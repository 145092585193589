@import "_mixins/pux/breakpoints.scss";
@import "_mixins/pux/css-rules.scss";
@import "_mixins/pux/scale.scss";
@import "_mixins/pux/pux-social.scss";
@import "_variables.scss";

.blog {
    &-filter {
        &-main {
            ul {
                margin-left: -15px;
                margin-right: -15px;
                @include clearfix;

                li {
                    display: block;
                    float: left;
                    width: 100%;
                    padding-left: 15px;
                    padding-right: 15px;
                    margin-bottom: 15px;

                    &:before {
                        display: none;
                    }

                    a {
                        display: block;
                        text-align: center;
                        background-color: $main-color;
                        color: $base-white;
                        text-transform: uppercase;
                        font-size: 16px;
                        line-height: 50px;
                        padding: 0 20px;
                        border-bottom: 2px solid $main-color;

                        &:hover {
                            background-color: darken($main-color, 5);
                            text-decoration: none;
                        }
                    }

                    &.Highlighted {
                        a {
                            background-color: $base-white;
                            color: $main-color;
                        }
                    }

                    @include media(md) {
                        width: 33.333333%;
                        margin-bottom: 30px;
                    }

                    @include media(lg) {
                        a {
                            font-size: 17px;
                            line-height: 70px;
                        }
                    }
                }
            }
        }

        &-secondary {
            text-align: center;
            @include pux-scale("margin-bottom", 60px, 30px);

            a {
                display: inline-block;
                background-color: $base-white;
                font-size: 14px;
                text-transform: uppercase;
                line-height: 40px;
                padding: 0 15px;
                margin: 0 0 15px 0;
                width: 100%;

                &:hover {
                    background-color: darken($base-white, 10);
                    text-decoration: none;
                    color: $main-color;
                }

                @include media(sm) {
                    width: auto;
                    font-size: 15px;
                    padding: 0 30px;
                    margin: 0 5px 10px 5px;
                }

                &.active {
                    background-color: $main-color;
                    color: $base-white;
                }
            }
        }
    }

    &-items {
        margin-left: -15px;
        margin-right: -15px;

        .items-container {
            @include flex-block();
            @include flex-flow(row, wrap);
        }

        .show-more-container {
            text-align: center;
            @include pux-scale-padding(30px, 0, 60px, 0, 20px);

            .arrow-down-link {
                display: inline-block;
                background-color: rgba(129, 127, 126, 0.8);
                color: $base-white;
                width: 30px;
                height: 30px;
                text-align: center;
                border-radius: 50%;
                font-size: 0px;
                overflow: hidden;

                &:before {
                    content: "\e902";
                    font-size: 14px;
                    line-height: 30px;
                }

                &:hover {
                    background-color: rgba(129, 127, 126, 1);
                    color: $base-white;
                    text-decoration: none;
                }

                @include media(md) {
                    width: 40px;
                    height: 40px;

                    &:before {
                        line-height: 40px;
                    }
                }

                @include media(lg) {
                    width: 60px;
                    height: 60px;

                    &:before {
                        font-size: 22px;
                        line-height: 60px;
                    }
                }
            }
        }
    }

    &-item {
        @include flex(100%);
        max-width: 100%;
        padding-left: 15px;
        padding-right: 15px;
        margin-bottom: 30px;

        &-teaser {
            position: relative;
            display: block;
            height: 200px;
            overflow: hidden;
            z-index: 1;

            &-inner {
                display: block;
                height: 100%;
                background-size: cover;
                background-position: center;
                background-repeat: no-repeat;
                @include transition(400ms);
            }
        }

        &-info {
            position: relative;
            padding: 20px;
            background-color: $base-white;
            z-index: 2;
            @include transition(400ms);
            text-align: left;

            &-name {
                font-size: 20px;
                line-height: 30px;
                font-weight: 700;
                display: block;
                min-height: 60px;
                margin-bottom: 15px;

                &:hover {
                    color: $main-color;
                }
            }

            &-category {
                display: inline-block;
                vertical-align: middle;
                line-height: 30px;
                padding: 0 20px;
                background-color: $light-grey;
                color: $second-color;
                text-transform: uppercase;

                &:hover {
                    background-color: darken($light-grey, 5);
                    color: $second-color;
                    text-decoration: none;
                }
            }

            &-date {
                float: right;
                line-height: 30px;
                vertical-align: middle;
            }
        }

        &:hover &-teaser {
            &-inner {
                @include transform(scale(1.1, 1.1));
            }
        }

        @include media(sm) {
            @include flex(50%);
            max-width: 50%;
        }

        @include media(lg) {
            @include flex(33.333333%);
            max-width: 33.333333%;

            &-teaser {
                height: 300px;
            }

            &-info {
                padding: 40px;
                margin-top: -110px;
                margin-right: 20px;
            }

            &:hover &-info {
                margin-right: 0px;
                padding: 50px;
                margin-top: -130px;
            }
        }
    }

    &-page {
        &-flex {
            @include pux-scale("padding-top", 90px, 30px);
            @include flex-block();
            @include flex-flow(row, wrap);
        }

        &-header {
            border-top: 1px solid $base-white;
            padding-bottom: 5px;
            padding-top: 10px;
            @include pux-scale("margin-top", 30px, 20px);
            @include pux-scale("margin-bottom", 40px, 20px);

            @include flex(100%);
            @include order(2);

            @include media(xl) {
                @include order(0);
                padding-top: 0;
                border-top: 0;
                border-bottom: 1px solid $base-white;
            }

            &-tags {
                font-size: 0;
                margin-bottom: 10px;

                a {
                    display: inline-block;
                    vertical-align: middle;
                    line-height: 30px;
                    padding: 0 15px;
                    text-transform: uppercase;
                    font-size: 14px;
                    margin-bottom: 10px;

                    &:not(.blog-page-header-category) {
                        background-color: $base-white;
                        color: $main-color;

                        &:hover {
                            background-color: darken($light-grey, 5);
                            color: $second-color;
                            text-decoration: none;
                        }
                    }

                    &:not(:last-of-type) {
                        margin-right: 10px;
                    }

                    @include media(sm) {
                        font-size: 14px;
                        padding: 0 20px;
                    }
                }

                @include media(md) {
                    margin-bottom: 0px;
                }
            }

            &-category {
                background-color: $main-color;
                color: $base-white;

                &:hover {
                    background-color: darken($main-color, 5);
                    color: $base-white;
                    text-decoration: none;
                }
            }

            &-date {
                margin-bottom: 10px;

                @include media(sm) {
                    display: inline-block;
                    margin-right: 10px;
                    margin-bottom: 0px;

                    &:after {
                        content: "|";
                        margin-left: 10px;
                        color: $base-white;
                    }
                }
            }

            &-author {
                margin-bottom: 10px;

                @include media(sm) {
                    display: inline-block;
                    margin-right: 10px;
                    margin-bottom: 0px;

                    &:after {
                        content: "|";
                        margin-left: 10px;
                        color: $base-white;
                    }
                }
            }

            &-social {
                display: inline-block;
                @include create-social(21px, 0, 30px, 50%, none, none, 0, 0, $base-white, $base-white, true, false, false);

                ul {
                    display: inline-block;
                    vertical-align: -3px;
                    margin-left: 10px;

                    li {
                        margin: 0;
                    }
                }
            }
        }

        &-title {
            @include flex(100%);
            @include order(0);

            @include media(xl) {
                @include order(1);
            }

            h1 {
                text-transform: uppercase;
                text-align: center;
                @include pux-scale("margin-bottom", 60px, 30px);

                @include media(xl) {
                    padding: 0 100px;
                }
            }
        }

        &-content {
            @include flex(100%);
            @include order(1);

            @include media(xl) {
                @include order(2);
            }
        }

        &-latest {
            .items-container {
                .blog-item {
                    @include media(xl) {
                        &:nth-of-type(4) {
                            display: none;
                        }
                    }
                }
            }
        }
    }
}

.author {
    &-item {
        @include pux-scale("margin-top", 60px, 30px);
        background-color: $base-white;
        font-size: 0;

        &-left {
            display: inline-block;
            vertical-align: top;
            width: 100%;

            @include media(sm) {
                width: 160px;
            }

            @include media(md) {
                width: 220px;
            }
        }

        &-right {
            display: inline-block;
            vertical-align: top;
            width: 100%;
            padding: 20px 15px 20px 15px;

            @include media(sm) {
                margin-left: -160px;
                padding: 30px 40px 30px 160px;
            }

            @include media(md) {
                margin-left: -220px;
                padding: 30px 40px 30px 220px;
            }
        }

        &-avatar {
            padding: 20px;

            @include media(md) {
                padding: 20px 40px;
            }

            img {
                display: block;
                border-radius: 50%;
            }
        }

        &-name {
            font-size: 22px;
            line-height: 30px;
            color: $color-line;
            font-weight: 700;
            text-transform: uppercase;
        }

        &-description {
            font-size: 16px;
            line-height: 25px;
        }

        &-social {
            text-align: right;
            @include create-social(21px, 0, 30px, 50%, none, none, 0, 0, $base-white, $base-white, true, false, false);

            ul {
                display: block;
                li {
                    display: inline-block;
                    margin-bottom: 20px;
                }
            }
        }
    }
}
