@charset "UTF-8";
/* plugins */
.selectric-wrapper {
  position: relative;
  cursor: pointer;
  margin-bottom: 0; }
  .selectric-wrapper:focus {
    background: #eeeff4;
    color: #ed6e04;
    border-bottom: 2px solid #ed6e04; }

.selectric-responsive {
  width: 100%; }

.selectric {
  border: 1px solid #f1f1f1;
  border-radius: 0px;
  background: #f1f1f1;
  position: relative;
  overflow: hidden;
  -moz-transition: all ease-in-out 100ms;
  -o-transition: all ease-in-out 100ms;
  -webkit-transition: all ease-in-out 100ms;
  transition: all ease-in-out 100ms; }
  .selectric .label {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0 60px 0 30px;
    font-size: 15px;
    line-height: 60px;
    color: #323337;
    height: 60px;
    user-select: none; }
    .selectric .label b {
      float: right;
      font-weight: 400; }
  .selectric .button {
    display: block;
    position: absolute;
    right: 0;
    top: 0;
    width: 60px;
    height: 60px;
    line-height: 60px;
    color: #ed6e04;
    text-align: center;
    font: 0/0 a;
    *font: 20px/60px Lucida Sans Unicode, Arial Unicode MS, Arial;
    transition: all ease 200ms; }
    .selectric .button:after {
      font-family: 'icomoon' !important;
      speak: none;
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      line-height: 1;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      font-size: 15px;
      content: "\e912";
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: #ed6e04; }

.selectric-focus .selectric {
  border-color: #f1f1f1; }

.selectric-hover .selectric {
  border-color: #f1f1f1; }

.selectric-disabled {
  filter: alpha(opacity=50);
  opacity: 0.5;
  cursor: default;
  user-select: none; }

.selectric-hide-select {
  position: relative;
  overflow: hidden;
  width: 0;
  height: 0; }
  .selectric-hide-select select {
    position: absolute;
    left: -100%; }
  .selectric-hide-select.selectric-is-native {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 10; }
    .selectric-hide-select.selectric-is-native select {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: 100%;
      width: 100%;
      border: none;
      z-index: 1;
      box-sizing: border-box;
      opacity: 0; }

.selectric-input {
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  margin: 0 !important;
  padding: 0 !important;
  width: 1px !important;
  height: 1px !important;
  outline: none !important;
  border: none !important;
  *font: 0/0 a !important;
  background: none !important; }

.selectric-temp-show {
  position: absolute !important;
  visibility: hidden !important;
  display: block !important; }

/* Items box */
.selectric-items {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #f1f1f1;
  border: 1px solid #f1f1f1;
  border-top: 0;
  z-index: -1;
  box-shadow: 0 0 10px -6px;
  -moz-transition: all ease-in-out 100ms;
  -o-transition: all ease-in-out 100ms;
  -webkit-transition: all ease-in-out 100ms;
  transition: all ease-in-out 100ms; }
  .selectric-items .selectric-scroll {
    height: 100%;
    overflow: auto; }
  .selectric-above .selectric-items {
    top: auto;
    bottom: 100%; }
  .selectric-items ul,
  .selectric-items li {
    list-style: none;
    padding: 0;
    margin: 0;
    font-size: 15px;
    line-height: 20px;
    min-height: 20px; }
  .selectric-items li {
    display: block;
    padding: 10px 30px;
    color: #323337;
    background-color: #fff;
    cursor: pointer;
    transition: all ease 200ms; }
    .selectric-items li:before {
      display: none; }
    .selectric-items li.selected {
      color: #323337;
      background-color: #f1f1f1; }
    .selectric-items li.highlighted {
      color: #323337; }
    .selectric-items li:hover {
      background-color: #f1f1f1; }
    .selectric-items li b {
      float: right;
      font-weight: 400; }
  .selectric-items.multiple li {
    position: relative;
    padding: 10px 30px 10px 60px; }
    .selectric-items.multiple li:before {
      content: "";
      width: 20px;
      height: 20px;
      background: #eeeff4;
      display: block;
      position: absolute;
      left: 30px;
      top: 10px;
      -moz-transition: all ease-in-out 200ms;
      -o-transition: all ease-in-out 200ms;
      -webkit-transition: all ease-in-out 200ms;
      transition: all ease-in-out 200ms; }
    .selectric-items.multiple li:after {
      content: "\e909";
      display: inline-block;
      line-height: 20px;
      font-size: 10px;
      width: 20px;
      color: #ed6e04;
      text-align: center;
      left: 30px;
      top: 10px;
      position: absolute;
      opacity: 0;
      -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
      filter: alpha(opacity=0);
      -moz-transition: all ease-in-out 200ms;
      -o-transition: all ease-in-out 200ms;
      -webkit-transition: all ease-in-out 200ms;
      transition: all ease-in-out 200ms; }
    .selectric-items.multiple li:hover:before {
      background-color: #fff; }
    .selectric-items.multiple li:hover:after {
      opacity: 0.4;
      -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=40)";
      filter: alpha(opacity=40); }
    .selectric-items.multiple li.selected:before {
      background-color: #fff; }
    .selectric-items.multiple li.selected:after {
      opacity: 1;
      -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
      filter: alpha(opacity=100); }
  .selectric-items .disabled {
    filter: alpha(opacity=50);
    opacity: 0.5;
    cursor: default !important;
    background: none !important;
    color: #666 !important;
    user-select: none; }
  .selectric-items .selectric-group .selectric-group-label {
    font-weight: bold;
    padding-left: 10px;
    cursor: default;
    user-select: none;
    background: none;
    color: #444; }
  .selectric-items .selectric-group.disabled li {
    filter: alpha(opacity=100);
    opacity: 1; }
  .selectric-items .selectric-group li {
    padding-left: 25px; }

/* selectric open */
.selectric-open {
  z-index: 9999; }
  .selectric-open .selectric {
    border-color: #ed6e04;
    border-bottom-color: #f1f1f1; }
    .selectric-open .selectric .button {
      color: #1a1a1c;
      -ms-transform: rotate(180deg);
      -webkit-transform: rotate(180deg);
      transform: rotate(180deg); }
  .selectric-open .selectric-items {
    display: block;
    border-color: #ed6e04; }

/* modules */
.test-social ul {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  margin: 0;
  padding: 0;
  list-style: none; }
  .test-social ul li {
    padding: 0 15px 15px 0; }
    .test-social ul li:before {
      display: none; }
    .test-social ul li a {
      font-size: 0;
      display: block;
      text-decoration: none;
      width: 30px;
      height: 30px;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      -ms-border-radius: 50%;
      border-radius: 50%;
      background: grey;
      border: none;
      color: #fff;
      text-align: center;
      -moz-transition: all ease-in-out 200ms;
      -o-transition: all ease-in-out 200ms;
      -webkit-transition: all ease-in-out 200ms;
      transition: all ease-in-out 200ms; }
      .test-social ul li a:focus, .test-social ul li a:hover {
        background: blue;
        color: black;
        border: none;
        text-decoration: none; }

.test-social .facebook:before {
  content: "";
  line-height: 30px;
  font-size: 20px; }

.test-social .twitter:before {
  content: "";
  line-height: 30px;
  font-size: 20px; }

.test-social .linkedin:before {
  content: "";
  line-height: 30px;
  font-size: 20px; }

.test-social .youtube:before {
  content: "";
  line-height: 30px;
  font-size: 20px; }

.test-social .instagram:before {
  content: "";
  line-height: 30px;
  font-size: 20px; }

.test-social .pinterest:before {
  content: "";
  line-height: 30px;
  font-size: 20px; }

.test-social .google:before {
  content: "";
  line-height: 30px;
  font-size: 20px; }

.test-social .vimeo:before {
  content: "";
  line-height: 30px;
  font-size: 20px; }

.product-social ul {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  margin: 0;
  padding: 0;
  list-style: none; }
  .product-social ul li {
    padding: 0 15px 15px 0; }
    .product-social ul li:before {
      display: none; }
    .product-social ul li a {
      font-size: 0;
      display: block;
      text-decoration: none;
      width: 40px;
      height: 40px;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      -ms-border-radius: 50%;
      border-radius: 50%;
      background: none;
      border: 1px solid grey;
      color: grey;
      text-align: center;
      -moz-transition: all ease-in-out 200ms;
      -o-transition: all ease-in-out 200ms;
      -webkit-transition: all ease-in-out 200ms;
      transition: all ease-in-out 200ms; }
      .product-social ul li a:focus, .product-social ul li a:hover {
        background: none;
        color: black;
        border: 1px solid black;
        text-decoration: none; }

.product-social .facebook:before {
  content: "";
  line-height: 40px;
  font-size: 20px; }

.product-social .twitter:before {
  content: "";
  line-height: 40px;
  font-size: 20px; }

.product-social .linkedin:before {
  content: "";
  line-height: 40px;
  font-size: 20px; }

.product-social .youtube:before {
  content: "";
  line-height: 40px;
  font-size: 20px; }

.product-social .instagram:before {
  content: "";
  line-height: 40px;
  font-size: 20px; }

.product-social .pinterest:before {
  content: "";
  line-height: 40px;
  font-size: 20px; }

.product-social .google:before {
  content: "";
  line-height: 40px;
  font-size: 20px; }

.product-social .vimeo:before {
  content: "";
  line-height: 40px;
  font-size: 20px; }

.slider-init .main-slider {
  width: 100%;
  background-repeat: no-repeat !important;
  -webkit-background-size: cover !important;
  background-size: cover !important;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -moz-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  padding: 20px 0px 20px 0px; }
  @media (min-width: 480px) {
    .slider-init .main-slider {
      padding: 20px 0px 20px 0px; } }
  @media (min-width: 545px) {
    .slider-init .main-slider {
      padding: 25px 0px 25px 0px; } }
  @media (min-width: 768px) {
    .slider-init .main-slider {
      padding: 30px 0px 30px 0px; } }
  @media (min-width: 992px) {
    .slider-init .main-slider {
      padding: 35px 0px 35px 0px; } }
  @media (min-width: 1200px) {
    .slider-init .main-slider {
      padding: 40px 0px 40px 0px; } }
  @media (min-width: 1400px) {
    .slider-init .main-slider {
      padding: 50px 0px 50px 0px; } }
  .slider-init .main-slider-inner {
    width: 100%;
    min-height: 288px;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    -webkit-box-pack: center;
    -moz-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center; }
    @media (min-width: 480px) {
      .slider-init .main-slider-inner {
        min-height: 288px; } }
    @media (min-width: 545px) {
      .slider-init .main-slider-inner {
        min-height: 360px; } }
    @media (min-width: 768px) {
      .slider-init .main-slider-inner {
        min-height: 432px; } }
    @media (min-width: 992px) {
      .slider-init .main-slider-inner {
        min-height: 504px; } }
    @media (min-width: 1200px) {
      .slider-init .main-slider-inner {
        min-height: 576px; } }
    @media (min-width: 1400px) {
      .slider-init .main-slider-inner {
        min-height: 720px; } }
    .slider-init .main-slider-inner-content {
      max-width: 1000px;
      margin: auto; }
      .slider-init .main-slider-inner-content-text {
        color: #fff; }
        .slider-init .main-slider-inner-content-text h1,
        .slider-init .main-slider-inner-content-text h2 {
          color: #fff; }
      .slider-init .main-slider-inner-content-btn {
        padding: 20px 0 0 0; }
    .slider-init .main-slider-inner .pux-container {
      width: 100%; }
  .slider-init .main-slider.top-center {
    background-position: top center !important; }
  .slider-init .main-slider.top-left {
    background-position: top left !important; }
  .slider-init .main-slider.top-right {
    background-position: top right !important; }
  .slider-init .main-slider.center-center {
    background-position: center center !important; }
  .slider-init .main-slider.bottom-center {
    background-position: bottom center !important; }
  .slider-init .main-slider.bottom-left {
    background-position: bottom left !important; }
  .slider-init .main-slider.bottom-right {
    background-position: bottom right !important; }

.slider-init .slick-arrow {
  position: absolute;
  top: 50%;
  margin-top: -20px;
  width: 40px;
  height: 40px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
  border: none;
  font-size: 0;
  background: #ed6e04;
  outline: 0;
  z-index: 22;
  cursor: pointer; }
  .slider-init .slick-arrow:before {
    font-size: 20px; }
  .slider-init .slick-arrow.slick-prev {
    left: 20px; }
    .slider-init .slick-arrow.slick-prev:before {
      content: "遗"; }
  .slider-init .slick-arrow.slick-next {
    right: 20px; }
    .slider-init .slick-arrow.slick-next:before {
      content: "道"; }

.slider-init .slick-dots {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  position: absolute;
  left: 0;
  width: 100%;
  bottom: 30px; }
  .slider-init .slick-dots li {
    margin: 0;
    padding: 0 20px 0 0; }
    .slider-init .slick-dots li:before {
      display: none; }
    .slider-init .slick-dots li:last-child {
      padding: 0; }
    .slider-init .slick-dots li button {
      font-size: 0;
      outline: 0;
      border: 0;
      padding: 20px 0;
      background: transparent;
      -webkit-border-radius: 0;
      -moz-border-radius: 0;
      -ms-border-radius: 0;
      border-radius: 0;
      -moz-transition: all ease-in-out 350ms;
      -o-transition: all ease-in-out 350ms;
      -webkit-transition: all ease-in-out 350ms;
      transition: all ease-in-out 350ms; }
      .slider-init .slick-dots li button:before {
        content: '';
        background: #fff;
        display: block;
        width: 40px;
        height: 4px;
        border: none; }
    .slider-init .slick-dots li.slick-active button:before {
      background: #ed6e04; }

.test-social ul {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  margin: 0;
  padding: 0;
  list-style: none; }
  .test-social ul li {
    padding: 0 15px 15px 0; }
    .test-social ul li:before {
      display: none; }
    .test-social ul li a {
      font-size: 0;
      display: block;
      text-decoration: none;
      width: 30px;
      height: 30px;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      -ms-border-radius: 50%;
      border-radius: 50%;
      background: grey;
      border: none;
      color: #fff;
      text-align: center;
      -moz-transition: all ease-in-out 200ms;
      -o-transition: all ease-in-out 200ms;
      -webkit-transition: all ease-in-out 200ms;
      transition: all ease-in-out 200ms; }
      .test-social ul li a:focus, .test-social ul li a:hover {
        background: blue;
        color: black;
        border: none;
        text-decoration: none; }

.test-social .facebook:before {
  content: "";
  line-height: 30px;
  font-size: 20px; }

.test-social .twitter:before {
  content: "";
  line-height: 30px;
  font-size: 20px; }

.test-social .linkedin:before {
  content: "";
  line-height: 30px;
  font-size: 20px; }

.test-social .youtube:before {
  content: "";
  line-height: 30px;
  font-size: 20px; }

.test-social .instagram:before {
  content: "";
  line-height: 30px;
  font-size: 20px; }

.test-social .pinterest:before {
  content: "";
  line-height: 30px;
  font-size: 20px; }

.test-social .google:before {
  content: "";
  line-height: 30px;
  font-size: 20px; }

.test-social .vimeo:before {
  content: "";
  line-height: 30px;
  font-size: 20px; }

.product-social ul {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  margin: 0;
  padding: 0;
  list-style: none; }
  .product-social ul li {
    padding: 0 15px 15px 0; }
    .product-social ul li:before {
      display: none; }
    .product-social ul li a {
      font-size: 0;
      display: block;
      text-decoration: none;
      width: 40px;
      height: 40px;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      -ms-border-radius: 50%;
      border-radius: 50%;
      background: none;
      border: 1px solid grey;
      color: grey;
      text-align: center;
      -moz-transition: all ease-in-out 200ms;
      -o-transition: all ease-in-out 200ms;
      -webkit-transition: all ease-in-out 200ms;
      transition: all ease-in-out 200ms; }
      .product-social ul li a:focus, .product-social ul li a:hover {
        background: none;
        color: black;
        border: 1px solid black;
        text-decoration: none; }

.product-social .facebook:before {
  content: "";
  line-height: 40px;
  font-size: 20px; }

.product-social .twitter:before {
  content: "";
  line-height: 40px;
  font-size: 20px; }

.product-social .linkedin:before {
  content: "";
  line-height: 40px;
  font-size: 20px; }

.product-social .youtube:before {
  content: "";
  line-height: 40px;
  font-size: 20px; }

.product-social .instagram:before {
  content: "";
  line-height: 40px;
  font-size: 20px; }

.product-social .pinterest:before {
  content: "";
  line-height: 40px;
  font-size: 20px; }

.product-social .google:before {
  content: "";
  line-height: 40px;
  font-size: 20px; }

.product-social .vimeo:before {
  content: "";
  line-height: 40px;
  font-size: 20px; }

.pux-container {
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px;
  max-width: 1600px; }
  .pux-container:after {
    display: block;
    content: "";
    clear: both; }
  @media (min-width: 768px) {
    .pux-container {
      padding-left: 45px;
      padding-right: 45px; } }
  @media (min-width: 1200px) {
    .pux-container {
      padding-left: 80px;
      padding-right: 80px; } }

.pux-container .row {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px; }
  @media (min-width: 768px) {
    .pux-container .row {
      margin-left: -45px;
      margin-right: -45px; } }

.text-container {
  max-width: 1040px;
  margin-left: auto;
  margin-right: auto; }
  .text-container:before, .text-container:after {
    content: '';
    display: table; }
  .text-container:after {
    clear: both; }
  .text-container iframe {
    display: block;
    margin: 0 auto;
    max-width: 100% !important; }
    @media (min-width: 320px) and (max-width: 1199px) {
      .text-container iframe {
        max-height: 480px; } }

.pux-container .col-1,
.pux-container .col-2,
.pux-container .col-3,
.pux-container .col-4,
.pux-container .col-5,
.pux-container .col-6,
.pux-container .col-7,
.pux-container .col-8,
.pux-container .col-9,
.pux-container .col-10,
.pux-container .col-11,
.pux-container .col-12,
.pux-container .col,
.pux-container .col-xs-1,
.pux-container .col-xs-2,
.pux-container .col-xs-3,
.pux-container .col-xs-4,
.pux-container .col-xs-5,
.pux-container .col-xs-6,
.pux-container .col-xs-7,
.pux-container .col-xs-8,
.pux-container .col-xs-9,
.pux-container .col-xs-10,
.pux-container .col-xs-11,
.pux-container .col-xs-12,
.pux-container .col-auto,
.pux-container .col-sm-1,
.pux-container .col-sm-2,
.pux-container .col-sm-3,
.pux-container .col-sm-4,
.pux-container .col-sm-5,
.pux-container .col-sm-6,
.pux-container .col-sm-7,
.pux-container .col-sm-8,
.pux-container .col-sm-9,
.pux-container .col-sm-10,
.pux-container .col-sm-11,
.pux-container .col-sm-12,
.pux-container .col-sm,
.pux-container .col-sm-auto,
.pux-container .col-md-1,
.pux-container .col-md-2,
.pux-container .col-md-3,
.pux-container .col-md-4,
.pux-container .col-md-5,
.pux-container .col-md-6,
.pux-container .col-md-7,
.pux-container .col-md-8,
.pux-container .col-md-9,
.pux-container .col-md-10,
.pux-container .col-md-11,
.pux-container .col-md-12,
.pux-container .col-md,
.pux-container .col-md-auto,
.pux-container .col-lg-1,
.pux-container .col-lg-2,
.pux-container .col-lg-3,
.pux-container .col-lg-4,
.pux-container .col-lg-5,
.pux-container .col-lg-6,
.pux-container .col-lg-7,
.pux-container .col-lg-8,
.pux-container .col-lg-9,
.pux-container .col-lg-10,
.pux-container .col-lg-11,
.pux-container .col-lg-12,
.pux-container .col-lg,
.pux-container .col-lg-auto,
.pux-container .col-xl-1,
.pux-container .col-xl-2,
.pux-container .col-xl-3,
.pux-container .col-xl-4,
.pux-container .col-xl-5,
.pux-container .col-xl-6,
.pux-container .col-xl-7,
.pux-container .col-xl-8,
.pux-container .col-xl-9,
.pux-container .col-xl-10,
.pux-container .col-xl-11,
.pux-container .col-xl-12,
.pux-container .col-xl,
.pux-container .col-xl-auto {
  padding-left: 15px;
  padding-right: 15px; }
  @media (min-width: 768px) {
    .pux-container .col-1,
    .pux-container .col-2,
    .pux-container .col-3,
    .pux-container .col-4,
    .pux-container .col-5,
    .pux-container .col-6,
    .pux-container .col-7,
    .pux-container .col-8,
    .pux-container .col-9,
    .pux-container .col-10,
    .pux-container .col-11,
    .pux-container .col-12,
    .pux-container .col,
    .pux-container .col-xs-1,
    .pux-container .col-xs-2,
    .pux-container .col-xs-3,
    .pux-container .col-xs-4,
    .pux-container .col-xs-5,
    .pux-container .col-xs-6,
    .pux-container .col-xs-7,
    .pux-container .col-xs-8,
    .pux-container .col-xs-9,
    .pux-container .col-xs-10,
    .pux-container .col-xs-11,
    .pux-container .col-xs-12,
    .pux-container .col-auto,
    .pux-container .col-sm-1,
    .pux-container .col-sm-2,
    .pux-container .col-sm-3,
    .pux-container .col-sm-4,
    .pux-container .col-sm-5,
    .pux-container .col-sm-6,
    .pux-container .col-sm-7,
    .pux-container .col-sm-8,
    .pux-container .col-sm-9,
    .pux-container .col-sm-10,
    .pux-container .col-sm-11,
    .pux-container .col-sm-12,
    .pux-container .col-sm,
    .pux-container .col-sm-auto,
    .pux-container .col-md-1,
    .pux-container .col-md-2,
    .pux-container .col-md-3,
    .pux-container .col-md-4,
    .pux-container .col-md-5,
    .pux-container .col-md-6,
    .pux-container .col-md-7,
    .pux-container .col-md-8,
    .pux-container .col-md-9,
    .pux-container .col-md-10,
    .pux-container .col-md-11,
    .pux-container .col-md-12,
    .pux-container .col-md,
    .pux-container .col-md-auto,
    .pux-container .col-lg-1,
    .pux-container .col-lg-2,
    .pux-container .col-lg-3,
    .pux-container .col-lg-4,
    .pux-container .col-lg-5,
    .pux-container .col-lg-6,
    .pux-container .col-lg-7,
    .pux-container .col-lg-8,
    .pux-container .col-lg-9,
    .pux-container .col-lg-10,
    .pux-container .col-lg-11,
    .pux-container .col-lg-12,
    .pux-container .col-lg,
    .pux-container .col-lg-auto,
    .pux-container .col-xl-1,
    .pux-container .col-xl-2,
    .pux-container .col-xl-3,
    .pux-container .col-xl-4,
    .pux-container .col-xl-5,
    .pux-container .col-xl-6,
    .pux-container .col-xl-7,
    .pux-container .col-xl-8,
    .pux-container .col-xl-9,
    .pux-container .col-xl-10,
    .pux-container .col-xl-11,
    .pux-container .col-xl-12,
    .pux-container .col-xl,
    .pux-container .col-xl-auto {
      padding-left: 45px;
      padding-right: 45px; } }

.pux-fluid-container {
  padding-left: 15px;
  padding-righ: 15px; }
  @media (min-width: 768px) {
    .pux-fluid-container {
      padding-left: 45px;
      padding-righ: 45px; } }
  @media (min-width: 1200px) {
    .pux-fluid-container {
      padding-left: 80px;
      padding-righ: 80px; } }

header {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  background-color: #fff;
  box-shadow: none;
  z-index: 999; }
  header:after {
    content: '';
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    display: block;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 10px 100vw 0 0;
    border-color: #fff transparent transparent transparent;
    -moz-transition: all ease-in-out 500ms;
    -o-transition: all ease-in-out 500ms;
    -webkit-transition: all ease-in-out 500ms;
    transition: all ease-in-out 500ms; }
    @media (min-width: 992px) {
      header:after {
        border-width: 20px 100vw 0 0; } }
    @media (min-width: 1200px) {
      header:after {
        border-width: 40px 100vw 0 0; } }
  header .main-navbar .navbar-brand {
    margin-right: 0;
    margin-bottom: 10px;
    padding-top: 10px;
    padding-bottom: 0;
    -moz-transition: all ease-in-out 350ms;
    -o-transition: all ease-in-out 350ms;
    -webkit-transition: all ease-in-out 350ms;
    transition: all ease-in-out 350ms; }
    header .main-navbar .navbar-brand img {
      width: 94px;
      height: 45px !important;
      position: relative;
      z-index: 1;
      -moz-transition: all ease-in-out 500ms;
      -o-transition: all ease-in-out 500ms;
      -webkit-transition: all ease-in-out 500ms;
      transition: all ease-in-out 500ms; }
    @media (min-width: 1200px) {
      header .main-navbar .navbar-brand {
        margin-bottom: -7px;
        padding-top: 15px; }
        header .main-navbar .navbar-brand img {
          height: 72px !important; } }
  header .main-navbar .navbar-toggler {
    display: inline-block;
    vertical-align: middle;
    border: 0;
    padding: 0;
    margin: 0px 0px;
    height: 22px;
    width: 32px;
    position: relative; }
    header .main-navbar .navbar-toggler .icon-bar {
      display: block;
      background-color: #ed6e04;
      width: 32px;
      height: 4px;
      border-radius: 2px;
      -moz-transition: all ease-in-out 350ms;
      -o-transition: all ease-in-out 350ms;
      -webkit-transition: all ease-in-out 350ms;
      transition: all ease-in-out 350ms;
      position: absolute;
      left: 0;
      top: 0; }
      header .main-navbar .navbar-toggler .icon-bar:nth-of-type(2) {
        top: 9px; }
      header .main-navbar .navbar-toggler .icon-bar:nth-of-type(3) {
        top: 18px; }
    header .main-navbar .navbar-toggler:not(.collapsed) .icon-bar {
      border-radius: 1px; }
      header .main-navbar .navbar-toggler:not(.collapsed) .icon-bar:nth-of-type(1) {
        -webkit-transform: rotateZ(45deg);
        -moz-transform: rotateZ(45deg);
        -ms-transform: rotateZ(45deg);
        -o-transform: rotateZ(45deg);
        transform: rotateZ(45deg);
        top: 9px; }
      header .main-navbar .navbar-toggler:not(.collapsed) .icon-bar:nth-of-type(2) {
        width: 0px;
        opacity: 0; }
      header .main-navbar .navbar-toggler:not(.collapsed) .icon-bar:nth-of-type(3) {
        -webkit-transform: rotateZ(-45deg);
        -moz-transform: rotateZ(-45deg);
        -ms-transform: rotateZ(-45deg);
        -o-transform: rotateZ(-45deg);
        transform: rotateZ(-45deg);
        top: 9px; }
    header .main-navbar .navbar-toggler:focus {
      outline: 0;
      box-shadow: none;
      border: 0; }
    @media (min-width: 1200px) {
      header .main-navbar .navbar-toggler {
        display: none; } }
  header .main-navbar .navbar-collapse {
    width: 100%; }
    @media (min-width: 1200px) {
      header .main-navbar .navbar-collapse {
        display: block;
        width: auto; } }
    header .main-navbar .navbar-collapse.main-menu > ul {
      padding: 5px 0px 15px 0px;
      margin: 0;
      font-size: 0;
      -moz-transition: all ease-in-out 350ms;
      -o-transition: all ease-in-out 350ms;
      -webkit-transition: all ease-in-out 350ms;
      transition: all ease-in-out 350ms; }
      @media (min-width: 1200px) {
        header .main-navbar .navbar-collapse.main-menu > ul {
          padding: 40px 0 7px 0; } }
      header .main-navbar .navbar-collapse.main-menu > ul > li {
        display: block;
        text-align: right;
        margin-bottom: 8px; }
        @media (min-width: 1200px) {
          header .main-navbar .navbar-collapse.main-menu > ul > li {
            display: inline-block;
            margin-bottom: 0px;
            padding-right: 35px; } }
        @media (min-width: 1400px) {
          header .main-navbar .navbar-collapse.main-menu > ul > li {
            padding-right: 70px; } }
        header .main-navbar .navbar-collapse.main-menu > ul > li a {
          font-size: 16px;
          text-transform: uppercase;
          display: inline-block;
          position: relative;
          padding: 0 4px;
          color: #646569; }
          @media (min-width: 1400px) {
            header .main-navbar .navbar-collapse.main-menu > ul > li a {
              font-size: 17px;
              padding-bottom: 8px; } }
          header .main-navbar .navbar-collapse.main-menu > ul > li a:after {
            content: '';
            display: block;
            position: absolute;
            bottom: 0;
            left: 0;
            right: 100%;
            border-bottom: 2px solid transparent;
            -moz-transition: all ease-in-out 350ms;
            -o-transition: all ease-in-out 350ms;
            -webkit-transition: all ease-in-out 350ms;
            transition: all ease-in-out 350ms; }
          header .main-navbar .navbar-collapse.main-menu > ul > li a:hover {
            text-decoration: none; }
            header .main-navbar .navbar-collapse.main-menu > ul > li a:hover:after {
              right: 0;
              border-color: #ed6e04; }
        header .main-navbar .navbar-collapse.main-menu > ul > li.Highlighted a:after {
          right: 0;
          border-color: #ed6e04; }
  header .main-navbar .header-controls {
    padding: 18px 0 17px 0;
    -moz-transition: all ease-in-out 350ms;
    -o-transition: all ease-in-out 350ms;
    -webkit-transition: all ease-in-out 350ms;
    transition: all ease-in-out 350ms; }
    @media (min-width: 1200px) {
      header .main-navbar .header-controls {
        padding: 35px 0 10px 0; } }
  header .main-navbar .header-btn {
    color: #ed6e04;
    display: inline-block;
    vertical-align: middle; }
    header .main-navbar .header-btn:before {
      width: 30px;
      line-height: 30px;
      display: block;
      font-size: 21px;
      text-align: center;
      -moz-transition: all ease-in-out 200ms;
      -o-transition: all ease-in-out 200ms;
      -webkit-transition: all ease-in-out 200ms;
      transition: all ease-in-out 200ms;
      -webkit-transform-origin: 50% 50%;
      -moz-transform-origin: 50% 50%;
      -ms-transform-origin: 50% 50%;
      -o-transform-origin: 50% 50%;
      transform-origin: 50% 50%; }
    header .main-navbar .header-btn.login {
      margin-right: 15px; }
      @media (min-width: 1200px) {
        header .main-navbar .header-btn.login {
          margin-right: 30px; } }
      header .main-navbar .header-btn.login:before {
        content: "\e905"; }
    header .main-navbar .header-btn.search {
      margin-right: 15px; }
      @media (min-width: 1200px) {
        header .main-navbar .header-btn.search {
          margin-right: 30px; } }
      header .main-navbar .header-btn.search:before {
        content: "\e904"; }
    header .main-navbar .header-btn.language {
      position: relative;
      margin-right: 15px; }
      @media (min-width: 1200px) {
        header .main-navbar .header-btn.language {
          margin-right: 0px; } }
      header .main-navbar .header-btn.language:hover .header-language-inner {
        display: block;
        padding: 10px 10px 0; }
      header .main-navbar .header-btn.language .header-language-current,
      header .main-navbar .header-btn.language .header-language-link {
        display: block;
        font-size: 0;
        width: 30px;
        height: 30px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover; }
        header .main-navbar .header-btn.language .header-language-current.active,
        header .main-navbar .header-btn.language .header-language-link.active {
          display: none; }
        header .main-navbar .header-btn.language .header-language-current.cs-cz,
        header .main-navbar .header-btn.language .header-language-link.cs-cz {
          background-image: url("../img/cz-flag.svg"); }
        header .main-navbar .header-btn.language .header-language-current.sk-sk,
        header .main-navbar .header-btn.language .header-language-link.sk-sk {
          background-image: url("../img/sk-flag.svg"); }
      header .main-navbar .header-btn.language .header-language-current {
        position: relative;
        z-index: 2; }
      header .main-navbar .header-btn.language .header-language-link {
        display: block;
        margin: 10px 0; }
        header .main-navbar .header-btn.language .header-language-link.cs-cz, header .main-navbar .header-btn.language .header-language-link.sk-sk {
          transition: transform 300ms; }
          header .main-navbar .header-btn.language .header-language-link.cs-cz:hover, header .main-navbar .header-btn.language .header-language-link.sk-sk:hover {
            transform: scale(1.25); }
      header .main-navbar .header-btn.language .header-language-inner {
        position: absolute;
        display: none;
        right: -10px;
        top: -10px;
        font-size: 0;
        background-color: #fff;
        z-index: 1;
        padding: 10px 0 0;
        box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.1);
        transition: padding 300ms; }
        header .main-navbar .header-btn.language .header-language-inner ul {
          padding: 40px 0 0;
          margin: 0;
          list-style: none; }
          header .main-navbar .header-btn.language .header-language-inner ul li {
            padding: 0;
            margin: 0; }
    header .main-navbar .header-btn:hover, header .main-navbar .header-btn:focus {
      text-decoration: none; }
      @media (min-width: 992px) {
        header .main-navbar .header-btn:hover:before, header .main-navbar .header-btn:focus:before {
          -ms-transform: scale(1.33, 1.33);
          -webkit-transform: scale(1.33, 1.33);
          transform: scale(1.33, 1.33); } }
  header.scrolled {
    -webkit-box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.1); }
    header.scrolled:after {
      border-width: 0px; }
    header.scrolled .main-navbar .navbar-brand {
      padding-top: 10px;
      margin-bottom: 10px; }
      header.scrolled .main-navbar .navbar-brand img {
        height: 45px !important; }
    header.scrolled .main-navbar .navbar-collapse.main-menu > ul {
      padding: 5px 0 15px 0; }
      @media (min-width: 1200px) {
        header.scrolled .main-navbar .navbar-collapse.main-menu > ul {
          padding: 23px 0 17px 0; } }
    header.scrolled .main-navbar .header-controls {
      padding: 18px 0 17px 0; }

main {
  padding-top: 65px; }
  @media (min-width: 1200px) {
    main {
      padding-top: 80px; } }

footer {
  background-color: #646569;
  color: #fff;
  padding-bottom: 15px; }
  @media (min-width: 1200px) {
    footer {
      padding-bottom: 50px; } }
  footer .footer-list ul {
    margin: 0;
    padding: 25px 0 20px 0;
    font-size: 0;
    text-align: center; }
    @media (min-width: 1200px) {
      footer .footer-list ul {
        padding: 60px 0 40px 0; } }
    footer .footer-list ul li {
      display: inline-block;
      padding: 0 30px;
      margin-bottom: 10px; }
      @media (min-width: 1200px) {
        footer .footer-list ul li {
          margin-bottom: 0; } }
      footer .footer-list ul li a {
        font-size: 17px;
        color: #fff;
        text-transform: uppercase;
        display: inline-block;
        position: relative;
        padding: 0 4px; }
        footer .footer-list ul li a:after {
          content: '';
          display: block;
          position: absolute;
          bottom: 0;
          left: 0;
          right: 100%;
          border-bottom: 2px solid transparent;
          -moz-transition: all ease-in-out 350ms;
          -o-transition: all ease-in-out 350ms;
          -webkit-transition: all ease-in-out 350ms;
          transition: all ease-in-out 350ms; }
        footer .footer-list ul li a:hover {
          text-decoration: none; }
          footer .footer-list ul li a:hover:after {
            right: 0;
            border-color: #fff; }
  footer .footer-copy {
    font-size: 13px;
    line-height: 20px;
    text-align: center;
    margin-bottom: 30px; }
    @media (min-width: 768px) {
      footer .footer-copy {
        text-align: left; } }
    @media (min-width: 1200px) {
      footer .footer-copy {
        margin-bottom: 0px; } }
  footer .footer-social {
    text-align: center; }
    footer .footer-social ul {
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-box-align: center;
      -moz-box-align: center;
      -ms-flex-align: center;
      -webkit-align-items: center;
      align-items: center;
      margin: 0;
      padding: 0;
      list-style: none; }
      footer .footer-social ul li {
        padding: 0 15px 15px 0; }
        footer .footer-social ul li:before {
          display: none; }
        footer .footer-social ul li a {
          font-size: 0;
          display: block;
          text-decoration: none;
          width: 30px;
          height: 30px;
          -webkit-border-radius: 50%;
          -moz-border-radius: 50%;
          -ms-border-radius: 50%;
          border-radius: 50%;
          background: #fff;
          border: none;
          color: #646569;
          text-align: center;
          -moz-transition: all ease-in-out 200ms;
          -o-transition: all ease-in-out 200ms;
          -webkit-transition: all ease-in-out 200ms;
          transition: all ease-in-out 200ms; }
          footer .footer-social ul li a:focus, footer .footer-social ul li a:hover {
            background: #ed6e04;
            color: #fff;
            border: none;
            text-decoration: none; }
    footer .footer-social .facebook:before {
      content: "";
      line-height: 30px;
      font-size: 21px; }
    footer .footer-social .facebook:focus, footer .footer-social .facebook:hover {
      background: #3b5998; }
    footer .footer-social .twitter:before {
      content: "";
      line-height: 30px;
      font-size: 21px; }
    footer .footer-social .twitter:focus, footer .footer-social .twitter:hover {
      background: #00aced; }
    footer .footer-social .linkedin:before {
      content: "";
      line-height: 30px;
      font-size: 21px; }
    footer .footer-social .linkedin:focus, footer .footer-social .linkedin:hover {
      background: #0d77b7; }
    footer .footer-social .youtube:before {
      content: "";
      line-height: 30px;
      font-size: 21px; }
    footer .footer-social .youtube:focus, footer .footer-social .youtube:hover {
      background: #cc181e; }
    footer .footer-social .instagram:before {
      content: "";
      line-height: 30px;
      font-size: 21px; }
    footer .footer-social .instagram:focus, footer .footer-social .instagram:hover {
      background: #8a3ab9; }
    footer .footer-social .pinterest:before {
      content: "";
      line-height: 30px;
      font-size: 21px; }
    footer .footer-social .pinterest:focus, footer .footer-social .pinterest:hover {
      background: #cd2029; }
    footer .footer-social .google:before {
      content: "";
      line-height: 30px;
      font-size: 21px; }
    footer .footer-social .google:focus, footer .footer-social .google:hover {
      background: #d34836; }
    footer .footer-social .vimeo:before {
      content: "";
      line-height: 30px;
      font-size: 21px; }
    footer .footer-social .vimeo:focus, footer .footer-social .vimeo:hover {
      background: #45bbff; }
    @media (min-width: 1200px) {
      footer .footer-social {
        text-align: left; } }
    footer .footer-social ul {
      width: 100%;
      display: block; }
      @media (min-width: 1200px) {
        footer .footer-social ul {
          float: right;
          width: auto; } }
      footer .footer-social ul li {
        display: inline-block;
        vertical-align: middle; }
        footer .footer-social ul li:last-of-type {
          padding-right: 0px; }
        footer .footer-social ul li .social-ico.twitter:before {
          font-size: 14px; }
        footer .footer-social ul li .social-ico.linkedin:before {
          font-size: 17px; }
        footer .footer-social ul li .social-ico.youtube:before {
          font-size: 12px; }

.EditMode header,
.DesignMode header {
  position: static; }
  .EditMode header:after,
  .DesignMode header:after {
    display: none; }

.EditMode main,
.DesignMode main {
  padding-top: 0px; }

#CMSHeaderDiv {
  display: none; }

.is-xxl {
  display: none; }
  @media (min-width: 1400px) {
    .is-xxl {
      display: block; } }

@font-face {
  font-family: 'Fedra';
  src: url("../fonts/FedraSans/FedraSansPro-Book.otf") format("opentype");
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Fedra';
  src: url("../fonts/FedraSans/FedraSansPro-Light.otf") format("opentype");
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Fedra';
  src: url("../fonts/FedraSans/FedraSansPro-Bold.otf") format("opentype");
  font-weight: 700;
  font-style: normal; }

.cms-bootstrap [class^="icon-"]:before,
.cms-bootstrap [class*=" icon-"]:before {
  font-family: 'Core-icons' !important; }

*:before,
*:after {
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

body {
  height: auto !important; }

form,
html,
body {
  font-size: 16px;
  font-size: 1rem;
  font-family: "Fedra", sans-serif;
  height: 100%;
  color: #323337; }

img {
  max-width: 100%;
  height: auto !important; }

.pt-5 {
  padding-top: 5px; }

.pb-5 {
  padding-bottom: 5px; }

.mt-5 {
  margin-top: 5px; }

.mb-5 {
  margin-bottom: 5px; }

.pt-10 {
  padding-top: 10px; }

.pb-10 {
  padding-bottom: 10px; }

.mt-10 {
  margin-top: 10px; }

.mb-10 {
  margin-bottom: 10px; }

.pt-15 {
  padding-top: 15px; }

.pb-15 {
  padding-bottom: 15px; }

.mt-15 {
  margin-top: 15px; }

.mb-15 {
  margin-bottom: 15px; }

.pt-20 {
  padding-top: 20px; }

.pb-20 {
  padding-bottom: 20px; }

.mt-20 {
  margin-top: 20px; }

.mb-20 {
  margin-bottom: 20px; }

.pt-25 {
  padding-top: 20px; }
  @media (min-width: 480px) {
    .pt-25 {
      padding-top: 20px; } }
  @media (min-width: 545px) {
    .pt-25 {
      padding-top: 20px; } }
  @media (min-width: 768px) {
    .pt-25 {
      padding-top: 20px; } }
  @media (min-width: 992px) {
    .pt-25 {
      padding-top: 20px; } }
  @media (min-width: 1200px) {
    .pt-25 {
      padding-top: 20px; } }
  @media (min-width: 1400px) {
    .pt-25 {
      padding-top: 25px; } }

.pb-25 {
  padding-bottom: 20px; }
  @media (min-width: 480px) {
    .pb-25 {
      padding-bottom: 20px; } }
  @media (min-width: 545px) {
    .pb-25 {
      padding-bottom: 20px; } }
  @media (min-width: 768px) {
    .pb-25 {
      padding-bottom: 20px; } }
  @media (min-width: 992px) {
    .pb-25 {
      padding-bottom: 20px; } }
  @media (min-width: 1200px) {
    .pb-25 {
      padding-bottom: 20px; } }
  @media (min-width: 1400px) {
    .pb-25 {
      padding-bottom: 25px; } }

.mt-25 {
  margin-top: 20px; }
  @media (min-width: 480px) {
    .mt-25 {
      margin-top: 20px; } }
  @media (min-width: 545px) {
    .mt-25 {
      margin-top: 20px; } }
  @media (min-width: 768px) {
    .mt-25 {
      margin-top: 20px; } }
  @media (min-width: 992px) {
    .mt-25 {
      margin-top: 20px; } }
  @media (min-width: 1200px) {
    .mt-25 {
      margin-top: 20px; } }
  @media (min-width: 1400px) {
    .mt-25 {
      margin-top: 25px; } }

.mb-25 {
  margin-bottom: 20px; }
  @media (min-width: 480px) {
    .mb-25 {
      margin-bottom: 20px; } }
  @media (min-width: 545px) {
    .mb-25 {
      margin-bottom: 20px; } }
  @media (min-width: 768px) {
    .mb-25 {
      margin-bottom: 20px; } }
  @media (min-width: 992px) {
    .mb-25 {
      margin-bottom: 20px; } }
  @media (min-width: 1200px) {
    .mb-25 {
      margin-bottom: 20px; } }
  @media (min-width: 1400px) {
    .mb-25 {
      margin-bottom: 25px; } }

.pt-30 {
  padding-top: 20px; }
  @media (min-width: 480px) {
    .pt-30 {
      padding-top: 20px; } }
  @media (min-width: 545px) {
    .pt-30 {
      padding-top: 20px; } }
  @media (min-width: 768px) {
    .pt-30 {
      padding-top: 20px; } }
  @media (min-width: 992px) {
    .pt-30 {
      padding-top: 21px; } }
  @media (min-width: 1200px) {
    .pt-30 {
      padding-top: 24px; } }
  @media (min-width: 1400px) {
    .pt-30 {
      padding-top: 30px; } }

.pb-30 {
  padding-bottom: 20px; }
  @media (min-width: 480px) {
    .pb-30 {
      padding-bottom: 20px; } }
  @media (min-width: 545px) {
    .pb-30 {
      padding-bottom: 20px; } }
  @media (min-width: 768px) {
    .pb-30 {
      padding-bottom: 20px; } }
  @media (min-width: 992px) {
    .pb-30 {
      padding-bottom: 21px; } }
  @media (min-width: 1200px) {
    .pb-30 {
      padding-bottom: 24px; } }
  @media (min-width: 1400px) {
    .pb-30 {
      padding-bottom: 30px; } }

.mt-30 {
  margin-top: 20px; }
  @media (min-width: 480px) {
    .mt-30 {
      margin-top: 20px; } }
  @media (min-width: 545px) {
    .mt-30 {
      margin-top: 20px; } }
  @media (min-width: 768px) {
    .mt-30 {
      margin-top: 20px; } }
  @media (min-width: 992px) {
    .mt-30 {
      margin-top: 21px; } }
  @media (min-width: 1200px) {
    .mt-30 {
      margin-top: 24px; } }
  @media (min-width: 1400px) {
    .mt-30 {
      margin-top: 30px; } }

.mb-30 {
  margin-bottom: 20px; }
  @media (min-width: 480px) {
    .mb-30 {
      margin-bottom: 20px; } }
  @media (min-width: 545px) {
    .mb-30 {
      margin-bottom: 20px; } }
  @media (min-width: 768px) {
    .mb-30 {
      margin-bottom: 20px; } }
  @media (min-width: 992px) {
    .mb-30 {
      margin-bottom: 21px; } }
  @media (min-width: 1200px) {
    .mb-30 {
      margin-bottom: 24px; } }
  @media (min-width: 1400px) {
    .mb-30 {
      margin-bottom: 30px; } }

.pt-35 {
  padding-top: 20px; }
  @media (min-width: 480px) {
    .pt-35 {
      padding-top: 20px; } }
  @media (min-width: 545px) {
    .pt-35 {
      padding-top: 20px; } }
  @media (min-width: 768px) {
    .pt-35 {
      padding-top: 21px; } }
  @media (min-width: 992px) {
    .pt-35 {
      padding-top: 25px; } }
  @media (min-width: 1200px) {
    .pt-35 {
      padding-top: 28px; } }
  @media (min-width: 1400px) {
    .pt-35 {
      padding-top: 35px; } }

.pb-35 {
  padding-bottom: 20px; }
  @media (min-width: 480px) {
    .pb-35 {
      padding-bottom: 20px; } }
  @media (min-width: 545px) {
    .pb-35 {
      padding-bottom: 20px; } }
  @media (min-width: 768px) {
    .pb-35 {
      padding-bottom: 21px; } }
  @media (min-width: 992px) {
    .pb-35 {
      padding-bottom: 25px; } }
  @media (min-width: 1200px) {
    .pb-35 {
      padding-bottom: 28px; } }
  @media (min-width: 1400px) {
    .pb-35 {
      padding-bottom: 35px; } }

.mt-35 {
  margin-top: 20px; }
  @media (min-width: 480px) {
    .mt-35 {
      margin-top: 20px; } }
  @media (min-width: 545px) {
    .mt-35 {
      margin-top: 20px; } }
  @media (min-width: 768px) {
    .mt-35 {
      margin-top: 21px; } }
  @media (min-width: 992px) {
    .mt-35 {
      margin-top: 25px; } }
  @media (min-width: 1200px) {
    .mt-35 {
      margin-top: 28px; } }
  @media (min-width: 1400px) {
    .mt-35 {
      margin-top: 35px; } }

.mb-35 {
  margin-bottom: 20px; }
  @media (min-width: 480px) {
    .mb-35 {
      margin-bottom: 20px; } }
  @media (min-width: 545px) {
    .mb-35 {
      margin-bottom: 20px; } }
  @media (min-width: 768px) {
    .mb-35 {
      margin-bottom: 21px; } }
  @media (min-width: 992px) {
    .mb-35 {
      margin-bottom: 25px; } }
  @media (min-width: 1200px) {
    .mb-35 {
      margin-bottom: 28px; } }
  @media (min-width: 1400px) {
    .mb-35 {
      margin-bottom: 35px; } }

.pt-40 {
  padding-top: 20px; }
  @media (min-width: 480px) {
    .pt-40 {
      padding-top: 20px; } }
  @media (min-width: 545px) {
    .pt-40 {
      padding-top: 20px; } }
  @media (min-width: 768px) {
    .pt-40 {
      padding-top: 24px; } }
  @media (min-width: 992px) {
    .pt-40 {
      padding-top: 28px; } }
  @media (min-width: 1200px) {
    .pt-40 {
      padding-top: 32px; } }
  @media (min-width: 1400px) {
    .pt-40 {
      padding-top: 40px; } }

.pb-40 {
  padding-bottom: 20px; }
  @media (min-width: 480px) {
    .pb-40 {
      padding-bottom: 20px; } }
  @media (min-width: 545px) {
    .pb-40 {
      padding-bottom: 20px; } }
  @media (min-width: 768px) {
    .pb-40 {
      padding-bottom: 24px; } }
  @media (min-width: 992px) {
    .pb-40 {
      padding-bottom: 28px; } }
  @media (min-width: 1200px) {
    .pb-40 {
      padding-bottom: 32px; } }
  @media (min-width: 1400px) {
    .pb-40 {
      padding-bottom: 40px; } }

.mt-40 {
  margin-top: 20px; }
  @media (min-width: 480px) {
    .mt-40 {
      margin-top: 20px; } }
  @media (min-width: 545px) {
    .mt-40 {
      margin-top: 20px; } }
  @media (min-width: 768px) {
    .mt-40 {
      margin-top: 24px; } }
  @media (min-width: 992px) {
    .mt-40 {
      margin-top: 28px; } }
  @media (min-width: 1200px) {
    .mt-40 {
      margin-top: 32px; } }
  @media (min-width: 1400px) {
    .mt-40 {
      margin-top: 40px; } }

.mb-40 {
  margin-bottom: 20px; }
  @media (min-width: 480px) {
    .mb-40 {
      margin-bottom: 20px; } }
  @media (min-width: 545px) {
    .mb-40 {
      margin-bottom: 20px; } }
  @media (min-width: 768px) {
    .mb-40 {
      margin-bottom: 24px; } }
  @media (min-width: 992px) {
    .mb-40 {
      margin-bottom: 28px; } }
  @media (min-width: 1200px) {
    .mb-40 {
      margin-bottom: 32px; } }
  @media (min-width: 1400px) {
    .mb-40 {
      margin-bottom: 40px; } }

.pt-45 {
  padding-top: 20px; }
  @media (min-width: 480px) {
    .pt-45 {
      padding-top: 20px; } }
  @media (min-width: 545px) {
    .pt-45 {
      padding-top: 23px; } }
  @media (min-width: 768px) {
    .pt-45 {
      padding-top: 27px; } }
  @media (min-width: 992px) {
    .pt-45 {
      padding-top: 32px; } }
  @media (min-width: 1200px) {
    .pt-45 {
      padding-top: 36px; } }
  @media (min-width: 1400px) {
    .pt-45 {
      padding-top: 45px; } }

.pb-45 {
  padding-bottom: 20px; }
  @media (min-width: 480px) {
    .pb-45 {
      padding-bottom: 20px; } }
  @media (min-width: 545px) {
    .pb-45 {
      padding-bottom: 23px; } }
  @media (min-width: 768px) {
    .pb-45 {
      padding-bottom: 27px; } }
  @media (min-width: 992px) {
    .pb-45 {
      padding-bottom: 32px; } }
  @media (min-width: 1200px) {
    .pb-45 {
      padding-bottom: 36px; } }
  @media (min-width: 1400px) {
    .pb-45 {
      padding-bottom: 45px; } }

.mt-45 {
  margin-top: 20px; }
  @media (min-width: 480px) {
    .mt-45 {
      margin-top: 20px; } }
  @media (min-width: 545px) {
    .mt-45 {
      margin-top: 23px; } }
  @media (min-width: 768px) {
    .mt-45 {
      margin-top: 27px; } }
  @media (min-width: 992px) {
    .mt-45 {
      margin-top: 32px; } }
  @media (min-width: 1200px) {
    .mt-45 {
      margin-top: 36px; } }
  @media (min-width: 1400px) {
    .mt-45 {
      margin-top: 45px; } }

.mb-45 {
  margin-bottom: 20px; }
  @media (min-width: 480px) {
    .mb-45 {
      margin-bottom: 20px; } }
  @media (min-width: 545px) {
    .mb-45 {
      margin-bottom: 23px; } }
  @media (min-width: 768px) {
    .mb-45 {
      margin-bottom: 27px; } }
  @media (min-width: 992px) {
    .mb-45 {
      margin-bottom: 32px; } }
  @media (min-width: 1200px) {
    .mb-45 {
      margin-bottom: 36px; } }
  @media (min-width: 1400px) {
    .mb-45 {
      margin-bottom: 45px; } }

.pt-50 {
  padding-top: 20px; }
  @media (min-width: 480px) {
    .pt-50 {
      padding-top: 20px; } }
  @media (min-width: 545px) {
    .pt-50 {
      padding-top: 25px; } }
  @media (min-width: 768px) {
    .pt-50 {
      padding-top: 30px; } }
  @media (min-width: 992px) {
    .pt-50 {
      padding-top: 35px; } }
  @media (min-width: 1200px) {
    .pt-50 {
      padding-top: 40px; } }
  @media (min-width: 1400px) {
    .pt-50 {
      padding-top: 50px; } }

.pb-50 {
  padding-bottom: 20px; }
  @media (min-width: 480px) {
    .pb-50 {
      padding-bottom: 20px; } }
  @media (min-width: 545px) {
    .pb-50 {
      padding-bottom: 25px; } }
  @media (min-width: 768px) {
    .pb-50 {
      padding-bottom: 30px; } }
  @media (min-width: 992px) {
    .pb-50 {
      padding-bottom: 35px; } }
  @media (min-width: 1200px) {
    .pb-50 {
      padding-bottom: 40px; } }
  @media (min-width: 1400px) {
    .pb-50 {
      padding-bottom: 50px; } }

.mt-50 {
  margin-top: 20px; }
  @media (min-width: 480px) {
    .mt-50 {
      margin-top: 20px; } }
  @media (min-width: 545px) {
    .mt-50 {
      margin-top: 25px; } }
  @media (min-width: 768px) {
    .mt-50 {
      margin-top: 30px; } }
  @media (min-width: 992px) {
    .mt-50 {
      margin-top: 35px; } }
  @media (min-width: 1200px) {
    .mt-50 {
      margin-top: 40px; } }
  @media (min-width: 1400px) {
    .mt-50 {
      margin-top: 50px; } }

.mb-50 {
  margin-bottom: 20px; }
  @media (min-width: 480px) {
    .mb-50 {
      margin-bottom: 20px; } }
  @media (min-width: 545px) {
    .mb-50 {
      margin-bottom: 25px; } }
  @media (min-width: 768px) {
    .mb-50 {
      margin-bottom: 30px; } }
  @media (min-width: 992px) {
    .mb-50 {
      margin-bottom: 35px; } }
  @media (min-width: 1200px) {
    .mb-50 {
      margin-bottom: 40px; } }
  @media (min-width: 1400px) {
    .mb-50 {
      margin-bottom: 50px; } }

.pt-55 {
  padding-top: 22px; }
  @media (min-width: 480px) {
    .pt-55 {
      padding-top: 22px; } }
  @media (min-width: 545px) {
    .pt-55 {
      padding-top: 28px; } }
  @media (min-width: 768px) {
    .pt-55 {
      padding-top: 33px; } }
  @media (min-width: 992px) {
    .pt-55 {
      padding-top: 39px; } }
  @media (min-width: 1200px) {
    .pt-55 {
      padding-top: 44px; } }
  @media (min-width: 1400px) {
    .pt-55 {
      padding-top: 55px; } }

.pb-55 {
  padding-bottom: 22px; }
  @media (min-width: 480px) {
    .pb-55 {
      padding-bottom: 22px; } }
  @media (min-width: 545px) {
    .pb-55 {
      padding-bottom: 28px; } }
  @media (min-width: 768px) {
    .pb-55 {
      padding-bottom: 33px; } }
  @media (min-width: 992px) {
    .pb-55 {
      padding-bottom: 39px; } }
  @media (min-width: 1200px) {
    .pb-55 {
      padding-bottom: 44px; } }
  @media (min-width: 1400px) {
    .pb-55 {
      padding-bottom: 55px; } }

.mt-55 {
  margin-top: 22px; }
  @media (min-width: 480px) {
    .mt-55 {
      margin-top: 22px; } }
  @media (min-width: 545px) {
    .mt-55 {
      margin-top: 28px; } }
  @media (min-width: 768px) {
    .mt-55 {
      margin-top: 33px; } }
  @media (min-width: 992px) {
    .mt-55 {
      margin-top: 39px; } }
  @media (min-width: 1200px) {
    .mt-55 {
      margin-top: 44px; } }
  @media (min-width: 1400px) {
    .mt-55 {
      margin-top: 55px; } }

.mb-55 {
  margin-bottom: 22px; }
  @media (min-width: 480px) {
    .mb-55 {
      margin-bottom: 22px; } }
  @media (min-width: 545px) {
    .mb-55 {
      margin-bottom: 28px; } }
  @media (min-width: 768px) {
    .mb-55 {
      margin-bottom: 33px; } }
  @media (min-width: 992px) {
    .mb-55 {
      margin-bottom: 39px; } }
  @media (min-width: 1200px) {
    .mb-55 {
      margin-bottom: 44px; } }
  @media (min-width: 1400px) {
    .mb-55 {
      margin-bottom: 55px; } }

.pt-60 {
  padding-top: 24px; }
  @media (min-width: 480px) {
    .pt-60 {
      padding-top: 24px; } }
  @media (min-width: 545px) {
    .pt-60 {
      padding-top: 30px; } }
  @media (min-width: 768px) {
    .pt-60 {
      padding-top: 36px; } }
  @media (min-width: 992px) {
    .pt-60 {
      padding-top: 42px; } }
  @media (min-width: 1200px) {
    .pt-60 {
      padding-top: 48px; } }
  @media (min-width: 1400px) {
    .pt-60 {
      padding-top: 60px; } }

.pb-60 {
  padding-bottom: 24px; }
  @media (min-width: 480px) {
    .pb-60 {
      padding-bottom: 24px; } }
  @media (min-width: 545px) {
    .pb-60 {
      padding-bottom: 30px; } }
  @media (min-width: 768px) {
    .pb-60 {
      padding-bottom: 36px; } }
  @media (min-width: 992px) {
    .pb-60 {
      padding-bottom: 42px; } }
  @media (min-width: 1200px) {
    .pb-60 {
      padding-bottom: 48px; } }
  @media (min-width: 1400px) {
    .pb-60 {
      padding-bottom: 60px; } }

.mt-60 {
  margin-top: 24px; }
  @media (min-width: 480px) {
    .mt-60 {
      margin-top: 24px; } }
  @media (min-width: 545px) {
    .mt-60 {
      margin-top: 30px; } }
  @media (min-width: 768px) {
    .mt-60 {
      margin-top: 36px; } }
  @media (min-width: 992px) {
    .mt-60 {
      margin-top: 42px; } }
  @media (min-width: 1200px) {
    .mt-60 {
      margin-top: 48px; } }
  @media (min-width: 1400px) {
    .mt-60 {
      margin-top: 60px; } }

.mb-60 {
  margin-bottom: 24px; }
  @media (min-width: 480px) {
    .mb-60 {
      margin-bottom: 24px; } }
  @media (min-width: 545px) {
    .mb-60 {
      margin-bottom: 30px; } }
  @media (min-width: 768px) {
    .mb-60 {
      margin-bottom: 36px; } }
  @media (min-width: 992px) {
    .mb-60 {
      margin-bottom: 42px; } }
  @media (min-width: 1200px) {
    .mb-60 {
      margin-bottom: 48px; } }
  @media (min-width: 1400px) {
    .mb-60 {
      margin-bottom: 60px; } }

.pt-65 {
  padding-top: 26px; }
  @media (min-width: 480px) {
    .pt-65 {
      padding-top: 26px; } }
  @media (min-width: 545px) {
    .pt-65 {
      padding-top: 33px; } }
  @media (min-width: 768px) {
    .pt-65 {
      padding-top: 39px; } }
  @media (min-width: 992px) {
    .pt-65 {
      padding-top: 46px; } }
  @media (min-width: 1200px) {
    .pt-65 {
      padding-top: 52px; } }
  @media (min-width: 1400px) {
    .pt-65 {
      padding-top: 65px; } }

.pb-65 {
  padding-bottom: 26px; }
  @media (min-width: 480px) {
    .pb-65 {
      padding-bottom: 26px; } }
  @media (min-width: 545px) {
    .pb-65 {
      padding-bottom: 33px; } }
  @media (min-width: 768px) {
    .pb-65 {
      padding-bottom: 39px; } }
  @media (min-width: 992px) {
    .pb-65 {
      padding-bottom: 46px; } }
  @media (min-width: 1200px) {
    .pb-65 {
      padding-bottom: 52px; } }
  @media (min-width: 1400px) {
    .pb-65 {
      padding-bottom: 65px; } }

.mt-65 {
  margin-top: 26px; }
  @media (min-width: 480px) {
    .mt-65 {
      margin-top: 26px; } }
  @media (min-width: 545px) {
    .mt-65 {
      margin-top: 33px; } }
  @media (min-width: 768px) {
    .mt-65 {
      margin-top: 39px; } }
  @media (min-width: 992px) {
    .mt-65 {
      margin-top: 46px; } }
  @media (min-width: 1200px) {
    .mt-65 {
      margin-top: 52px; } }
  @media (min-width: 1400px) {
    .mt-65 {
      margin-top: 65px; } }

.mb-65 {
  margin-bottom: 26px; }
  @media (min-width: 480px) {
    .mb-65 {
      margin-bottom: 26px; } }
  @media (min-width: 545px) {
    .mb-65 {
      margin-bottom: 33px; } }
  @media (min-width: 768px) {
    .mb-65 {
      margin-bottom: 39px; } }
  @media (min-width: 992px) {
    .mb-65 {
      margin-bottom: 46px; } }
  @media (min-width: 1200px) {
    .mb-65 {
      margin-bottom: 52px; } }
  @media (min-width: 1400px) {
    .mb-65 {
      margin-bottom: 65px; } }

.pt-70 {
  padding-top: 28px; }
  @media (min-width: 480px) {
    .pt-70 {
      padding-top: 28px; } }
  @media (min-width: 545px) {
    .pt-70 {
      padding-top: 35px; } }
  @media (min-width: 768px) {
    .pt-70 {
      padding-top: 42px; } }
  @media (min-width: 992px) {
    .pt-70 {
      padding-top: 49px; } }
  @media (min-width: 1200px) {
    .pt-70 {
      padding-top: 56px; } }
  @media (min-width: 1400px) {
    .pt-70 {
      padding-top: 70px; } }

.pb-70 {
  padding-bottom: 28px; }
  @media (min-width: 480px) {
    .pb-70 {
      padding-bottom: 28px; } }
  @media (min-width: 545px) {
    .pb-70 {
      padding-bottom: 35px; } }
  @media (min-width: 768px) {
    .pb-70 {
      padding-bottom: 42px; } }
  @media (min-width: 992px) {
    .pb-70 {
      padding-bottom: 49px; } }
  @media (min-width: 1200px) {
    .pb-70 {
      padding-bottom: 56px; } }
  @media (min-width: 1400px) {
    .pb-70 {
      padding-bottom: 70px; } }

.mt-70 {
  margin-top: 28px; }
  @media (min-width: 480px) {
    .mt-70 {
      margin-top: 28px; } }
  @media (min-width: 545px) {
    .mt-70 {
      margin-top: 35px; } }
  @media (min-width: 768px) {
    .mt-70 {
      margin-top: 42px; } }
  @media (min-width: 992px) {
    .mt-70 {
      margin-top: 49px; } }
  @media (min-width: 1200px) {
    .mt-70 {
      margin-top: 56px; } }
  @media (min-width: 1400px) {
    .mt-70 {
      margin-top: 70px; } }

.mb-70 {
  margin-bottom: 28px; }
  @media (min-width: 480px) {
    .mb-70 {
      margin-bottom: 28px; } }
  @media (min-width: 545px) {
    .mb-70 {
      margin-bottom: 35px; } }
  @media (min-width: 768px) {
    .mb-70 {
      margin-bottom: 42px; } }
  @media (min-width: 992px) {
    .mb-70 {
      margin-bottom: 49px; } }
  @media (min-width: 1200px) {
    .mb-70 {
      margin-bottom: 56px; } }
  @media (min-width: 1400px) {
    .mb-70 {
      margin-bottom: 70px; } }

.pt-75 {
  padding-top: 30px; }
  @media (min-width: 480px) {
    .pt-75 {
      padding-top: 30px; } }
  @media (min-width: 545px) {
    .pt-75 {
      padding-top: 38px; } }
  @media (min-width: 768px) {
    .pt-75 {
      padding-top: 45px; } }
  @media (min-width: 992px) {
    .pt-75 {
      padding-top: 53px; } }
  @media (min-width: 1200px) {
    .pt-75 {
      padding-top: 60px; } }
  @media (min-width: 1400px) {
    .pt-75 {
      padding-top: 75px; } }

.pb-75 {
  padding-bottom: 30px; }
  @media (min-width: 480px) {
    .pb-75 {
      padding-bottom: 30px; } }
  @media (min-width: 545px) {
    .pb-75 {
      padding-bottom: 38px; } }
  @media (min-width: 768px) {
    .pb-75 {
      padding-bottom: 45px; } }
  @media (min-width: 992px) {
    .pb-75 {
      padding-bottom: 53px; } }
  @media (min-width: 1200px) {
    .pb-75 {
      padding-bottom: 60px; } }
  @media (min-width: 1400px) {
    .pb-75 {
      padding-bottom: 75px; } }

.mt-75 {
  margin-top: 30px; }
  @media (min-width: 480px) {
    .mt-75 {
      margin-top: 30px; } }
  @media (min-width: 545px) {
    .mt-75 {
      margin-top: 38px; } }
  @media (min-width: 768px) {
    .mt-75 {
      margin-top: 45px; } }
  @media (min-width: 992px) {
    .mt-75 {
      margin-top: 53px; } }
  @media (min-width: 1200px) {
    .mt-75 {
      margin-top: 60px; } }
  @media (min-width: 1400px) {
    .mt-75 {
      margin-top: 75px; } }

.mb-75 {
  margin-bottom: 30px; }
  @media (min-width: 480px) {
    .mb-75 {
      margin-bottom: 30px; } }
  @media (min-width: 545px) {
    .mb-75 {
      margin-bottom: 38px; } }
  @media (min-width: 768px) {
    .mb-75 {
      margin-bottom: 45px; } }
  @media (min-width: 992px) {
    .mb-75 {
      margin-bottom: 53px; } }
  @media (min-width: 1200px) {
    .mb-75 {
      margin-bottom: 60px; } }
  @media (min-width: 1400px) {
    .mb-75 {
      margin-bottom: 75px; } }

.pt-80 {
  padding-top: 32px; }
  @media (min-width: 480px) {
    .pt-80 {
      padding-top: 32px; } }
  @media (min-width: 545px) {
    .pt-80 {
      padding-top: 40px; } }
  @media (min-width: 768px) {
    .pt-80 {
      padding-top: 48px; } }
  @media (min-width: 992px) {
    .pt-80 {
      padding-top: 56px; } }
  @media (min-width: 1200px) {
    .pt-80 {
      padding-top: 64px; } }
  @media (min-width: 1400px) {
    .pt-80 {
      padding-top: 80px; } }

.pb-80 {
  padding-bottom: 32px; }
  @media (min-width: 480px) {
    .pb-80 {
      padding-bottom: 32px; } }
  @media (min-width: 545px) {
    .pb-80 {
      padding-bottom: 40px; } }
  @media (min-width: 768px) {
    .pb-80 {
      padding-bottom: 48px; } }
  @media (min-width: 992px) {
    .pb-80 {
      padding-bottom: 56px; } }
  @media (min-width: 1200px) {
    .pb-80 {
      padding-bottom: 64px; } }
  @media (min-width: 1400px) {
    .pb-80 {
      padding-bottom: 80px; } }

.mt-80 {
  margin-top: 32px; }
  @media (min-width: 480px) {
    .mt-80 {
      margin-top: 32px; } }
  @media (min-width: 545px) {
    .mt-80 {
      margin-top: 40px; } }
  @media (min-width: 768px) {
    .mt-80 {
      margin-top: 48px; } }
  @media (min-width: 992px) {
    .mt-80 {
      margin-top: 56px; } }
  @media (min-width: 1200px) {
    .mt-80 {
      margin-top: 64px; } }
  @media (min-width: 1400px) {
    .mt-80 {
      margin-top: 80px; } }

.mb-80 {
  margin-bottom: 32px; }
  @media (min-width: 480px) {
    .mb-80 {
      margin-bottom: 32px; } }
  @media (min-width: 545px) {
    .mb-80 {
      margin-bottom: 40px; } }
  @media (min-width: 768px) {
    .mb-80 {
      margin-bottom: 48px; } }
  @media (min-width: 992px) {
    .mb-80 {
      margin-bottom: 56px; } }
  @media (min-width: 1200px) {
    .mb-80 {
      margin-bottom: 64px; } }
  @media (min-width: 1400px) {
    .mb-80 {
      margin-bottom: 80px; } }

.pt-85 {
  padding-top: 34px; }
  @media (min-width: 480px) {
    .pt-85 {
      padding-top: 34px; } }
  @media (min-width: 545px) {
    .pt-85 {
      padding-top: 43px; } }
  @media (min-width: 768px) {
    .pt-85 {
      padding-top: 51px; } }
  @media (min-width: 992px) {
    .pt-85 {
      padding-top: 60px; } }
  @media (min-width: 1200px) {
    .pt-85 {
      padding-top: 68px; } }
  @media (min-width: 1400px) {
    .pt-85 {
      padding-top: 85px; } }

.pb-85 {
  padding-bottom: 34px; }
  @media (min-width: 480px) {
    .pb-85 {
      padding-bottom: 34px; } }
  @media (min-width: 545px) {
    .pb-85 {
      padding-bottom: 43px; } }
  @media (min-width: 768px) {
    .pb-85 {
      padding-bottom: 51px; } }
  @media (min-width: 992px) {
    .pb-85 {
      padding-bottom: 60px; } }
  @media (min-width: 1200px) {
    .pb-85 {
      padding-bottom: 68px; } }
  @media (min-width: 1400px) {
    .pb-85 {
      padding-bottom: 85px; } }

.mt-85 {
  margin-top: 34px; }
  @media (min-width: 480px) {
    .mt-85 {
      margin-top: 34px; } }
  @media (min-width: 545px) {
    .mt-85 {
      margin-top: 43px; } }
  @media (min-width: 768px) {
    .mt-85 {
      margin-top: 51px; } }
  @media (min-width: 992px) {
    .mt-85 {
      margin-top: 60px; } }
  @media (min-width: 1200px) {
    .mt-85 {
      margin-top: 68px; } }
  @media (min-width: 1400px) {
    .mt-85 {
      margin-top: 85px; } }

.mb-85 {
  margin-bottom: 34px; }
  @media (min-width: 480px) {
    .mb-85 {
      margin-bottom: 34px; } }
  @media (min-width: 545px) {
    .mb-85 {
      margin-bottom: 43px; } }
  @media (min-width: 768px) {
    .mb-85 {
      margin-bottom: 51px; } }
  @media (min-width: 992px) {
    .mb-85 {
      margin-bottom: 60px; } }
  @media (min-width: 1200px) {
    .mb-85 {
      margin-bottom: 68px; } }
  @media (min-width: 1400px) {
    .mb-85 {
      margin-bottom: 85px; } }

.pt-90 {
  padding-top: 36px; }
  @media (min-width: 480px) {
    .pt-90 {
      padding-top: 36px; } }
  @media (min-width: 545px) {
    .pt-90 {
      padding-top: 45px; } }
  @media (min-width: 768px) {
    .pt-90 {
      padding-top: 54px; } }
  @media (min-width: 992px) {
    .pt-90 {
      padding-top: 63px; } }
  @media (min-width: 1200px) {
    .pt-90 {
      padding-top: 72px; } }
  @media (min-width: 1400px) {
    .pt-90 {
      padding-top: 90px; } }

.pb-90 {
  padding-bottom: 36px; }
  @media (min-width: 480px) {
    .pb-90 {
      padding-bottom: 36px; } }
  @media (min-width: 545px) {
    .pb-90 {
      padding-bottom: 45px; } }
  @media (min-width: 768px) {
    .pb-90 {
      padding-bottom: 54px; } }
  @media (min-width: 992px) {
    .pb-90 {
      padding-bottom: 63px; } }
  @media (min-width: 1200px) {
    .pb-90 {
      padding-bottom: 72px; } }
  @media (min-width: 1400px) {
    .pb-90 {
      padding-bottom: 90px; } }

.mt-90 {
  margin-top: 36px; }
  @media (min-width: 480px) {
    .mt-90 {
      margin-top: 36px; } }
  @media (min-width: 545px) {
    .mt-90 {
      margin-top: 45px; } }
  @media (min-width: 768px) {
    .mt-90 {
      margin-top: 54px; } }
  @media (min-width: 992px) {
    .mt-90 {
      margin-top: 63px; } }
  @media (min-width: 1200px) {
    .mt-90 {
      margin-top: 72px; } }
  @media (min-width: 1400px) {
    .mt-90 {
      margin-top: 90px; } }

.mb-90 {
  margin-bottom: 36px; }
  @media (min-width: 480px) {
    .mb-90 {
      margin-bottom: 36px; } }
  @media (min-width: 545px) {
    .mb-90 {
      margin-bottom: 45px; } }
  @media (min-width: 768px) {
    .mb-90 {
      margin-bottom: 54px; } }
  @media (min-width: 992px) {
    .mb-90 {
      margin-bottom: 63px; } }
  @media (min-width: 1200px) {
    .mb-90 {
      margin-bottom: 72px; } }
  @media (min-width: 1400px) {
    .mb-90 {
      margin-bottom: 90px; } }

.pt-95 {
  padding-top: 38px; }
  @media (min-width: 480px) {
    .pt-95 {
      padding-top: 38px; } }
  @media (min-width: 545px) {
    .pt-95 {
      padding-top: 48px; } }
  @media (min-width: 768px) {
    .pt-95 {
      padding-top: 57px; } }
  @media (min-width: 992px) {
    .pt-95 {
      padding-top: 67px; } }
  @media (min-width: 1200px) {
    .pt-95 {
      padding-top: 76px; } }
  @media (min-width: 1400px) {
    .pt-95 {
      padding-top: 95px; } }

.pb-95 {
  padding-bottom: 38px; }
  @media (min-width: 480px) {
    .pb-95 {
      padding-bottom: 38px; } }
  @media (min-width: 545px) {
    .pb-95 {
      padding-bottom: 48px; } }
  @media (min-width: 768px) {
    .pb-95 {
      padding-bottom: 57px; } }
  @media (min-width: 992px) {
    .pb-95 {
      padding-bottom: 67px; } }
  @media (min-width: 1200px) {
    .pb-95 {
      padding-bottom: 76px; } }
  @media (min-width: 1400px) {
    .pb-95 {
      padding-bottom: 95px; } }

.mt-95 {
  margin-top: 38px; }
  @media (min-width: 480px) {
    .mt-95 {
      margin-top: 38px; } }
  @media (min-width: 545px) {
    .mt-95 {
      margin-top: 48px; } }
  @media (min-width: 768px) {
    .mt-95 {
      margin-top: 57px; } }
  @media (min-width: 992px) {
    .mt-95 {
      margin-top: 67px; } }
  @media (min-width: 1200px) {
    .mt-95 {
      margin-top: 76px; } }
  @media (min-width: 1400px) {
    .mt-95 {
      margin-top: 95px; } }

.mb-95 {
  margin-bottom: 38px; }
  @media (min-width: 480px) {
    .mb-95 {
      margin-bottom: 38px; } }
  @media (min-width: 545px) {
    .mb-95 {
      margin-bottom: 48px; } }
  @media (min-width: 768px) {
    .mb-95 {
      margin-bottom: 57px; } }
  @media (min-width: 992px) {
    .mb-95 {
      margin-bottom: 67px; } }
  @media (min-width: 1200px) {
    .mb-95 {
      margin-bottom: 76px; } }
  @media (min-width: 1400px) {
    .mb-95 {
      margin-bottom: 95px; } }

.pt-100 {
  padding-top: 40px; }
  @media (min-width: 480px) {
    .pt-100 {
      padding-top: 40px; } }
  @media (min-width: 545px) {
    .pt-100 {
      padding-top: 50px; } }
  @media (min-width: 768px) {
    .pt-100 {
      padding-top: 60px; } }
  @media (min-width: 992px) {
    .pt-100 {
      padding-top: 70px; } }
  @media (min-width: 1200px) {
    .pt-100 {
      padding-top: 80px; } }
  @media (min-width: 1400px) {
    .pt-100 {
      padding-top: 100px; } }

.pb-100 {
  padding-bottom: 40px; }
  @media (min-width: 480px) {
    .pb-100 {
      padding-bottom: 40px; } }
  @media (min-width: 545px) {
    .pb-100 {
      padding-bottom: 50px; } }
  @media (min-width: 768px) {
    .pb-100 {
      padding-bottom: 60px; } }
  @media (min-width: 992px) {
    .pb-100 {
      padding-bottom: 70px; } }
  @media (min-width: 1200px) {
    .pb-100 {
      padding-bottom: 80px; } }
  @media (min-width: 1400px) {
    .pb-100 {
      padding-bottom: 100px; } }

.mt-100 {
  margin-top: 40px; }
  @media (min-width: 480px) {
    .mt-100 {
      margin-top: 40px; } }
  @media (min-width: 545px) {
    .mt-100 {
      margin-top: 50px; } }
  @media (min-width: 768px) {
    .mt-100 {
      margin-top: 60px; } }
  @media (min-width: 992px) {
    .mt-100 {
      margin-top: 70px; } }
  @media (min-width: 1200px) {
    .mt-100 {
      margin-top: 80px; } }
  @media (min-width: 1400px) {
    .mt-100 {
      margin-top: 100px; } }

.mb-100 {
  margin-bottom: 40px; }
  @media (min-width: 480px) {
    .mb-100 {
      margin-bottom: 40px; } }
  @media (min-width: 545px) {
    .mb-100 {
      margin-bottom: 50px; } }
  @media (min-width: 768px) {
    .mb-100 {
      margin-bottom: 60px; } }
  @media (min-width: 992px) {
    .mb-100 {
      margin-bottom: 70px; } }
  @media (min-width: 1200px) {
    .mb-100 {
      margin-bottom: 80px; } }
  @media (min-width: 1400px) {
    .mb-100 {
      margin-bottom: 100px; } }

.no-padding-bottom {
  padding-bottom: 0px !important; }

h1,
.h1 {
  font-size: 32px;
  font-size: 2rem;
  line-height: 40px;
  margin-bottom: 20px;
  font-weight: 300; }
  @media (min-width: 480px) {
    h1,
    .h1 {
      font-size: 32px;
      font-size: 2rem; } }
  @media (min-width: 545px) {
    h1,
    .h1 {
      font-size: 40px;
      font-size: 2.5rem; } }
  @media (min-width: 768px) {
    h1,
    .h1 {
      font-size: 48px;
      font-size: 3rem; } }
  @media (min-width: 992px) {
    h1,
    .h1 {
      font-size: 56px;
      font-size: 3.5rem; } }
  @media (min-width: 1200px) {
    h1,
    .h1 {
      font-size: 64px;
      font-size: 4rem; } }
  @media (min-width: 1400px) {
    h1,
    .h1 {
      font-size: 80px;
      font-size: 5rem; } }
  @media (min-width: 480px) {
    h1,
    .h1 {
      line-height: 40px; } }
  @media (min-width: 545px) {
    h1,
    .h1 {
      line-height: 50px; } }
  @media (min-width: 768px) {
    h1,
    .h1 {
      line-height: 60px; } }
  @media (min-width: 992px) {
    h1,
    .h1 {
      line-height: 70px; } }
  @media (min-width: 1200px) {
    h1,
    .h1 {
      line-height: 80px; } }
  @media (min-width: 1400px) {
    h1,
    .h1 {
      line-height: 100px; } }
  @media (min-width: 480px) {
    h1,
    .h1 {
      margin-bottom: 20px; } }
  @media (min-width: 545px) {
    h1,
    .h1 {
      margin-bottom: 20px; } }
  @media (min-width: 768px) {
    h1,
    .h1 {
      margin-bottom: 20px; } }
  @media (min-width: 992px) {
    h1,
    .h1 {
      margin-bottom: 21px; } }
  @media (min-width: 1200px) {
    h1,
    .h1 {
      margin-bottom: 24px; } }
  @media (min-width: 1400px) {
    h1,
    .h1 {
      margin-bottom: 30px; } }

h2,
.h2 {
  font-size: 22px;
  font-size: 1.375rem;
  line-height: 28px;
  margin-bottom: 20px;
  font-weight: 700; }
  @media (min-width: 480px) {
    h2,
    .h2 {
      font-size: 22px;
      font-size: 1.375rem; } }
  @media (min-width: 545px) {
    h2,
    .h2 {
      font-size: 28px;
      font-size: 1.75rem; } }
  @media (min-width: 768px) {
    h2,
    .h2 {
      font-size: 33px;
      font-size: 2.0625rem; } }
  @media (min-width: 992px) {
    h2,
    .h2 {
      font-size: 39px;
      font-size: 2.4375rem; } }
  @media (min-width: 1200px) {
    h2,
    .h2 {
      font-size: 44px;
      font-size: 2.75rem; } }
  @media (min-width: 1400px) {
    h2,
    .h2 {
      font-size: 55px;
      font-size: 3.4375rem; } }
  @media (min-width: 480px) {
    h2,
    .h2 {
      line-height: 28px; } }
  @media (min-width: 545px) {
    h2,
    .h2 {
      line-height: 28px; } }
  @media (min-width: 768px) {
    h2,
    .h2 {
      line-height: 33px; } }
  @media (min-width: 992px) {
    h2,
    .h2 {
      line-height: 39px; } }
  @media (min-width: 1200px) {
    h2,
    .h2 {
      line-height: 44px; } }
  @media (min-width: 1400px) {
    h2,
    .h2 {
      line-height: 55px; } }
  @media (min-width: 480px) {
    h2,
    .h2 {
      margin-bottom: 20px; } }
  @media (min-width: 545px) {
    h2,
    .h2 {
      margin-bottom: 20px; } }
  @media (min-width: 768px) {
    h2,
    .h2 {
      margin-bottom: 20px; } }
  @media (min-width: 992px) {
    h2,
    .h2 {
      margin-bottom: 21px; } }
  @media (min-width: 1200px) {
    h2,
    .h2 {
      margin-bottom: 24px; } }
  @media (min-width: 1400px) {
    h2,
    .h2 {
      margin-bottom: 30px; } }

h3,
.h3 {
  font-size: 24px;
  font-size: 1.5rem;
  line-height: 30px;
  margin-bottom: 15px;
  font-weight: 700; }
  @media (min-width: 480px) {
    h3,
    .h3 {
      font-size: 24px;
      font-size: 1.5rem; } }
  @media (min-width: 545px) {
    h3,
    .h3 {
      font-size: 24px;
      font-size: 1.5rem; } }
  @media (min-width: 768px) {
    h3,
    .h3 {
      font-size: 27px;
      font-size: 1.6875rem; } }
  @media (min-width: 992px) {
    h3,
    .h3 {
      font-size: 32px;
      font-size: 2rem; } }
  @media (min-width: 1200px) {
    h3,
    .h3 {
      font-size: 36px;
      font-size: 2.25rem; } }
  @media (min-width: 1400px) {
    h3,
    .h3 {
      font-size: 45px;
      font-size: 2.8125rem; } }
  @media (min-width: 480px) {
    h3,
    .h3 {
      line-height: 30px; } }
  @media (min-width: 545px) {
    h3,
    .h3 {
      line-height: 33px; } }
  @media (min-width: 768px) {
    h3,
    .h3 {
      line-height: 39px; } }
  @media (min-width: 992px) {
    h3,
    .h3 {
      line-height: 46px; } }
  @media (min-width: 1200px) {
    h3,
    .h3 {
      line-height: 52px; } }
  @media (min-width: 1400px) {
    h3,
    .h3 {
      line-height: 65px; } }
  @media (min-width: 480px) {
    h3,
    .h3 {
      margin-bottom: 15px; } }
  @media (min-width: 545px) {
    h3,
    .h3 {
      margin-bottom: 15px; } }
  @media (min-width: 768px) {
    h3,
    .h3 {
      margin-bottom: 15px; } }
  @media (min-width: 992px) {
    h3,
    .h3 {
      margin-bottom: 18px; } }
  @media (min-width: 1200px) {
    h3,
    .h3 {
      margin-bottom: 20px; } }
  @media (min-width: 1400px) {
    h3,
    .h3 {
      margin-bottom: 25px; } }

h4,
.h4 {
  font-size: 18px;
  font-size: 1.125rem;
  line-height: 24px;
  margin-bottom: 10px;
  font-weight: 700;
  text-transform: uppercase;
  color: #646569; }
  @media (min-width: 480px) {
    h4,
    .h4 {
      font-size: 18px;
      font-size: 1.125rem; } }
  @media (min-width: 545px) {
    h4,
    .h4 {
      font-size: 18px;
      font-size: 1.125rem; } }
  @media (min-width: 768px) {
    h4,
    .h4 {
      font-size: 18px;
      font-size: 1.125rem; } }
  @media (min-width: 992px) {
    h4,
    .h4 {
      font-size: 18px;
      font-size: 1.125rem; } }
  @media (min-width: 1200px) {
    h4,
    .h4 {
      font-size: 18px;
      font-size: 1.125rem; } }
  @media (min-width: 1400px) {
    h4,
    .h4 {
      font-size: 22px;
      font-size: 1.375rem; } }
  @media (min-width: 480px) {
    h4,
    .h4 {
      line-height: 24px; } }
  @media (min-width: 545px) {
    h4,
    .h4 {
      line-height: 24px; } }
  @media (min-width: 768px) {
    h4,
    .h4 {
      line-height: 24px; } }
  @media (min-width: 992px) {
    h4,
    .h4 {
      line-height: 24px; } }
  @media (min-width: 1200px) {
    h4,
    .h4 {
      line-height: 24px; } }
  @media (min-width: 1400px) {
    h4,
    .h4 {
      line-height: 30px; } }
  @media (min-width: 480px) {
    h4,
    .h4 {
      margin-bottom: 10px; } }
  @media (min-width: 545px) {
    h4,
    .h4 {
      margin-bottom: 10px; } }
  @media (min-width: 768px) {
    h4,
    .h4 {
      margin-bottom: 12px; } }
  @media (min-width: 992px) {
    h4,
    .h4 {
      margin-bottom: 14px; } }
  @media (min-width: 1200px) {
    h4,
    .h4 {
      margin-bottom: 16px; } }
  @media (min-width: 1400px) {
    h4,
    .h4 {
      margin-bottom: 20px; } }

big,
.perex {
  font-size: 18px;
  font-size: 1.125rem;
  line-height: 18px;
  line-height: 1.125rem;
  color: #646569; }
  @media (min-width: 480px) {
    big,
    .perex {
      font-size: 18px;
      font-size: 1.125rem;
      line-height: 18px;
      line-height: 1.125rem; } }
  @media (min-width: 545px) {
    big,
    .perex {
      font-size: 18px;
      font-size: 1.125rem;
      line-height: 18px;
      line-height: 1.125rem; } }
  @media (min-width: 768px) {
    big,
    .perex {
      font-size: 18px;
      font-size: 1.125rem;
      line-height: 21px;
      line-height: 1.3125rem; } }
  @media (min-width: 992px) {
    big,
    .perex {
      font-size: 18px;
      font-size: 1.125rem;
      line-height: 25px;
      line-height: 1.5625rem; } }
  @media (min-width: 1200px) {
    big,
    .perex {
      font-size: 18px;
      font-size: 1.125rem;
      line-height: 28px;
      line-height: 1.75rem; } }
  @media (min-width: 1400px) {
    big,
    .perex {
      font-size: 22px;
      font-size: 1.375rem;
      line-height: 35px;
      line-height: 2.1875rem; } }

small,
.text-small {
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 18px;
  line-height: 1.125rem; }
  @media (min-width: 480px) {
    small,
    .text-small {
      font-size: 14px;
      font-size: 0.875rem;
      line-height: 18px;
      line-height: 1.125rem; } }
  @media (min-width: 545px) {
    small,
    .text-small {
      font-size: 14px;
      font-size: 0.875rem;
      line-height: 18px;
      line-height: 1.125rem; } }
  @media (min-width: 768px) {
    small,
    .text-small {
      font-size: 14px;
      font-size: 0.875rem;
      line-height: 18px;
      line-height: 1.125rem; } }
  @media (min-width: 992px) {
    small,
    .text-small {
      font-size: 14px;
      font-size: 0.875rem;
      line-height: 18px;
      line-height: 1.125rem; } }
  @media (min-width: 1200px) {
    small,
    .text-small {
      font-size: 14px;
      font-size: 0.875rem;
      line-height: 20px;
      line-height: 1.25rem; } }
  @media (min-width: 1400px) {
    small,
    .text-small {
      font-size: 16px;
      font-size: 1rem;
      line-height: 25px;
      line-height: 1.5625rem; } }

a {
  color: #ed6e04;
  cursor: pointer;
  -moz-transition: all ease-in-out 200ms;
  -o-transition: all ease-in-out 200ms;
  -webkit-transition: all ease-in-out 200ms;
  transition: all ease-in-out 200ms; }
  a:focus, a:hover {
    outline: none;
    color: #323337; }

q:before {
  font-family: "Fedra", sans-serif !important;
  content: '»';
  color: #ed6e04;
  display: inline-block; }

q:after {
  font-family: "Fedra", sans-serif !important;
  content: '«';
  color: #ed6e04;
  display: inline-block; }

.btn {
  display: inline-block;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  border-radius: 0;
  border: none;
  font-size: 14px;
  line-height: 20px;
  padding: 16px 15px 14px 20px;
  text-transform: uppercase;
  -webkit-box-shadow: 4px 4px 0px 0px #dedfdf;
  -moz-box-shadow: 4px 4px 0px 0px #dedfdf;
  box-shadow: 4px 4px 0px 0px #dedfdf;
  position: relative;
  margin-right: 29px;
  margin-top: 0px;
  margin-bottom: 4px;
  margin-left: 0px;
  -moz-transition: all ease-in-out 100ms;
  -o-transition: all ease-in-out 100ms;
  -webkit-transition: all ease-in-out 100ms;
  transition: all ease-in-out 100ms; }
  .btn:before {
    content: '';
    display: block;
    position: absolute;
    right: -24px;
    top: 4px;
    font-size: 20px;
    color: #ed6e04;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 25px 0 25px 20px;
    border-color: transparent transparent transparent #dedfdf;
    -moz-transition: all ease-in-out 100ms;
    -o-transition: all ease-in-out 100ms;
    -webkit-transition: all ease-in-out 100ms;
    transition: all ease-in-out 100ms; }
  .btn:after {
    content: '';
    display: block;
    position: absolute;
    right: -20px;
    top: 0;
    font-size: 20px;
    color: #ed6e04;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 25px 0 25px 20px;
    -moz-transition: all ease-in-out 100ms;
    -o-transition: all ease-in-out 100ms;
    -webkit-transition: all ease-in-out 100ms;
    transition: all ease-in-out 100ms; }
  .btn:focus, .btn:hover {
    text-decoration: none;
    margin-top: 3px;
    margin-bottom: 1px;
    margin-left: 3px;
    margin-right: 26px;
    -webkit-box-shadow: 1px 1px 0px 0px #dedfdf;
    -moz-box-shadow: 1px 1px 0px 0px #dedfdf;
    box-shadow: 1px 1px 0px 0px #dedfdf; }
    .btn:focus:before, .btn:hover:before {
      right: -26px;
      top: 1px; }
  @media (min-width: 768px) {
    .btn {
      font-size: 17px;
      line-height: 24px;
      padding: 23px 40px 23px 50px;
      border-width: 35px 0 35px 25px; }
      .btn:before {
        right: -29px;
        border-width: 35px 0 35px 25px; }
      .btn:after {
        right: -25px;
        border-width: 35px 0 35px 25px; } }
  span .btn {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    margin-bottom: 0px;
    margin-right: 0px;
    vertical-align: top; }
    span .btn:focus, span .btn:hover {
      -webkit-box-shadow: none;
      -moz-box-shadow: none;
      box-shadow: none;
      margin-bottom: 0px;
      margin-right: 0px;
      margin-top: 0px;
      margin-left: 0px; }
  span.btn {
    padding: 0; }
  .btn-more {
    display: inline-block;
    background-color: rgba(129, 127, 126, 0.8);
    color: #fff;
    width: 30px;
    height: 30px;
    text-align: center;
    line-height: 30px;
    border-radius: 50%;
    font-size: 14px;
    overflow: hidden; }
    .btn-more:before {
      content: "\e902";
      line-height: 30px; }
    .btn-more:hover, .btn-more:focus {
      background-color: #817f7e;
      color: #fff;
      text-decoration: none; }
    @media (min-width: 768px) {
      .btn-more {
        width: 40px;
        height: 40px;
        line-height: 40px; }
        .btn-more:before {
          line-height: 40px; } }
    @media (min-width: 992px) {
      .btn-more {
        width: 60px;
        height: 60px;
        line-height: 60px;
        font-size: 22px; }
        .btn-more:before {
          line-height: 60px; } }
  .btn-link {
    color: #ed6e04;
    font-weight: 700; }
    .btn-link:after {
      position: relative;
      content: "\e908";
      font-size: 10px;
      line-height: 25px;
      margin-left: 5px;
      -moz-transition: all ease-in-out 200ms;
      -o-transition: all ease-in-out 200ms;
      -webkit-transition: all ease-in-out 200ms;
      transition: all ease-in-out 200ms; }
    .btn-link:hover {
      color: #323337;
      text-decoration: none; }
      .btn-link:hover:after {
        margin-left: 8px;
        color: #ed6e04; }

.btn-primary {
  background: #ed6e04;
  color: #fff; }
  .btn-primary:after {
    border-color: transparent transparent transparent #ed6e04; }
  .btn-primary:focus, .btn-primary:hover {
    text-decoration: none;
    background: #d46204;
    color: #fff; }
    .btn-primary:focus:after, .btn-primary:hover:after {
      border-color: transparent transparent transparent #d46204; }
  .btn-primary:focus .btn-primary,
  .btn-primary:hover .btn-primary {
    background: #d46204; }

.btn-default {
  background: #fff;
  color: #ed6e04;
  box-shadow: 4px 4px 0px 0px rgba(50, 51, 55, 0.1); }
  .btn-default:before {
    border-color: transparent transparent transparent rgba(50, 51, 55, 0.1); }
  .btn-default:after {
    border-color: transparent transparent transparent #fff; }
  .btn-default:focus, .btn-default:hover {
    text-decoration: none;
    background: #f2f2f2;
    color: #ed6e04;
    box-shadow: 1px 1px 0px 0px rgba(50, 51, 55, 0.1); }
    .btn-default:focus:after, .btn-default:hover:after {
      border-color: transparent transparent transparent #f2f2f2; }

.image-top-center {
  position: top center !important; }

.image-top-left {
  position: top left !important; }

.image-top-right {
  position: top left !important; }

.image-bottom-center {
  position: bottom center !important; }

.image-bottom-left {
  position: bottom left !important; }

.image-bottom-right {
  position: bottom right !important; }

main {
  min-height: 400px; }
  main ol {
    margin: 0;
    padding: 0;
    list-style: none;
    counter-reset: my-ol; }
    main ol li {
      position: relative;
      margin: 0 0 10px 0;
      padding: 0 0 0 40px; }
      main ol li:before {
        font-family: "Fedra", sans-serif !important;
        content: counter(my-ol);
        counter-increment: my-ol;
        color: #646569;
        position: absolute;
        left: 0;
        top: 2px;
        font-weight: bold; }
  main ul {
    margin: 0;
    padding: 0;
    list-style: none; }
    main ul li {
      font-size: 16px;
      font-size: 1rem;
      line-height: 30px;
      margin: 0 0 10px 0;
      padding: 0 0 0 40px;
      position: relative; }
      main ul li:before {
        content: "";
        width: 20px;
        height: 2px;
        background: #ed6e04;
        position: absolute;
        top: 14px;
        left: 0; }
  main table {
    width: 100%; }
    main table tr {
      border-bottom: 2px solid #e4e4e4; }
      main table tr td {
        padding: 13px 15px 12px 15px; }
      main table tr:nth-child(even) {
        background: #ebebeb; }
  main .respo-table table {
    width: 100%; }
    main .respo-table table thead {
      display: none; }
      @media (min-width: 768px) {
        main .respo-table table thead {
          display: table-header-group; } }
      main .respo-table table thead tr {
        border-bottom: 2px solid #323337; }
        main .respo-table table thead tr th {
          padding: 13px 15px 12px 15px;
          font-weight: 400; }
    main .respo-table table tbody tr {
      display: block;
      border: 1px solid #e4e4e4;
      border-bottom: none;
      margin: 0 0 20px 0; }
      main .respo-table table tbody tr:nth-child(even) {
        background: #ebebeb; }
      @media (min-width: 768px) {
        main .respo-table table tbody tr {
          display: table-row;
          margin: 0;
          border: none;
          border-bottom: 2px solid #e4e4e4; } }
      main .respo-table table tbody tr td {
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-box-pack: space-between;
        -moz-box-pack: space-between;
        -ms-flex-pack: space-between;
        -webkit-justify-content: space-between;
        justify-content: space-between;
        width: 100%;
        border-bottom: 2px solid #e4e4e4; }
        @media (min-width: 768px) {
          main .respo-table table tbody tr td {
            display: table-cell;
            border: none;
            width: auto; } }
        main .respo-table table tbody tr td:before {
          content: attr(data-th);
          display: block;
          text-align: left;
          font-family: "Fedra", sans-serif !important;
          margin: 0 15px 0 0; }
          @media (min-width: 768px) {
            main .respo-table table tbody tr td:before {
              display: none; } }

@media (min-width: 545px) {
  .two-column-list ul {
    -webkit-column-count: 2;
    -moz-column-count: 2;
    column-count: 2; } }

.btn-list ul {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -moz-flex-flow: row wrap;
  -ms-flex-direction: row;
  -ms-flex-wrap: wrap;
  -webkit-flex-flow: row wrap;
  flex-flow: row wrap;
  margin-left: -15px;
  margin-right: -15px; }
  .btn-list ul li {
    -webkit-flex: 100%;
    -moz-flex: 100%;
    -ms-flex: 100%;
    -webkit-flex: 100%;
    flex: 100%;
    max-width: 100%;
    padding-left: 15px;
    padding-right: 15px;
    margin-bottom: 30px; }
    @media (min-width: 768px) {
      .btn-list ul li {
        -webkit-flex: 33.3333%;
        -moz-flex: 33.3333%;
        -ms-flex: 33.3333%;
        -webkit-flex: 33.3333%;
        flex: 33.3333%;
        max-width: 33.3333%; } }
    .btn-list ul li:before {
      display: none; }
    .btn-list ul li a {
      display: block;
      background-color: #ed6e04;
      color: #fff;
      line-height: 22px;
      padding: 14px 30px;
      text-align: center;
      text-transform: uppercase; }
      .btn-list ul li a:hover {
        text-decoration: none;
        background-color: #d46204; }
      @media (min-width: 768px) {
        .btn-list ul li a {
          font-size: 17px;
          padding: 24px 30px; } }

/* wrappers */
.orange-wrapper {
  background-color: #ed6e04;
  color: #fff; }
  .orange-wrapper .perex,
  .orange-wrapper big {
    color: #fff; }
  .orange-wrapper a:not(.btn) {
    color: #fff;
    text-decoration: underline; }

.white-wrapper {
  background-color: #fff; }

.grey-wrapper {
  background-color: #f2f2f2; }

@media (min-width: 992px) {
  .text-block {
    padding-left: 40px;
    padding-right: 40px; } }

@media (min-width: 1200px) {
  .text-block {
    padding-left: 70px;
    padding-right: 70px; } }

.text-block h2 {
  font-weight: 300;
  margin-bottom: 20px; }
  @media (min-width: 480px) {
    .text-block h2 {
      margin-bottom: 20px; } }
  @media (min-width: 545px) {
    .text-block h2 {
      margin-bottom: 20px; } }
  @media (min-width: 768px) {
    .text-block h2 {
      margin-bottom: 24px; } }
  @media (min-width: 992px) {
    .text-block h2 {
      margin-bottom: 28px; } }
  @media (min-width: 1200px) {
    .text-block h2 {
      margin-bottom: 32px; } }
  @media (min-width: 1400px) {
    .text-block h2 {
      margin-bottom: 40px; } }
  .text-block h2 strong {
    font-weight: 700; }

.text-block p {
  margin-bottom: 20px; }
  @media (min-width: 480px) {
    .text-block p {
      margin-bottom: 20px; } }
  @media (min-width: 545px) {
    .text-block p {
      margin-bottom: 25px; } }
  @media (min-width: 768px) {
    .text-block p {
      margin-bottom: 30px; } }
  @media (min-width: 992px) {
    .text-block p {
      margin-bottom: 35px; } }
  @media (min-width: 1200px) {
    .text-block p {
      margin-bottom: 40px; } }
  @media (min-width: 1400px) {
    .text-block p {
      margin-bottom: 50px; } }

.bottom-line {
  border-bottom: 1px solid #f2f2f2; }

/* animated icons */
.animated-icon {
  opacity: 0; }
  .animated-icon.full-visible {
    opacity: 1; }

.hide {
  display: none; }

label {
  display: block;
  margin: 0px 0 10px 0;
  font-size: 16px;
  line-height: 25px;
  font-weight: 700; }

.form-control,
textarea,
input[type="text"],
input[type="password"] {
  width: 100%;
  height: 60px;
  padding: 0 30px;
  border: 1px solid #f1f1f1;
  border-radius: 0;
  background: #f1f1f1;
  -moz-transition: all ease-in-out 100ms;
  -o-transition: all ease-in-out 100ms;
  -webkit-transition: all ease-in-out 100ms;
  transition: all ease-in-out 100ms; }
  .form-control::-webkit-input-placeholder,
  textarea::-webkit-input-placeholder,
  input[type="text"]::-webkit-input-placeholder,
  input[type="password"]::-webkit-input-placeholder {
    color: #646569; }
  .form-control:-moz-placeholder,
  textarea:-moz-placeholder,
  input[type="text"]:-moz-placeholder,
  input[type="password"]:-moz-placeholder {
    color: #646569; }
  .form-control::-moz-placeholder,
  textarea::-moz-placeholder,
  input[type="text"]::-moz-placeholder,
  input[type="password"]::-moz-placeholder {
    color: #646569; }
  .form-control:-ms-input-placeholder,
  textarea:-ms-input-placeholder,
  input[type="text"]:-ms-input-placeholder,
  input[type="password"]:-ms-input-placeholder {
    color: #646569; }
  .form-control:focus,
  textarea:focus,
  input[type="text"]:focus,
  input[type="password"]:focus {
    outline: 0;
    border: 1px solid #ed6e04; }

textarea,
textarea.form-control {
  height: 180px;
  padding: 20px 30px; }
  textarea:focus,
  textarea.form-control:focus {
    border: 1px solid #ed6e04; }

.required label:before {
  content: "*";
  font-size: 10px;
  display: inline-block;
  vertical-align: 6px;
  margin: 0 2px 0 0; }

.file .uploader-upload {
  display: none; }

.file label {
  display: inline-block;
  padding: 20px;
  text-transform: uppercase;
  color: #fff;
  background: #ed6e04;
  cursor: pointer;
  font-size: 15px;
  font-size: 0.9375rem;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  border-radius: 0;
  max-width: 280px;
  margin: 0;
  margin-top: 35px;
  width: 100%;
  text-align: center;
  cursor: pointer;
  letter-spacing: 1px;
  outline: 0 !important;
  line-height: 20px; }

.file .file-name {
  display: block;
  padding: 5px 10px;
  background: #f2f2f2;
  color: #000;
  margin-top: 10px; }

.Error select,
.Error .selectric,
.Error input[type="text"],
.Error textarea {
  border: 1px solid #d40511 !important;
  -webkit-box-shadow: inset 0 0 0 1px #d40511;
  -moz-box-shadow: inset 0 0 0 1px #d40511;
  box-shadow: inset 0 0 0 1px #d40511;
  -webkit-animation-name: blink;
  -webkit-animation-duration: 1s;
  -webkit-animation-timing-function: ease;
  -webkit-animation-iteration-count: 2;
  -webkit-animation-direction: normal;
  -moz-animation-name: blink;
  -moz-animation-duration: 1s;
  -moz-animation-timing-function: ease;
  -moz-animation-iteration-count: 2;
  -moz-animation-direction: normal;
  -o-animation-name: blink;
  -o-animation-duration: 1s;
  -o-animation-timing-function: ease;
  -o-animation-iteration-count: 2;
  -o-animation-direction: normal;
  animation-name: blink;
  animation-duration: 1s;
  animation-timing-function: ease;
  animation-iteration-count: 2;
  animation-direction: normal; }

@-webkit-keyframes blink {
  0% {
    background: #fff; }
  50% {
    background: #fec7ca; }
  100% {
    background: #fff; } }

@-moz-keyframes blink {
  0% {
    background: #fff; }
  50% {
    background: #fec7ca; }
  100% {
    background: #fff; } }

@-ms-keyframes blink {
  0% {
    background: #fff; }
  50% {
    background: #fec7ca; }
  100% {
    background: #fff; } }

@keyframes blink {
  0% {
    background: #fff; }
  50% {
    background: #fec7ca; }
  100% {
    background: #fff; } }

.EditingFormErrorLabel {
  font-size: 14px;
  font-size: 0.875rem;
  color: #d40511;
  display: block;
  margin: 5px 0 15px 0; }

.ErrorLabel {
  color: #d40511;
  display: block;
  margin: 5px 10px; }

.radio input[type="radio"] {
  display: none; }
  .radio input[type="radio"] + label {
    position: relative;
    padding: 0 0 10px 30px;
    display: inline-block;
    margin: 0; }
    .radio input[type="radio"] + label:before {
      content: "";
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      -ms-border-radius: 50%;
      border-radius: 50%;
      width: 20px;
      height: 20px;
      background: #eeeff4;
      display: block;
      position: absolute;
      left: 0;
      top: 1px;
      -moz-transition: all ease-in-out 200ms;
      -o-transition: all ease-in-out 200ms;
      -webkit-transition: all ease-in-out 200ms;
      transition: all ease-in-out 200ms; }
    .radio input[type="radio"] + label:after {
      content: "";
      display: block;
      left: 5px;
      top: 6px;
      width: 10px;
      height: 10px;
      background: #ed6e04;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      -ms-border-radius: 50%;
      border-radius: 50%;
      position: absolute;
      opacity: 0;
      -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
      filter: alpha(opacity=0);
      -moz-transition: all ease-in-out 200ms;
      -o-transition: all ease-in-out 200ms;
      -webkit-transition: all ease-in-out 200ms;
      transition: all ease-in-out 200ms; }
    .radio input[type="radio"] + label:hover:after {
      opacity: 0.4;
      -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=40)";
      filter: alpha(opacity=40); }
  .radio input[type="radio"]:checked + label:after {
    opacity: 1;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
    filter: alpha(opacity=100); }

.checkbox input[type="checkbox"] {
  display: none; }
  .checkbox input[type="checkbox"] + label {
    position: relative;
    padding: 0 0 10px 30px;
    margin: 0;
    display: inline-block; }
    .checkbox input[type="checkbox"] + label:before {
      content: "";
      width: 20px;
      height: 20px;
      background: #eeeff4;
      display: block;
      position: absolute;
      left: 0;
      top: 1px;
      -moz-transition: all ease-in-out 200ms;
      -o-transition: all ease-in-out 200ms;
      -webkit-transition: all ease-in-out 200ms;
      transition: all ease-in-out 200ms; }
    .checkbox input[type="checkbox"] + label:after {
      content: "\e909";
      display: inline-block;
      line-height: 20px;
      font-size: 10px;
      width: 20px;
      color: #ed6e04;
      text-align: center;
      left: 0px;
      top: 1px;
      position: absolute;
      opacity: 0;
      -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
      filter: alpha(opacity=0);
      -moz-transition: all ease-in-out 200ms;
      -o-transition: all ease-in-out 200ms;
      -webkit-transition: all ease-in-out 200ms;
      transition: all ease-in-out 200ms; }
    .checkbox input[type="checkbox"] + label:hover:after {
      opacity: 0.4;
      -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=40)";
      filter: alpha(opacity=40); }
  .checkbox input[type="checkbox"]:checked + label:after {
    opacity: 1;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
    filter: alpha(opacity=100); }

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 100%;
  height: 60px;
  padding: 0 30px;
  border: 1px solid #f1f1f1;
  background: #f1f1f1;
  -moz-transition: all ease-in-out 100ms;
  -o-transition: all ease-in-out 100ms;
  -webkit-transition: all ease-in-out 100ms;
  transition: all ease-in-out 100ms;
  background-image: url(../img/dp-arrow.png);
  background-position: 97% center;
  background-repeat: no-repeat; }
  select:focus {
    outline: 0;
    border: 1px solid #ed6e04; }

.modal {
  background-color: rgba(255, 255, 255, 0.8); }
  .modal-dialog {
    background-color: #fff;
    width: calc(100% - 20px);
    max-width: 920px;
    padding: 20px;
    margin-top: 60px;
    margin-bottom: 40px; }
    @media (min-width: 480px) {
      .modal-dialog {
        margin-top: 60px; } }
    @media (min-width: 545px) {
      .modal-dialog {
        margin-top: 75px; } }
    @media (min-width: 768px) {
      .modal-dialog {
        margin-top: 90px; } }
    @media (min-width: 992px) {
      .modal-dialog {
        margin-top: 105px; } }
    @media (min-width: 1200px) {
      .modal-dialog {
        margin-top: 120px; } }
    @media (min-width: 1400px) {
      .modal-dialog {
        margin-top: 150px; } }
    @media (min-width: 480px) {
      .modal-dialog {
        margin-bottom: 40px; } }
    @media (min-width: 545px) {
      .modal-dialog {
        margin-bottom: 50px; } }
    @media (min-width: 768px) {
      .modal-dialog {
        margin-bottom: 60px; } }
    @media (min-width: 992px) {
      .modal-dialog {
        margin-bottom: 70px; } }
    @media (min-width: 1200px) {
      .modal-dialog {
        margin-bottom: 80px; } }
    @media (min-width: 1400px) {
      .modal-dialog {
        margin-bottom: 100px; } }
    .modal-dialog.search {
      background-color: transparent;
      padding: 30px 0 0 0;
      text-align: center; }
    @media (min-width: 768px) {
      .modal-dialog {
        padding: 40px; } }
    @media (min-width: 992px) {
      .modal-dialog {
        padding: 80px; } }
  .modal-form .editing-form-control-nested-control {
    margin-bottom: 10px; }
    @media (min-width: 480px) {
      .modal-form .editing-form-control-nested-control {
        margin-bottom: 10px; } }
    @media (min-width: 545px) {
      .modal-form .editing-form-control-nested-control {
        margin-bottom: 10px; } }
    @media (min-width: 768px) {
      .modal-form .editing-form-control-nested-control {
        margin-bottom: 12px; } }
    @media (min-width: 992px) {
      .modal-form .editing-form-control-nested-control {
        margin-bottom: 14px; } }
    @media (min-width: 1200px) {
      .modal-form .editing-form-control-nested-control {
        margin-bottom: 16px; } }
    @media (min-width: 1400px) {
      .modal-form .editing-form-control-nested-control {
        margin-bottom: 20px; } }
    .modal-form .editing-form-control-nested-control.Error {
      margin-bottom: 0; }
      .modal-form .editing-form-control-nested-control.Error + label {
        margin-bottom: 0; }
  .modal-close {
    position: absolute;
    top: 0px;
    right: 0px;
    background-color: #ed6e04;
    color: #fff;
    display: block;
    height: 40px;
    width: 40px;
    text-align: center; }
    .modal-close:before {
      content: "\e900";
      line-height: 40px !important;
      font-size: 14px; }
    .modal-close:hover {
      background-color: #d46204;
      color: #fff;
      text-decoration: none; }
    @media (min-width: 768px) {
      .modal-close {
        height: 80px;
        width: 80px; }
        .modal-close:before {
          line-height: 80px !important; } }

.newsletter-subscription {
  max-width: 440px;
  margin: 0 auto;
  font-size: 0;
  background-color: #fff; }
  .newsletter-subscription-input {
    display: inline-block;
    vertical-align: top;
    width: 100%;
    margin-right: -60px;
    padding-right: 60px; }
    .newsletter-subscription-input input[type="text"] {
      background-color: #fff;
      height: 60px;
      line-height: 60px;
      border-color: transparent;
      padding: 0 20px; }
      .newsletter-subscription-input input[type="text"]:focus {
        border-color: transparent; }
    @media (min-width: 545px) {
      .newsletter-subscription-input {
        margin-right: -80px;
        padding-right: 80px; }
        .newsletter-subscription-input input[type="text"] {
          height: 80px;
          line-height: 80px;
          padding: 0 30px; } }
  .newsletter-subscription-btn {
    position: relative;
    vertical-align: top;
    width: 60px;
    height: 60px;
    font-size: 0;
    margin: 0;
    padding: 0;
    box-shadow: none;
    background-color: #fff; }
    .newsletter-subscription-btn:before {
      content: "\e90a";
      font-size: 16px;
      border: 0;
      width: 40px;
      height: 40px;
      line-height: 40px;
      text-align: center;
      top: 10px;
      right: 10px;
      background-color: #ed6e04;
      color: #fff;
      border-radius: 50%;
      cursor: pointer; }
    .newsletter-subscription-btn:after {
      display: none; }
    .newsletter-subscription-btn:hover, .newsletter-subscription-btn:focus {
      margin: 0;
      padding: 0;
      box-shadow: none;
      background-color: #fff; }
      .newsletter-subscription-btn:hover:before, .newsletter-subscription-btn:focus:before {
        top: 10px;
        right: 10px;
        background-color: #d46204; }
    @media (min-width: 545px) {
      .newsletter-subscription-btn {
        width: 80px;
        height: 80px; }
        .newsletter-subscription-btn:before {
          width: 60px;
          height: 60px;
          line-height: 60px; } }

.checkbox-control .editing-form-control-nested-control {
  display: inline-block;
  vertical-align: top;
  width: 35px; }
  .checkbox-control .editing-form-control-nested-control label {
    padding-bottom: 0px !important; }

.checkbox-control .EditingFormLabel {
  display: inline-block;
  vertical-align: top;
  width: 100%;
  margin-left: -35px;
  padding-left: 35px; }

.infobox-wrapper {
  padding: 30px 0px 48px 0px;
  text-align: center; }
  @media (min-width: 480px) {
    .infobox-wrapper {
      padding: 30px 0px 48px 0px; } }
  @media (min-width: 545px) {
    .infobox-wrapper {
      padding: 35px 0px 60px 0px; } }
  @media (min-width: 768px) {
    .infobox-wrapper {
      padding: 42px 0px 72px 0px; } }
  @media (min-width: 992px) {
    .infobox-wrapper {
      padding: 49px 0px 84px 0px; } }
  @media (min-width: 1200px) {
    .infobox-wrapper {
      padding: 56px 0px 96px 0px; } }
  @media (min-width: 1400px) {
    .infobox-wrapper {
      padding: 70px 0px 120px 0px; } }
  .infobox-wrapper h1 {
    max-width: 1200px;
    margin: 0 auto;
    text-transform: uppercase;
    margin-bottom: 20px; }
    @media (min-width: 480px) {
      .infobox-wrapper h1 {
        margin-bottom: 20px; } }
    @media (min-width: 545px) {
      .infobox-wrapper h1 {
        margin-bottom: 20px; } }
    @media (min-width: 768px) {
      .infobox-wrapper h1 {
        margin-bottom: 20px; } }
    @media (min-width: 992px) {
      .infobox-wrapper h1 {
        margin-bottom: 21px; } }
    @media (min-width: 1200px) {
      .infobox-wrapper h1 {
        margin-bottom: 24px; } }
    @media (min-width: 1400px) {
      .infobox-wrapper h1 {
        margin-bottom: 30px; } }
  .infobox-wrapper h2 {
    max-width: 1200px;
    margin: 0 auto;
    font-weight: 300;
    margin-bottom: 20px; }
    .infobox-wrapper h2 strong {
      font-weight: 700; }
  .infobox-wrapper .perex {
    max-width: 1200px;
    margin: 0 auto;
    margin-bottom: 20px; }
    @media (min-width: 480px) {
      .infobox-wrapper .perex {
        margin-bottom: 20px; } }
    @media (min-width: 545px) {
      .infobox-wrapper .perex {
        margin-bottom: 20px; } }
    @media (min-width: 768px) {
      .infobox-wrapper .perex {
        margin-bottom: 24px; } }
    @media (min-width: 992px) {
      .infobox-wrapper .perex {
        margin-bottom: 28px; } }
    @media (min-width: 1200px) {
      .infobox-wrapper .perex {
        margin-bottom: 32px; } }
    @media (min-width: 1400px) {
      .infobox-wrapper .perex {
        margin-bottom: 40px; } }

.infobox-items {
  margin-bottom: 20px; }
  @media (min-width: 480px) {
    .infobox-items {
      margin-bottom: 20px; } }
  @media (min-width: 545px) {
    .infobox-items {
      margin-bottom: 20px; } }
  @media (min-width: 768px) {
    .infobox-items {
      margin-bottom: 20px; } }
  @media (min-width: 992px) {
    .infobox-items {
      margin-bottom: 21px; } }
  @media (min-width: 1200px) {
    .infobox-items {
      margin-bottom: 24px; } }
  @media (min-width: 1400px) {
    .infobox-items {
      margin-bottom: 30px; } }
  .infobox-items .row {
    margin-left: -15px;
    margin-right: -15px; }
    .infobox-items .row [class*="col-"] {
      padding-left: 15px;
      padding-right: 15px; }
    @media (min-width: 992px) {
      .infobox-items .row {
        margin-left: -45px;
        margin-right: -45px; }
        .infobox-items .row [class*="col-"] {
          padding-left: 45px;
          padding-right: 45px; } }

.infobox-item {
  margin-bottom: 20px;
  text-align: center; }
  @media (min-width: 480px) {
    .infobox-item {
      margin-bottom: 20px; } }
  @media (min-width: 545px) {
    .infobox-item {
      margin-bottom: 23px; } }
  @media (min-width: 768px) {
    .infobox-item {
      margin-bottom: 27px; } }
  @media (min-width: 992px) {
    .infobox-item {
      margin-bottom: 32px; } }
  @media (min-width: 1200px) {
    .infobox-item {
      margin-bottom: 36px; } }
  @media (min-width: 1400px) {
    .infobox-item {
      margin-bottom: 45px; } }
  .infobox-item.jobs {
    margin-bottom: 40px; }
  .infobox-item-icon {
    margin-bottom: 10px; }
    .infobox-item-icon svg {
      height: 100px; }
  .infobox-item-name {
    text-transform: uppercase;
    font-size: 22px;
    line-height: 30px;
    font-weight: 700;
    margin-bottom: 10px;
    color: #646569; }
    .infobox-item-name a {
      position: relative;
      padding: 0 4px; }
      .infobox-item-name a:after {
        content: '';
        display: block;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 100%;
        border-bottom: 2px solid transparent;
        -moz-transition: all ease-in-out 350ms;
        -o-transition: all ease-in-out 350ms;
        -webkit-transition: all ease-in-out 350ms;
        transition: all ease-in-out 350ms; }
      .infobox-item-name a:hover {
        text-decoration: none; }
        .infobox-item-name a:hover:after {
          right: 0;
          border-color: #ed6e04; }
  .infobox-item-text {
    line-height: 25px; }
  .infobox-item-link {
    padding-top: 10px;
    font-weight: 700; }
    .infobox-item-link a:after {
      position: relative;
      content: "\e908";
      font-size: 10px;
      line-height: 25px;
      margin-left: 0;
      -moz-transition: all ease-in-out 200ms;
      -o-transition: all ease-in-out 200ms;
      -webkit-transition: all ease-in-out 200ms;
      transition: all ease-in-out 200ms; }
    .infobox-item-link a:hover {
      color: #323337;
      text-decoration: none; }
      .infobox-item-link a:hover:after {
        margin-left: 3px;
        color: #ed6e04; }

.parallax-widget-wrapper {
  position: relative;
  min-height: 600px;
  background-position: bottom center !important;
  background-repeat: no-repeat; }
  @media (min-width: 320px) and (max-width: 544px) {
    .parallax-widget-wrapper {
      background-size: auto 260px !important; } }
  @media (min-width: 545px) {
    .parallax-widget-wrapper {
      background-size: 100% auto !important; } }
  .parallax-widget-wrapper .row {
    margin-left: 0px;
    margin-right: 0px; }
    .parallax-widget-wrapper .row [class*="col-"] {
      padding-left: 0px;
      padding-right: 0px; }
  .parallax-widget-wrapper .text-layer {
    background-color: #f2f2f2;
    padding: 20px;
    text-align: center;
    margin: 0 -15px 0 -15px; }
    .parallax-widget-wrapper .text-layer h2 {
      font-weight: 300; }
      .parallax-widget-wrapper .text-layer h2 strong {
        font-weight: 700; }
    .parallax-widget-wrapper .text-layer p,
    .parallax-widget-wrapper .text-layer .perex {
      margin-bottom: 20px; }
    .parallax-widget-wrapper .text-layer-description {
      margin-bottom: 20px; }
      @media (min-width: 480px) {
        .parallax-widget-wrapper .text-layer-description {
          margin-bottom: 20px; } }
      @media (min-width: 545px) {
        .parallax-widget-wrapper .text-layer-description {
          margin-bottom: 20px; } }
      @media (min-width: 768px) {
        .parallax-widget-wrapper .text-layer-description {
          margin-bottom: 24px; } }
      @media (min-width: 992px) {
        .parallax-widget-wrapper .text-layer-description {
          margin-bottom: 28px; } }
      @media (min-width: 1200px) {
        .parallax-widget-wrapper .text-layer-description {
          margin-bottom: 32px; } }
      @media (min-width: 1400px) {
        .parallax-widget-wrapper .text-layer-description {
          margin-bottom: 40px; } }
    @media (min-width: 768px) {
      .parallax-widget-wrapper .text-layer {
        padding: 40px;
        margin: -20px 0px 0px 0px; } }
    @media (min-width: 992px) {
      .parallax-widget-wrapper .text-layer {
        margin: -40px 0 40px 0px; } }
    @media (min-width: 1200px) {
      .parallax-widget-wrapper .text-layer {
        padding: 80px; } }
  .parallax-widget-wrapper .video-btn {
    display: block;
    margin: 100px auto;
    width: 60px;
    height: 60px;
    line-height: 64px;
    border-radius: 50%;
    background-color: rgba(129, 127, 126, 0.8);
    text-align: center;
    overflow: hidden; }
    .parallax-widget-wrapper .video-btn:before {
      display: inline-block;
      content: "\e901";
      color: #fff;
      margin-left: 4px;
      -moz-transition: all ease-in-out 200ms;
      -o-transition: all ease-in-out 200ms;
      -webkit-transition: all ease-in-out 200ms;
      transition: all ease-in-out 200ms; }
    .parallax-widget-wrapper .video-btn:hover {
      background-color: #817f7e; }
      .parallax-widget-wrapper .video-btn:hover:before {
        -ms-transform: scale(1.3, 1.3);
        -webkit-transform: scale(1.3, 1.3);
        transform: scale(1.3, 1.3); }
    @media (min-width: 992px) {
      .parallax-widget-wrapper .video-btn {
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
        -moz-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        -o-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        margin: 0; } }
  .parallax-widget-wrapper.tickets {
    margin-left: -15px;
    margin-right: -15px;
    background-position: center center !important; }
    .parallax-widget-wrapper.tickets .row {
      margin-left: -20px;
      margin-right: -20px; }
      .parallax-widget-wrapper.tickets .row [class*="col-"] {
        padding-left: 20px;
        padding-right: 20px; }
    @media (min-width: 768px) {
      .parallax-widget-wrapper.tickets {
        margin-left: 0px;
        margin-right: 0px; } }
    .parallax-widget-wrapper.tickets .ticket-item {
      background-color: #ed6e04;
      padding: 20px;
      text-align: center;
      color: #fff; }
      .parallax-widget-wrapper.tickets .ticket-item-icon {
        width: 100px;
        height: 100px;
        margin: 0 auto; }
        .parallax-widget-wrapper.tickets .ticket-item-icon .cls-1 {
          fill: #fff; }
      .parallax-widget-wrapper.tickets .ticket-item-price {
        font-size: 45px;
        line-height: 55px;
        font-weight: 700;
        padding-top: 20px;
        font-size: 24px;
        line-height: 30px;
        margin-bottom: 20px; }
        @media (min-width: 480px) {
          .parallax-widget-wrapper.tickets .ticket-item-price {
            font-size: 24px; } }
        @media (min-width: 545px) {
          .parallax-widget-wrapper.tickets .ticket-item-price {
            font-size: 24px; } }
        @media (min-width: 768px) {
          .parallax-widget-wrapper.tickets .ticket-item-price {
            font-size: 27px; } }
        @media (min-width: 992px) {
          .parallax-widget-wrapper.tickets .ticket-item-price {
            font-size: 32px; } }
        @media (min-width: 1200px) {
          .parallax-widget-wrapper.tickets .ticket-item-price {
            font-size: 36px; } }
        @media (min-width: 1400px) {
          .parallax-widget-wrapper.tickets .ticket-item-price {
            font-size: 45px; } }
        @media (min-width: 480px) {
          .parallax-widget-wrapper.tickets .ticket-item-price {
            line-height: 30px; } }
        @media (min-width: 545px) {
          .parallax-widget-wrapper.tickets .ticket-item-price {
            line-height: 30px; } }
        @media (min-width: 768px) {
          .parallax-widget-wrapper.tickets .ticket-item-price {
            line-height: 33px; } }
        @media (min-width: 992px) {
          .parallax-widget-wrapper.tickets .ticket-item-price {
            line-height: 39px; } }
        @media (min-width: 1200px) {
          .parallax-widget-wrapper.tickets .ticket-item-price {
            line-height: 44px; } }
        @media (min-width: 1400px) {
          .parallax-widget-wrapper.tickets .ticket-item-price {
            line-height: 55px; } }
        @media (min-width: 480px) {
          .parallax-widget-wrapper.tickets .ticket-item-price {
            margin-bottom: 20px; } }
        @media (min-width: 545px) {
          .parallax-widget-wrapper.tickets .ticket-item-price {
            margin-bottom: 20px; } }
        @media (min-width: 768px) {
          .parallax-widget-wrapper.tickets .ticket-item-price {
            margin-bottom: 20px; } }
        @media (min-width: 992px) {
          .parallax-widget-wrapper.tickets .ticket-item-price {
            margin-bottom: 21px; } }
        @media (min-width: 1200px) {
          .parallax-widget-wrapper.tickets .ticket-item-price {
            margin-bottom: 24px; } }
        @media (min-width: 1400px) {
          .parallax-widget-wrapper.tickets .ticket-item-price {
            margin-bottom: 30px; } }
        .parallax-widget-wrapper.tickets .ticket-item-price > div {
          font-size: 16px;
          line-height: 24px;
          font-weight: 400;
          padding-top: 20px; }
          @media (min-width: 480px) {
            .parallax-widget-wrapper.tickets .ticket-item-price > div {
              font-size: 16px; } }
          @media (min-width: 545px) {
            .parallax-widget-wrapper.tickets .ticket-item-price > div {
              font-size: 16px; } }
          @media (min-width: 768px) {
            .parallax-widget-wrapper.tickets .ticket-item-price > div {
              font-size: 16px; } }
          @media (min-width: 992px) {
            .parallax-widget-wrapper.tickets .ticket-item-price > div {
              font-size: 16px; } }
          @media (min-width: 1200px) {
            .parallax-widget-wrapper.tickets .ticket-item-price > div {
              font-size: 18px; } }
          @media (min-width: 1400px) {
            .parallax-widget-wrapper.tickets .ticket-item-price > div {
              font-size: 22px; } }
          @media (min-width: 480px) {
            .parallax-widget-wrapper.tickets .ticket-item-price > div {
              line-height: 24px; } }
          @media (min-width: 545px) {
            .parallax-widget-wrapper.tickets .ticket-item-price > div {
              line-height: 24px; } }
          @media (min-width: 768px) {
            .parallax-widget-wrapper.tickets .ticket-item-price > div {
              line-height: 24px; } }
          @media (min-width: 992px) {
            .parallax-widget-wrapper.tickets .ticket-item-price > div {
              line-height: 25px; } }
          @media (min-width: 1200px) {
            .parallax-widget-wrapper.tickets .ticket-item-price > div {
              line-height: 28px; } }
          @media (min-width: 1400px) {
            .parallax-widget-wrapper.tickets .ticket-item-price > div {
              line-height: 35px; } }
      .parallax-widget-wrapper.tickets .ticket-item-text {
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 40px; }
        @media (min-width: 480px) {
          .parallax-widget-wrapper.tickets .ticket-item-text {
            font-size: 16px; } }
        @media (min-width: 545px) {
          .parallax-widget-wrapper.tickets .ticket-item-text {
            font-size: 16px; } }
        @media (min-width: 768px) {
          .parallax-widget-wrapper.tickets .ticket-item-text {
            font-size: 16px; } }
        @media (min-width: 992px) {
          .parallax-widget-wrapper.tickets .ticket-item-text {
            font-size: 16px; } }
        @media (min-width: 1200px) {
          .parallax-widget-wrapper.tickets .ticket-item-text {
            font-size: 18px; } }
        @media (min-width: 1400px) {
          .parallax-widget-wrapper.tickets .ticket-item-text {
            font-size: 22px; } }
        @media (min-width: 480px) {
          .parallax-widget-wrapper.tickets .ticket-item-text {
            line-height: 24px; } }
        @media (min-width: 545px) {
          .parallax-widget-wrapper.tickets .ticket-item-text {
            line-height: 24px; } }
        @media (min-width: 768px) {
          .parallax-widget-wrapper.tickets .ticket-item-text {
            line-height: 24px; } }
        @media (min-width: 992px) {
          .parallax-widget-wrapper.tickets .ticket-item-text {
            line-height: 25px; } }
        @media (min-width: 1200px) {
          .parallax-widget-wrapper.tickets .ticket-item-text {
            line-height: 28px; } }
        @media (min-width: 1400px) {
          .parallax-widget-wrapper.tickets .ticket-item-text {
            line-height: 35px; } }
      .parallax-widget-wrapper.tickets .ticket-item-btn {
        min-height: 74px; }
      @media (min-width: 545px) {
        .parallax-widget-wrapper.tickets .ticket-item {
          padding: 30px; } }
      @media (min-width: 768px) {
        .parallax-widget-wrapper.tickets .ticket-item {
          padding: 40px;
          margin-top: -20px;
          margin-bottom: 20px; } }
      @media (min-width: 1200px) {
        .parallax-widget-wrapper.tickets .ticket-item {
          background-color: rgba(237, 110, 4, 0.8);
          padding: 60px;
          margin-top: -40px;
          margin-bottom: 40px; } }
    .parallax-widget-wrapper.tickets .row > [class*="col-"]:first-of-type .ticket-item {
      margin-bottom: 240px; }
      @media (min-width: 768px) {
        .parallax-widget-wrapper.tickets .row > [class*="col-"]:first-of-type .ticket-item {
          margin-bottom: 20px; } }
      @media (min-width: 1200px) {
        .parallax-widget-wrapper.tickets .row > [class*="col-"]:first-of-type .ticket-item {
          margin-bottom: 40px; } }
    @media (min-width: 768px) {
      .parallax-widget-wrapper.tickets .row > [class*="col-"]:last-of-type .ticket-item {
        margin-top: 0px; } }
    @media (min-width: 992px) {
      .parallax-widget-wrapper.tickets .row > [class*="col-"]:last-of-type .ticket-item {
        margin-top: -20px; } }
    @media (min-width: 1200px) {
      .parallax-widget-wrapper.tickets .row > [class*="col-"]:last-of-type .ticket-item {
        margin-top: -40px; } }
    @media (min-width: 768px) {
      .parallax-widget-wrapper.tickets {
        background-size: cover !important; } }

.parallax-slider-widget-wrapper {
  position: relative; }
  @media (min-width: 992px) {
    .parallax-slider-widget-wrapper {
      min-height: 600px; } }

.parallax-slider-background {
  position: absolute;
  height: 280px;
  left: 0px;
  right: 0px;
  bottom: 0px; }
  @media (min-width: 768px) {
    .parallax-slider-background {
      height: auto;
      top: 0px; } }
  .parallax-slider-background .slick-list,
  .parallax-slider-background .slick-track,
  .parallax-slider-background .slick-slide {
    height: 100%; }
  .parallax-slider-background .slick-slide {
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat; }

.parallax-slider {
  margin-bottom: 15px; }
  @media (min-width: 480px) {
    .parallax-slider {
      margin-bottom: 15px; } }
  @media (min-width: 545px) {
    .parallax-slider {
      margin-bottom: 15px; } }
  @media (min-width: 768px) {
    .parallax-slider {
      margin-bottom: 18px; } }
  @media (min-width: 992px) {
    .parallax-slider {
      margin-bottom: 21px; } }
  @media (min-width: 1200px) {
    .parallax-slider {
      margin-bottom: 24px; } }
  @media (min-width: 1400px) {
    .parallax-slider {
      margin-bottom: 30px; } }
  .parallax-slider-wrapper {
    background-color: #f2f2f2;
    padding: 20px;
    margin: 0 -15px 240px -15px;
    position: relative;
    padding-bottom: 60px; }
    @media (min-width: 768px) {
      .parallax-slider-wrapper {
        padding: 40px;
        margin: -20px 0px 20px 0px; } }
    @media (min-width: 992px) {
      .parallax-slider-wrapper {
        margin: -40px 0 40px 0px; } }
    @media (min-width: 1200px) {
      .parallax-slider-wrapper {
        padding: 80px; } }
  .parallax-slider h3 {
    font-size: 24px;
    margin-bottom: 20px;
    font-weight: 400; }
    @media (min-width: 480px) {
      .parallax-slider h3 {
        font-size: 24px; } }
    @media (min-width: 545px) {
      .parallax-slider h3 {
        font-size: 28px; } }
    @media (min-width: 768px) {
      .parallax-slider h3 {
        font-size: 33px; } }
    @media (min-width: 992px) {
      .parallax-slider h3 {
        font-size: 39px; } }
    @media (min-width: 1200px) {
      .parallax-slider h3 {
        font-size: 44px; } }
    @media (min-width: 1400px) {
      .parallax-slider h3 {
        font-size: 55px; } }
    @media (min-width: 480px) {
      .parallax-slider h3 {
        margin-bottom: 20px; } }
    @media (min-width: 545px) {
      .parallax-slider h3 {
        margin-bottom: 20px; } }
    @media (min-width: 768px) {
      .parallax-slider h3 {
        margin-bottom: 20px; } }
    @media (min-width: 992px) {
      .parallax-slider h3 {
        margin-bottom: 21px; } }
    @media (min-width: 1200px) {
      .parallax-slider h3 {
        margin-bottom: 24px; } }
    @media (min-width: 1400px) {
      .parallax-slider h3 {
        margin-bottom: 30px; } }
  .parallax-slider-controls {
    font-size: 0px;
    text-align: center; }
  .parallax-slider-arrow {
    display: inline-block;
    background-color: rgba(129, 127, 126, 0.8);
    color: #fff;
    width: 40px;
    height: 40px;
    text-align: center;
    line-height: 42px;
    border-radius: 50%;
    font-size: 14px;
    overflow: hidden;
    margin: 0 10px; }
    .parallax-slider-arrow:hover {
      background-color: #817f7e;
      color: #fff;
      text-decoration: none; }
    @media (min-width: 768px) {
      .parallax-slider-arrow {
        width: 60px;
        height: 60px;
        line-height: 64px;
        font-size: 22px; } }
    .parallax-slider-arrow.prev:before {
      content: "\e91a"; }
    .parallax-slider-arrow.next:before {
      content: "\e91b"; }
  .parallax-slider-item {
    text-align: center; }
    .parallax-slider-item:focus {
      outline: none; }

.testimonial-wrapper {
  padding: 30px 0px 30px 0px; }
  @media (min-width: 480px) {
    .testimonial-wrapper {
      padding: 30px 0px 30px 0px; } }
  @media (min-width: 545px) {
    .testimonial-wrapper {
      padding: 35px 0px 30px 0px; } }
  @media (min-width: 768px) {
    .testimonial-wrapper {
      padding: 42px 0px 33px 0px; } }
  @media (min-width: 992px) {
    .testimonial-wrapper {
      padding: 49px 0px 39px 0px; } }
  @media (min-width: 1200px) {
    .testimonial-wrapper {
      padding: 56px 0px 44px 0px; } }
  @media (min-width: 1400px) {
    .testimonial-wrapper {
      padding: 70px 0px 55px 0px; } }
  .testimonial-wrapper h2 {
    text-align: center;
    margin-bottom: 24px; }
    @media (min-width: 480px) {
      .testimonial-wrapper h2 {
        margin-bottom: 24px; } }
    @media (min-width: 545px) {
      .testimonial-wrapper h2 {
        margin-bottom: 30px; } }
    @media (min-width: 768px) {
      .testimonial-wrapper h2 {
        margin-bottom: 36px; } }
    @media (min-width: 992px) {
      .testimonial-wrapper h2 {
        margin-bottom: 42px; } }
    @media (min-width: 1200px) {
      .testimonial-wrapper h2 {
        margin-bottom: 48px; } }
    @media (min-width: 1400px) {
      .testimonial-wrapper h2 {
        margin-bottom: 60px; } }

.testimonial-items .row {
  margin-left: -15px;
  margin-right: -15px; }
  .testimonial-items .row [class*="col-"] {
    padding-left: 15px;
    padding-right: 15px; }

.testimonial-items:not(.references) .row {
  margin-left: -15px;
  margin-right: -15px;
  /*[class*="col-"] {
                    @include media(xl) {
                        &:nth-of-type(4) {
                            display: none;
                        }
                    }
                }*/ }

.testimonial-items.references {
  position: relative;
  padding-bottom: 45px; }
  .testimonial-items.references .btn-more {
    position: absolute;
    bottom: 0px;
    left: 50%;
    -webkit-transform: translate(-50%, 50%) rotateZ(45deg);
    -moz-transform: translate(-50%, 50%) rotateZ(45deg);
    -ms-transform: translate(-50%, 50%) rotateZ(45deg);
    -o-transform: translate(-50%, 50%) rotateZ(45deg);
    transform: translate(-50%, 50%) rotateZ(45deg);
    z-index: 2;
    -moz-transition: all ease-in-out 350ms;
    -o-transition: all ease-in-out 350ms;
    -webkit-transition: all ease-in-out 350ms;
    transition: all ease-in-out 350ms; }
    .testimonial-items.references .btn-more.collapsed {
      -webkit-transform: translate(-50%, 50%) rotateZ(0deg);
      -moz-transform: translate(-50%, 50%) rotateZ(0deg);
      -ms-transform: translate(-50%, 50%) rotateZ(0deg);
      -o-transform: translate(-50%, 50%) rotateZ(0deg);
      transform: translate(-50%, 50%) rotateZ(0deg); }

.testimonial-item {
  font-size: 0px;
  position: relative;
  background-color: #f2f2f2;
  padding: 17px 20px;
  margin-bottom: 45px; }
  @media (min-width: 992px) {
    .testimonial-item {
      padding: 37px 40px; } }
  .testimonial-item q {
    display: block;
    margin-bottom: 20px;
    font-size: 16px;
    line-height: 25px;
    min-height: 75px; }
    .testimonial-item q:before {
      margin-right: 3px;
      font-size: 16px; }
    .testimonial-item q:after {
      font-size: 16px; }
  .testimonial-item-info {
    font-size: 16px;
    display: inline-block;
    vertical-align: top;
    width: 100%;
    margin-bottom: -10px; }
    @media (min-width: 992px) {
      .testimonial-item-info {
        padding-right: 160px;
        margin-right: -140px;
        margin-bottom: 0px; } }
    .testimonial-item-info strong {
      display: block; }
  .testimonial-item-rate {
    padding-top: 20px;
    line-height: 20px; }
    .testimonial-item-rate span {
      display: inline-block;
      margin-right: 4px;
      font-size: 20px;
      line-height: 20px; }
      .testimonial-item-rate span:before {
        content: "\e90b";
        color: #939396; }
      .testimonial-item-rate span:nth-of-type(1):before {
        -moz-transition: color 50ms linear 0ms;
        -o-transition: color 50ms linear 0ms;
        -webkit-transition: color 50ms linear 0ms;
        transition: color 50ms linear 0ms; }
      .testimonial-item-rate span:nth-of-type(2):before {
        -moz-transition: color 50ms linear 300ms;
        -o-transition: color 50ms linear 300ms;
        -webkit-transition: color 50ms linear 300ms;
        transition: color 50ms linear 300ms; }
      .testimonial-item-rate span:nth-of-type(3):before {
        -moz-transition: color 50ms linear 600ms;
        -o-transition: color 50ms linear 600ms;
        -webkit-transition: color 50ms linear 600ms;
        transition: color 50ms linear 600ms; }
      .testimonial-item-rate span:nth-of-type(4):before {
        -moz-transition: color 50ms linear 900ms;
        -o-transition: color 50ms linear 900ms;
        -webkit-transition: color 50ms linear 900ms;
        transition: color 50ms linear 900ms; }
      .testimonial-item-rate span:nth-of-type(5):before {
        -moz-transition: color 50ms linear 1200ms;
        -o-transition: color 50ms linear 1200ms;
        -webkit-transition: color 50ms linear 1200ms;
        transition: color 50ms linear 1200ms; }
    .testimonial-item-rate.activate.count-1 span:nth-of-type(1):before {
      color: #ed6e04; }
    .testimonial-item-rate.activate.count-2 span:nth-of-type(1):before, .testimonial-item-rate.activate.count-2 span:nth-of-type(2):before {
      color: #ed6e04; }
    .testimonial-item-rate.activate.count-3 span:nth-of-type(1):before, .testimonial-item-rate.activate.count-3 span:nth-of-type(2):before, .testimonial-item-rate.activate.count-3 span:nth-of-type(3):before {
      color: #ed6e04; }
    .testimonial-item-rate.activate.count-4 span:nth-of-type(1):before, .testimonial-item-rate.activate.count-4 span:nth-of-type(2):before, .testimonial-item-rate.activate.count-4 span:nth-of-type(3):before, .testimonial-item-rate.activate.count-4 span:nth-of-type(4):before {
      color: #ed6e04; }
    .testimonial-item-rate.activate.count-5 span:before {
      color: #ed6e04; }
  .testimonial-item-photo {
    display: inline-block;
    vertical-align: top;
    width: 100%;
    text-align: right;
    margin-bottom: -37px; }
    @media (min-width: 992px) {
      .testimonial-item-photo {
        padding-top: 10px;
        width: 140px;
        /*margin-bottom: -57px;*/ } }
    .testimonial-item-photo img {
      width: 100px;
      height: 100px !important;
      border-radius: 50%; }
      @media (min-width: 992px) {
        .testimonial-item-photo img {
          position: absolute;
          bottom: -20px;
          right: 40px;
          width: 140px;
          height: 140px !important; } }
  .testimonial-item:after {
    content: '';
    display: block;
    position: absolute;
    bottom: -20px;
    left: 40px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 20px 20px 0 20px;
    border-color: #f2f2f2 transparent transparent transparent; }
  .testimonial-item.no-rate .testimonial-item-info {
    padding-bottom: 43px; }

.count-up-wrapper {
  padding-top: 24px;
  padding-bottom: 20px; }
  @media (min-width: 480px) {
    .count-up-wrapper {
      padding-top: 24px; } }
  @media (min-width: 545px) {
    .count-up-wrapper {
      padding-top: 30px; } }
  @media (min-width: 768px) {
    .count-up-wrapper {
      padding-top: 36px; } }
  @media (min-width: 992px) {
    .count-up-wrapper {
      padding-top: 42px; } }
  @media (min-width: 1200px) {
    .count-up-wrapper {
      padding-top: 48px; } }
  @media (min-width: 1400px) {
    .count-up-wrapper {
      padding-top: 60px; } }
  .count-up-wrapper .row {
    margin-left: -15px;
    margin-right: -15px; }
    .count-up-wrapper .row [class*="col-"] {
      padding-left: 15px;
      padding-right: 15px; }

.count-up-item {
  margin-bottom: 20px;
  text-align: center; }
  @media (min-width: 480px) {
    .count-up-item {
      margin-bottom: 20px; } }
  @media (min-width: 545px) {
    .count-up-item {
      margin-bottom: 20px; } }
  @media (min-width: 768px) {
    .count-up-item {
      margin-bottom: 20px; } }
  @media (min-width: 992px) {
    .count-up-item {
      margin-bottom: 21px; } }
  @media (min-width: 1200px) {
    .count-up-item {
      margin-bottom: 24px; } }
  @media (min-width: 1400px) {
    .count-up-item {
      margin-bottom: 30px; } }
  .count-up-item-ico:before {
    font-size: 70px; }
  .count-up-item-ico.ico-1:before {
    content: "\e915"; }
  .count-up-item-ico.ico-2:before {
    content: "\e916"; }
  .count-up-item-ico.ico-3:before {
    content: "\e918"; }
  .count-up-item-ico.ico-4:before {
    content: "\e917"; }
  .count-up-item-number {
    font-size: 60px;
    line-height: 60px;
    font-weight: 700; }
    .count-up-item-number:after {
      font-family: "Fedra", sans-serif !important;
      content: '+';
      font-size: 32px;
      display: inline-block;
      vertical-align: 22px;
      margin-top: -8px;
      font-weight: 700; }
  .count-up-item-text {
    font-size: 22px;
    line-height: 30px;
    padding-top: 15px; }

.seminar-program-items {
  position: relative;
  margin-bottom: 30px; }
  @media (min-width: 480px) {
    .seminar-program-items {
      margin-bottom: 30px; } }
  @media (min-width: 545px) {
    .seminar-program-items {
      margin-bottom: 35px; } }
  @media (min-width: 768px) {
    .seminar-program-items {
      margin-bottom: 42px; } }
  @media (min-width: 992px) {
    .seminar-program-items {
      margin-bottom: 49px; } }
  @media (min-width: 1200px) {
    .seminar-program-items {
      margin-bottom: 56px; } }
  @media (min-width: 1400px) {
    .seminar-program-items {
      margin-bottom: 70px; } }
  .seminar-program-items .btn-more {
    position: absolute;
    bottom: 0px;
    left: 50%;
    -webkit-transform: translate(-50%, 50%) rotateZ(45deg);
    -moz-transform: translate(-50%, 50%) rotateZ(45deg);
    -ms-transform: translate(-50%, 50%) rotateZ(45deg);
    -o-transform: translate(-50%, 50%) rotateZ(45deg);
    transform: translate(-50%, 50%) rotateZ(45deg);
    z-index: 2;
    -moz-transition: all ease-in-out 350ms;
    -o-transition: all ease-in-out 350ms;
    -webkit-transition: all ease-in-out 350ms;
    transition: all ease-in-out 350ms; }
    .seminar-program-items .btn-more.collapsed {
      -webkit-transform: translate(-50%, 50%) rotateZ(0deg);
      -moz-transform: translate(-50%, 50%) rotateZ(0deg);
      -ms-transform: translate(-50%, 50%) rotateZ(0deg);
      -o-transform: translate(-50%, 50%) rotateZ(0deg);
      transform: translate(-50%, 50%) rotateZ(0deg); }

.seminar-program-collapse {
  z-index: 1; }

.seminar-program-item {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -moz-flex-flow: row wrap;
  -ms-flex-direction: row;
  -ms-flex-wrap: wrap;
  -webkit-flex-flow: row wrap;
  flex-flow: row wrap;
  border-bottom: 1px solid #fff; }
  .seminar-program-item-left {
    -webkit-flex: 100%;
    -moz-flex: 100%;
    -ms-flex: 100%;
    -webkit-flex: 100%;
    flex: 100%;
    padding: 16px;
    display: inline-block;
    max-width: 100px;
    position: relative;
    background-color: #f2f2f2;
    border-right: 1px solid #fff; }
    @media (min-width: 480px) {
      .seminar-program-item-left {
        padding: 16px; } }
    @media (min-width: 545px) {
      .seminar-program-item-left {
        padding: 20px; } }
    @media (min-width: 768px) {
      .seminar-program-item-left {
        padding: 24px; } }
    @media (min-width: 992px) {
      .seminar-program-item-left {
        padding: 28px; } }
    @media (min-width: 1200px) {
      .seminar-program-item-left {
        padding: 32px; } }
    @media (min-width: 1400px) {
      .seminar-program-item-left {
        padding: 40px; } }
    @media (min-width: 992px) {
      .seminar-program-item-left {
        max-width: 200px; } }
  .seminar-program-item-right {
    -webkit-flex: 100%;
    -moz-flex: 100%;
    -ms-flex: 100%;
    -webkit-flex: 100%;
    flex: 100%;
    padding: 16px;
    display: inline-block;
    background-color: #f2f2f2;
    max-width: calc(100% - 100px); }
    @media (min-width: 480px) {
      .seminar-program-item-right {
        padding: 16px; } }
    @media (min-width: 545px) {
      .seminar-program-item-right {
        padding: 20px; } }
    @media (min-width: 768px) {
      .seminar-program-item-right {
        padding: 24px; } }
    @media (min-width: 992px) {
      .seminar-program-item-right {
        padding: 28px; } }
    @media (min-width: 1200px) {
      .seminar-program-item-right {
        padding: 32px; } }
    @media (min-width: 1400px) {
      .seminar-program-item-right {
        padding: 40px; } }
    @media (min-width: 992px) {
      .seminar-program-item-right {
        max-width: calc(100% - 200px); } }
  .seminar-program-item-date {
    font-size: 22px;
    line-height: 1;
    font-weight: 700;
    color: #646569; }
    @media (min-width: 480px) {
      .seminar-program-item-date {
        font-size: 22px; } }
    @media (min-width: 545px) {
      .seminar-program-item-date {
        font-size: 23px; } }
    @media (min-width: 768px) {
      .seminar-program-item-date {
        font-size: 27px; } }
    @media (min-width: 992px) {
      .seminar-program-item-date {
        font-size: 32px; } }
    @media (min-width: 1200px) {
      .seminar-program-item-date {
        font-size: 36px; } }
    @media (min-width: 1400px) {
      .seminar-program-item-date {
        font-size: 45px; } }
  .seminar-program-item-name {
    font-size: 18px;
    line-height: 22px;
    margin-bottom: 10px;
    color: #646569;
    font-weight: 700;
    text-transform: uppercase; }
    @media (min-width: 480px) {
      .seminar-program-item-name {
        font-size: 18px; } }
    @media (min-width: 545px) {
      .seminar-program-item-name {
        font-size: 18px; } }
    @media (min-width: 768px) {
      .seminar-program-item-name {
        font-size: 18px; } }
    @media (min-width: 992px) {
      .seminar-program-item-name {
        font-size: 18px; } }
    @media (min-width: 1200px) {
      .seminar-program-item-name {
        font-size: 18px; } }
    @media (min-width: 1400px) {
      .seminar-program-item-name {
        font-size: 22px; } }
    @media (min-width: 480px) {
      .seminar-program-item-name {
        line-height: 22px; } }
    @media (min-width: 545px) {
      .seminar-program-item-name {
        line-height: 22px; } }
    @media (min-width: 768px) {
      .seminar-program-item-name {
        line-height: 22px; } }
    @media (min-width: 992px) {
      .seminar-program-item-name {
        line-height: 22px; } }
    @media (min-width: 1200px) {
      .seminar-program-item-name {
        line-height: 24px; } }
    @media (min-width: 1400px) {
      .seminar-program-item-name {
        line-height: 30px; } }
    @media (min-width: 480px) {
      .seminar-program-item-name {
        margin-bottom: 10px; } }
    @media (min-width: 545px) {
      .seminar-program-item-name {
        margin-bottom: 10px; } }
    @media (min-width: 768px) {
      .seminar-program-item-name {
        margin-bottom: 10px; } }
    @media (min-width: 992px) {
      .seminar-program-item-name {
        margin-bottom: 11px; } }
    @media (min-width: 1200px) {
      .seminar-program-item-name {
        margin-bottom: 12px; } }
    @media (min-width: 1400px) {
      .seminar-program-item-name {
        margin-bottom: 15px; } }
  .seminar-program-item-description {
    font-size: 14px;
    line-height: 18px; }
    @media (min-width: 480px) {
      .seminar-program-item-description {
        font-size: 14px; } }
    @media (min-width: 545px) {
      .seminar-program-item-description {
        font-size: 14px; } }
    @media (min-width: 768px) {
      .seminar-program-item-description {
        font-size: 14px; } }
    @media (min-width: 992px) {
      .seminar-program-item-description {
        font-size: 14px; } }
    @media (min-width: 1200px) {
      .seminar-program-item-description {
        font-size: 14px; } }
    @media (min-width: 1400px) {
      .seminar-program-item-description {
        font-size: 16px; } }
    @media (min-width: 480px) {
      .seminar-program-item-description {
        line-height: 18px; } }
    @media (min-width: 545px) {
      .seminar-program-item-description {
        line-height: 18px; } }
    @media (min-width: 768px) {
      .seminar-program-item-description {
        line-height: 18px; } }
    @media (min-width: 992px) {
      .seminar-program-item-description {
        line-height: 18px; } }
    @media (min-width: 1200px) {
      .seminar-program-item-description {
        line-height: 20px; } }
    @media (min-width: 1400px) {
      .seminar-program-item-description {
        line-height: 25px; } }

.lecturer-wrapper {
  padding-bottom: 48px; }
  @media (min-width: 480px) {
    .lecturer-wrapper {
      padding-bottom: 48px; } }
  @media (min-width: 545px) {
    .lecturer-wrapper {
      padding-bottom: 60px; } }
  @media (min-width: 768px) {
    .lecturer-wrapper {
      padding-bottom: 72px; } }
  @media (min-width: 992px) {
    .lecturer-wrapper {
      padding-bottom: 84px; } }
  @media (min-width: 1200px) {
    .lecturer-wrapper {
      padding-bottom: 96px; } }
  @media (min-width: 1400px) {
    .lecturer-wrapper {
      padding-bottom: 120px; } }

.lecturer-items {
  position: relative; }
  .lecturer-items .row {
    margin-left: -0.5px;
    margin-right: -0.5px; }
    .lecturer-items .row [class*="col-"] {
      padding-left: 0.5px;
      padding-right: 0.5px; }
  .lecturer-items .btn-more {
    position: absolute;
    bottom: 0px;
    left: 50%;
    -webkit-transform: translate(-50%, 50%) rotateZ(45deg);
    -moz-transform: translate(-50%, 50%) rotateZ(45deg);
    -ms-transform: translate(-50%, 50%) rotateZ(45deg);
    -o-transform: translate(-50%, 50%) rotateZ(45deg);
    transform: translate(-50%, 50%) rotateZ(45deg);
    z-index: 2;
    -moz-transition: all ease-in-out 350ms;
    -o-transition: all ease-in-out 350ms;
    -webkit-transition: all ease-in-out 350ms;
    transition: all ease-in-out 350ms; }
    .lecturer-items .btn-more.collapsed {
      -webkit-transform: translate(-50%, 50%) rotateZ(0deg);
      -moz-transform: translate(-50%, 50%) rotateZ(0deg);
      -ms-transform: translate(-50%, 50%) rotateZ(0deg);
      -o-transform: translate(-50%, 50%) rotateZ(0deg);
      transform: translate(-50%, 50%) rotateZ(0deg); }

.lecturer-collapse {
  z-index: 1; }

.lecturer-item {
  height: -webkit-calc(100vw - 30px);
  height: -moz-calc(100vw - 30px);
  height: calc(100vw - 30px);
  border-bottom: 1px solid #fff;
  position: relative;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  /*@include media(lg) {
            @include calc-variable(height, 33.333333vw, ($container-padding-md/2)+1px, "-");
        }*/ }
  .lecturer-item-inner {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 40%;
    width: 100%;
    -moz-transition: all ease-in-out 350ms;
    -o-transition: all ease-in-out 350ms;
    -webkit-transition: all ease-in-out 350ms;
    transition: all ease-in-out 350ms;
    display: table;
    vertical-align: middle;
    background-color: rgba(237, 110, 4, 0.8); }
    @media (min-width: 545px) {
      .lecturer-item-inner {
        background-color: rgba(237, 110, 4, 0);
        height: 100%; } }
    .lecturer-item-inner:hover {
      background-color: rgba(237, 110, 4, 0.8); }
  .lecturer-item-text {
    display: table-cell;
    vertical-align: middle;
    text-align: center;
    color: #fff;
    height: 100%;
    width: 100%;
    padding: 0 15px; }
  .lecturer-item-name {
    display: inline-block;
    text-transform: uppercase;
    font-size: 18px;
    line-height: 30px;
    font-weight: 700; }
    @media (min-width: 545px) {
      .lecturer-item-name {
        font-size: 22px;
        opacity: 0;
        -webkit-transform: translate(0, 30px);
        -moz-transform: translate(0, 30px);
        -ms-transform: translate(0, 30px);
        -o-transform: translate(0, 30px);
        transform: translate(0, 30px);
        -moz-transition: all 300ms ease-out;
        -o-transition: all 300ms ease-out;
        -webkit-transition: all 300ms ease-out;
        transition: all 300ms ease-out; } }
  .lecturer-item-description {
    display: inline-block;
    width: 100%;
    font-size: 14px; }
    @media (min-width: 545px) {
      .lecturer-item-description {
        font-size: 16px;
        opacity: 0;
        -webkit-transform: translate(0, 24px);
        -moz-transform: translate(0, 24px);
        -ms-transform: translate(0, 24px);
        -o-transform: translate(0, 24px);
        transform: translate(0, 24px);
        -moz-transition: all 600ms ease-out;
        -o-transition: all 600ms ease-out;
        -webkit-transition: all 600ms ease-out;
        transition: all 600ms ease-out; } }
  @media (min-width: 545px) {
    .lecturer-item:hover .lecturer-item-name {
      opacity: 1;
      -webkit-transform: translate(0, 0px);
      -moz-transform: translate(0, 0px);
      -ms-transform: translate(0, 0px);
      -o-transform: translate(0, 0px);
      transform: translate(0, 0px); }
    .lecturer-item:hover .lecturer-item-description {
      opacity: 1;
      -webkit-transform: translate(0, 0px);
      -moz-transform: translate(0, 0px);
      -ms-transform: translate(0, 0px);
      -o-transform: translate(0, 0px);
      transform: translate(0, 0px); } }
  @media (min-width: 545px) {
    .lecturer-item {
      height: -webkit-calc(50vw - 15.5px);
      height: -moz-calc(50vw - 15.5px);
      height: calc(50vw - 15.5px); } }
  @media (min-width: 768px) {
    .lecturer-item {
      height: -webkit-calc(50vw - 45.5px);
      height: -moz-calc(50vw - 45.5px);
      height: calc(50vw - 45.5px); } }
  @media (min-width: 1200px) {
    .lecturer-item {
      height: -webkit-calc(25vw - 83px);
      height: -moz-calc(25vw - 83px);
      height: calc(25vw - 83px); } }
  @media (min-width: 1600px) {
    .lecturer-item {
      height: 359.25px; } }

.tickets-wrapper {
  border-top: 1px solid #f2f2f2; }
  @media (min-width: 320px) and (max-width: 991px) {
    .tickets-wrapper {
      overflow-x: hidden; } }
  .tickets-wrapper .text-block h2 {
    font-weight: 700; }

.test-social ul {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  margin: 0;
  padding: 0;
  list-style: none; }
  .test-social ul li {
    padding: 0 15px 15px 0; }
    .test-social ul li:before {
      display: none; }
    .test-social ul li a {
      font-size: 0;
      display: block;
      text-decoration: none;
      width: 30px;
      height: 30px;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      -ms-border-radius: 50%;
      border-radius: 50%;
      background: grey;
      border: none;
      color: #fff;
      text-align: center;
      -moz-transition: all ease-in-out 200ms;
      -o-transition: all ease-in-out 200ms;
      -webkit-transition: all ease-in-out 200ms;
      transition: all ease-in-out 200ms; }
      .test-social ul li a:focus, .test-social ul li a:hover {
        background: blue;
        color: black;
        border: none;
        text-decoration: none; }

.test-social .facebook:before {
  content: "";
  line-height: 30px;
  font-size: 20px; }

.test-social .twitter:before {
  content: "";
  line-height: 30px;
  font-size: 20px; }

.test-social .linkedin:before {
  content: "";
  line-height: 30px;
  font-size: 20px; }

.test-social .youtube:before {
  content: "";
  line-height: 30px;
  font-size: 20px; }

.test-social .instagram:before {
  content: "";
  line-height: 30px;
  font-size: 20px; }

.test-social .pinterest:before {
  content: "";
  line-height: 30px;
  font-size: 20px; }

.test-social .google:before {
  content: "";
  line-height: 30px;
  font-size: 20px; }

.test-social .vimeo:before {
  content: "";
  line-height: 30px;
  font-size: 20px; }

.product-social ul {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  margin: 0;
  padding: 0;
  list-style: none; }
  .product-social ul li {
    padding: 0 15px 15px 0; }
    .product-social ul li:before {
      display: none; }
    .product-social ul li a {
      font-size: 0;
      display: block;
      text-decoration: none;
      width: 40px;
      height: 40px;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      -ms-border-radius: 50%;
      border-radius: 50%;
      background: none;
      border: 1px solid grey;
      color: grey;
      text-align: center;
      -moz-transition: all ease-in-out 200ms;
      -o-transition: all ease-in-out 200ms;
      -webkit-transition: all ease-in-out 200ms;
      transition: all ease-in-out 200ms; }
      .product-social ul li a:focus, .product-social ul li a:hover {
        background: none;
        color: black;
        border: 1px solid black;
        text-decoration: none; }

.product-social .facebook:before {
  content: "";
  line-height: 40px;
  font-size: 20px; }

.product-social .twitter:before {
  content: "";
  line-height: 40px;
  font-size: 20px; }

.product-social .linkedin:before {
  content: "";
  line-height: 40px;
  font-size: 20px; }

.product-social .youtube:before {
  content: "";
  line-height: 40px;
  font-size: 20px; }

.product-social .instagram:before {
  content: "";
  line-height: 40px;
  font-size: 20px; }

.product-social .pinterest:before {
  content: "";
  line-height: 40px;
  font-size: 20px; }

.product-social .google:before {
  content: "";
  line-height: 40px;
  font-size: 20px; }

.product-social .vimeo:before {
  content: "";
  line-height: 40px;
  font-size: 20px; }

.blog-filter-main ul {
  margin-left: -15px;
  margin-right: -15px; }
  .blog-filter-main ul:before, .blog-filter-main ul:after {
    content: '';
    display: table; }
  .blog-filter-main ul:after {
    clear: both; }
  .blog-filter-main ul li {
    display: block;
    float: left;
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
    margin-bottom: 15px; }
    .blog-filter-main ul li:before {
      display: none; }
    .blog-filter-main ul li a {
      display: block;
      text-align: center;
      background-color: #ed6e04;
      color: #fff;
      text-transform: uppercase;
      font-size: 16px;
      line-height: 50px;
      padding: 0 20px;
      border-bottom: 2px solid #ed6e04; }
      .blog-filter-main ul li a:hover {
        background-color: #d46204;
        text-decoration: none; }
    .blog-filter-main ul li.Highlighted a {
      background-color: #fff;
      color: #ed6e04; }
    @media (min-width: 768px) {
      .blog-filter-main ul li {
        width: 33.333333%;
        margin-bottom: 30px; } }
    @media (min-width: 992px) {
      .blog-filter-main ul li a {
        font-size: 17px;
        line-height: 70px; } }

.blog-filter-secondary {
  text-align: center;
  margin-bottom: 30px; }
  @media (min-width: 480px) {
    .blog-filter-secondary {
      margin-bottom: 30px; } }
  @media (min-width: 545px) {
    .blog-filter-secondary {
      margin-bottom: 30px; } }
  @media (min-width: 768px) {
    .blog-filter-secondary {
      margin-bottom: 36px; } }
  @media (min-width: 992px) {
    .blog-filter-secondary {
      margin-bottom: 42px; } }
  @media (min-width: 1200px) {
    .blog-filter-secondary {
      margin-bottom: 48px; } }
  @media (min-width: 1400px) {
    .blog-filter-secondary {
      margin-bottom: 60px; } }
  .blog-filter-secondary a {
    display: inline-block;
    background-color: #fff;
    font-size: 14px;
    text-transform: uppercase;
    line-height: 40px;
    padding: 0 15px;
    margin: 0 0 15px 0;
    width: 100%; }
    .blog-filter-secondary a:hover {
      background-color: #e6e6e6;
      text-decoration: none;
      color: #ed6e04; }
    @media (min-width: 545px) {
      .blog-filter-secondary a {
        width: auto;
        font-size: 15px;
        padding: 0 30px;
        margin: 0 5px 10px 5px; } }
    .blog-filter-secondary a.active {
      background-color: #ed6e04;
      color: #fff; }

.blog-items {
  margin-left: -15px;
  margin-right: -15px; }
  .blog-items .items-container {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -moz-flex-flow: row wrap;
    -ms-flex-direction: row;
    -ms-flex-wrap: wrap;
    -webkit-flex-flow: row wrap;
    flex-flow: row wrap; }
  .blog-items .show-more-container {
    text-align: center;
    padding: 20px 0px 24px 0px; }
    @media (min-width: 480px) {
      .blog-items .show-more-container {
        padding: 20px 0px 24px 0px; } }
    @media (min-width: 545px) {
      .blog-items .show-more-container {
        padding: 20px 0px 30px 0px; } }
    @media (min-width: 768px) {
      .blog-items .show-more-container {
        padding: 20px 0px 36px 0px; } }
    @media (min-width: 992px) {
      .blog-items .show-more-container {
        padding: 21px 0px 42px 0px; } }
    @media (min-width: 1200px) {
      .blog-items .show-more-container {
        padding: 24px 0px 48px 0px; } }
    @media (min-width: 1400px) {
      .blog-items .show-more-container {
        padding: 30px 0px 60px 0px; } }
    .blog-items .show-more-container .arrow-down-link {
      display: inline-block;
      background-color: rgba(129, 127, 126, 0.8);
      color: #fff;
      width: 30px;
      height: 30px;
      text-align: center;
      border-radius: 50%;
      font-size: 0px;
      overflow: hidden; }
      .blog-items .show-more-container .arrow-down-link:before {
        content: "\e902";
        font-size: 14px;
        line-height: 30px; }
      .blog-items .show-more-container .arrow-down-link:hover {
        background-color: #817f7e;
        color: #fff;
        text-decoration: none; }
      @media (min-width: 768px) {
        .blog-items .show-more-container .arrow-down-link {
          width: 40px;
          height: 40px; }
          .blog-items .show-more-container .arrow-down-link:before {
            line-height: 40px; } }
      @media (min-width: 992px) {
        .blog-items .show-more-container .arrow-down-link {
          width: 60px;
          height: 60px; }
          .blog-items .show-more-container .arrow-down-link:before {
            font-size: 22px;
            line-height: 60px; } }

.blog-item {
  -webkit-flex: 100%;
  -moz-flex: 100%;
  -ms-flex: 100%;
  -webkit-flex: 100%;
  flex: 100%;
  max-width: 100%;
  padding-left: 15px;
  padding-right: 15px;
  margin-bottom: 30px; }
  .blog-item-teaser {
    position: relative;
    display: block;
    height: 200px;
    overflow: hidden;
    z-index: 1; }
    .blog-item-teaser-inner {
      display: block;
      height: 100%;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      -moz-transition: all ease-in-out 400ms;
      -o-transition: all ease-in-out 400ms;
      -webkit-transition: all ease-in-out 400ms;
      transition: all ease-in-out 400ms; }
  .blog-item-info {
    position: relative;
    padding: 20px;
    background-color: #fff;
    z-index: 2;
    -moz-transition: all ease-in-out 400ms;
    -o-transition: all ease-in-out 400ms;
    -webkit-transition: all ease-in-out 400ms;
    transition: all ease-in-out 400ms;
    text-align: left; }
    .blog-item-info-name {
      font-size: 20px;
      line-height: 30px;
      font-weight: 700;
      display: block;
      min-height: 60px;
      margin-bottom: 15px; }
      .blog-item-info-name:hover {
        color: #ed6e04; }
    .blog-item-info-category {
      display: inline-block;
      vertical-align: middle;
      line-height: 30px;
      padding: 0 20px;
      background-color: #f2f2f2;
      color: #323337;
      text-transform: uppercase; }
      .blog-item-info-category:hover {
        background-color: #e5e5e5;
        color: #323337;
        text-decoration: none; }
    .blog-item-info-date {
      float: right;
      line-height: 30px;
      vertical-align: middle; }
  .blog-item:hover .blog-item-teaser-inner {
    -webkit-transform: scale(1.1, 1.1);
    -moz-transform: scale(1.1, 1.1);
    -ms-transform: scale(1.1, 1.1);
    -o-transform: scale(1.1, 1.1);
    transform: scale(1.1, 1.1); }
  @media (min-width: 545px) {
    .blog-item {
      -webkit-flex: 50%;
      -moz-flex: 50%;
      -ms-flex: 50%;
      -webkit-flex: 50%;
      flex: 50%;
      max-width: 50%; } }
  @media (min-width: 992px) {
    .blog-item {
      -webkit-flex: 33.33333%;
      -moz-flex: 33.33333%;
      -ms-flex: 33.33333%;
      -webkit-flex: 33.33333%;
      flex: 33.33333%;
      max-width: 33.333333%; }
      .blog-item-teaser {
        height: 300px; }
      .blog-item-info {
        padding: 40px;
        margin-top: -110px;
        margin-right: 20px; }
      .blog-item:hover .blog-item-info {
        margin-right: 0px;
        padding: 50px;
        margin-top: -130px; } }

.blog-page-flex {
  padding-top: 36px;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -moz-flex-flow: row wrap;
  -ms-flex-direction: row;
  -ms-flex-wrap: wrap;
  -webkit-flex-flow: row wrap;
  flex-flow: row wrap; }
  @media (min-width: 480px) {
    .blog-page-flex {
      padding-top: 36px; } }
  @media (min-width: 545px) {
    .blog-page-flex {
      padding-top: 45px; } }
  @media (min-width: 768px) {
    .blog-page-flex {
      padding-top: 54px; } }
  @media (min-width: 992px) {
    .blog-page-flex {
      padding-top: 63px; } }
  @media (min-width: 1200px) {
    .blog-page-flex {
      padding-top: 72px; } }
  @media (min-width: 1400px) {
    .blog-page-flex {
      padding-top: 90px; } }

.blog-page-header {
  border-top: 1px solid #fff;
  padding-bottom: 5px;
  padding-top: 10px;
  margin-top: 20px;
  margin-bottom: 20px;
  -webkit-flex: 100%;
  -moz-flex: 100%;
  -ms-flex: 100%;
  -webkit-flex: 100%;
  flex: 100%;
  -webkit-box-ordinal-group: 2;
  -moz-box-ordinal-group: 2;
  -ms-box-ordinal-group: 2;
  -ms-flex-order: 2;
  -webkit-order: 2;
  -moz-order: 2;
  -ms-order: 2;
  order: 2; }
  @media (min-width: 480px) {
    .blog-page-header {
      margin-top: 20px; } }
  @media (min-width: 545px) {
    .blog-page-header {
      margin-top: 20px; } }
  @media (min-width: 768px) {
    .blog-page-header {
      margin-top: 20px; } }
  @media (min-width: 992px) {
    .blog-page-header {
      margin-top: 21px; } }
  @media (min-width: 1200px) {
    .blog-page-header {
      margin-top: 24px; } }
  @media (min-width: 1400px) {
    .blog-page-header {
      margin-top: 30px; } }
  @media (min-width: 480px) {
    .blog-page-header {
      margin-bottom: 20px; } }
  @media (min-width: 545px) {
    .blog-page-header {
      margin-bottom: 20px; } }
  @media (min-width: 768px) {
    .blog-page-header {
      margin-bottom: 24px; } }
  @media (min-width: 992px) {
    .blog-page-header {
      margin-bottom: 28px; } }
  @media (min-width: 1200px) {
    .blog-page-header {
      margin-bottom: 32px; } }
  @media (min-width: 1400px) {
    .blog-page-header {
      margin-bottom: 40px; } }
  @media (min-width: 1200px) {
    .blog-page-header {
      -webkit-box-ordinal-group: 0;
      -moz-box-ordinal-group: 0;
      -ms-box-ordinal-group: 0;
      -ms-flex-order: 0;
      -webkit-order: 0;
      -moz-order: 0;
      -ms-order: 0;
      order: 0;
      padding-top: 0;
      border-top: 0;
      border-bottom: 1px solid #fff; } }
  .blog-page-header-tags {
    font-size: 0;
    margin-bottom: 10px; }
    .blog-page-header-tags a {
      display: inline-block;
      vertical-align: middle;
      line-height: 30px;
      padding: 0 15px;
      text-transform: uppercase;
      font-size: 14px;
      margin-bottom: 10px; }
      .blog-page-header-tags a:not(.blog-page-header-category) {
        background-color: #fff;
        color: #ed6e04; }
        .blog-page-header-tags a:not(.blog-page-header-category):hover {
          background-color: #e5e5e5;
          color: #323337;
          text-decoration: none; }
      .blog-page-header-tags a:not(:last-of-type) {
        margin-right: 10px; }
      @media (min-width: 545px) {
        .blog-page-header-tags a {
          font-size: 14px;
          padding: 0 20px; } }
    @media (min-width: 768px) {
      .blog-page-header-tags {
        margin-bottom: 0px; } }
  .blog-page-header-category {
    background-color: #ed6e04;
    color: #fff; }
    .blog-page-header-category:hover {
      background-color: #d46204;
      color: #fff;
      text-decoration: none; }
  .blog-page-header-date {
    margin-bottom: 10px; }
    @media (min-width: 545px) {
      .blog-page-header-date {
        display: inline-block;
        margin-right: 10px;
        margin-bottom: 0px; }
        .blog-page-header-date:after {
          content: "|";
          margin-left: 10px;
          color: #fff; } }
  .blog-page-header-author {
    margin-bottom: 10px; }
    @media (min-width: 545px) {
      .blog-page-header-author {
        display: inline-block;
        margin-right: 10px;
        margin-bottom: 0px; }
        .blog-page-header-author:after {
          content: "|";
          margin-left: 10px;
          color: #fff; } }
  .blog-page-header-social {
    display: inline-block; }
    .blog-page-header-social ul {
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-box-align: center;
      -moz-box-align: center;
      -ms-flex-align: center;
      -webkit-align-items: center;
      align-items: center;
      margin: 0;
      padding: 0;
      list-style: none; }
      .blog-page-header-social ul li {
        padding: 0; }
        .blog-page-header-social ul li:before {
          display: none; }
        .blog-page-header-social ul li a {
          font-size: 0;
          display: block;
          text-decoration: none;
          width: 30px;
          height: 30px;
          -webkit-border-radius: 50%;
          -moz-border-radius: 50%;
          -ms-border-radius: 50%;
          border-radius: 50%;
          background: none;
          border: 0;
          color: #fff;
          text-align: center;
          -moz-transition: all ease-in-out 200ms;
          -o-transition: all ease-in-out 200ms;
          -webkit-transition: all ease-in-out 200ms;
          transition: all ease-in-out 200ms; }
          .blog-page-header-social ul li a:focus, .blog-page-header-social ul li a:hover {
            background: none;
            color: #fff;
            border: 0;
            text-decoration: none; }
    .blog-page-header-social .facebook {
      background: #3b5998; }
      .blog-page-header-social .facebook:before {
        content: "";
        line-height: 30px;
        font-size: 21px; }
      .blog-page-header-social .facebook:focus, .blog-page-header-social .facebook:hover {
        background: #2d4373; }
    .blog-page-header-social .twitter {
      background: #00aced; }
      .blog-page-header-social .twitter:before {
        content: "";
        line-height: 30px;
        font-size: 21px; }
      .blog-page-header-social .twitter:focus, .blog-page-header-social .twitter:hover {
        background: #0087ba; }
    .blog-page-header-social .linkedin {
      background: #0d77b7; }
      .blog-page-header-social .linkedin:before {
        content: "";
        line-height: 30px;
        font-size: 21px; }
      .blog-page-header-social .linkedin:focus, .blog-page-header-social .linkedin:hover {
        background: #0a5887; }
    .blog-page-header-social .youtube {
      background: #cc181e; }
      .blog-page-header-social .youtube:before {
        content: "";
        line-height: 30px;
        font-size: 21px; }
      .blog-page-header-social .youtube:focus, .blog-page-header-social .youtube:hover {
        background: #9e1317; }
    .blog-page-header-social .instagram {
      background: #8a3ab9; }
      .blog-page-header-social .instagram:before {
        content: "";
        line-height: 30px;
        font-size: 21px; }
      .blog-page-header-social .instagram:focus, .blog-page-header-social .instagram:hover {
        background: #6d2e92; }
    .blog-page-header-social .pinterest {
      background: #cd2029; }
      .blog-page-header-social .pinterest:before {
        content: "";
        line-height: 30px;
        font-size: 21px; }
      .blog-page-header-social .pinterest:focus, .blog-page-header-social .pinterest:hover {
        background: #a11920; }
    .blog-page-header-social .google {
      background: #d34836; }
      .blog-page-header-social .google:before {
        content: "";
        line-height: 30px;
        font-size: 21px; }
      .blog-page-header-social .google:focus, .blog-page-header-social .google:hover {
        background: #b03626; }
    .blog-page-header-social .vimeo {
      background: #45bbff; }
      .blog-page-header-social .vimeo:before {
        content: "";
        line-height: 30px;
        font-size: 21px; }
      .blog-page-header-social .vimeo:focus, .blog-page-header-social .vimeo:hover {
        background: #12a8ff; }
    .blog-page-header-social ul {
      display: inline-block;
      vertical-align: -3px;
      margin-left: 10px; }
      .blog-page-header-social ul li {
        margin: 0; }

.blog-page-title {
  -webkit-flex: 100%;
  -moz-flex: 100%;
  -ms-flex: 100%;
  -webkit-flex: 100%;
  flex: 100%;
  -webkit-box-ordinal-group: 0;
  -moz-box-ordinal-group: 0;
  -ms-box-ordinal-group: 0;
  -ms-flex-order: 0;
  -webkit-order: 0;
  -moz-order: 0;
  -ms-order: 0;
  order: 0; }
  @media (min-width: 1200px) {
    .blog-page-title {
      -webkit-box-ordinal-group: 1;
      -moz-box-ordinal-group: 1;
      -ms-box-ordinal-group: 1;
      -ms-flex-order: 1;
      -webkit-order: 1;
      -moz-order: 1;
      -ms-order: 1;
      order: 1; } }
  .blog-page-title h1 {
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 30px; }
    @media (min-width: 480px) {
      .blog-page-title h1 {
        margin-bottom: 30px; } }
    @media (min-width: 545px) {
      .blog-page-title h1 {
        margin-bottom: 30px; } }
    @media (min-width: 768px) {
      .blog-page-title h1 {
        margin-bottom: 36px; } }
    @media (min-width: 992px) {
      .blog-page-title h1 {
        margin-bottom: 42px; } }
    @media (min-width: 1200px) {
      .blog-page-title h1 {
        margin-bottom: 48px; } }
    @media (min-width: 1400px) {
      .blog-page-title h1 {
        margin-bottom: 60px; } }
    @media (min-width: 1200px) {
      .blog-page-title h1 {
        padding: 0 100px; } }

.blog-page-content {
  -webkit-flex: 100%;
  -moz-flex: 100%;
  -ms-flex: 100%;
  -webkit-flex: 100%;
  flex: 100%;
  -webkit-box-ordinal-group: 1;
  -moz-box-ordinal-group: 1;
  -ms-box-ordinal-group: 1;
  -ms-flex-order: 1;
  -webkit-order: 1;
  -moz-order: 1;
  -ms-order: 1;
  order: 1; }
  @media (min-width: 1200px) {
    .blog-page-content {
      -webkit-box-ordinal-group: 2;
      -moz-box-ordinal-group: 2;
      -ms-box-ordinal-group: 2;
      -ms-flex-order: 2;
      -webkit-order: 2;
      -moz-order: 2;
      -ms-order: 2;
      order: 2; } }

@media (min-width: 1200px) {
  .blog-page-latest .items-container .blog-item:nth-of-type(4) {
    display: none; } }

.author-item {
  margin-top: 30px;
  background-color: #fff;
  font-size: 0; }
  @media (min-width: 480px) {
    .author-item {
      margin-top: 30px; } }
  @media (min-width: 545px) {
    .author-item {
      margin-top: 30px; } }
  @media (min-width: 768px) {
    .author-item {
      margin-top: 36px; } }
  @media (min-width: 992px) {
    .author-item {
      margin-top: 42px; } }
  @media (min-width: 1200px) {
    .author-item {
      margin-top: 48px; } }
  @media (min-width: 1400px) {
    .author-item {
      margin-top: 60px; } }
  .author-item-left {
    display: inline-block;
    vertical-align: top;
    width: 100%; }
    @media (min-width: 545px) {
      .author-item-left {
        width: 160px; } }
    @media (min-width: 768px) {
      .author-item-left {
        width: 220px; } }
  .author-item-right {
    display: inline-block;
    vertical-align: top;
    width: 100%;
    padding: 20px 15px 20px 15px; }
    @media (min-width: 545px) {
      .author-item-right {
        margin-left: -160px;
        padding: 30px 40px 30px 160px; } }
    @media (min-width: 768px) {
      .author-item-right {
        margin-left: -220px;
        padding: 30px 40px 30px 220px; } }
  .author-item-avatar {
    padding: 20px; }
    @media (min-width: 768px) {
      .author-item-avatar {
        padding: 20px 40px; } }
    .author-item-avatar img {
      display: block;
      border-radius: 50%; }
  .author-item-name {
    font-size: 22px;
    line-height: 30px;
    color: #646569;
    font-weight: 700;
    text-transform: uppercase; }
  .author-item-description {
    font-size: 16px;
    line-height: 25px; }
  .author-item-social {
    text-align: right; }
    .author-item-social ul {
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-box-align: center;
      -moz-box-align: center;
      -ms-flex-align: center;
      -webkit-align-items: center;
      align-items: center;
      margin: 0;
      padding: 0;
      list-style: none; }
      .author-item-social ul li {
        padding: 0; }
        .author-item-social ul li:before {
          display: none; }
        .author-item-social ul li a {
          font-size: 0;
          display: block;
          text-decoration: none;
          width: 30px;
          height: 30px;
          -webkit-border-radius: 50%;
          -moz-border-radius: 50%;
          -ms-border-radius: 50%;
          border-radius: 50%;
          background: none;
          border: 0;
          color: #fff;
          text-align: center;
          -moz-transition: all ease-in-out 200ms;
          -o-transition: all ease-in-out 200ms;
          -webkit-transition: all ease-in-out 200ms;
          transition: all ease-in-out 200ms; }
          .author-item-social ul li a:focus, .author-item-social ul li a:hover {
            background: none;
            color: #fff;
            border: 0;
            text-decoration: none; }
    .author-item-social .facebook {
      background: #3b5998; }
      .author-item-social .facebook:before {
        content: "";
        line-height: 30px;
        font-size: 21px; }
      .author-item-social .facebook:focus, .author-item-social .facebook:hover {
        background: #2d4373; }
    .author-item-social .twitter {
      background: #00aced; }
      .author-item-social .twitter:before {
        content: "";
        line-height: 30px;
        font-size: 21px; }
      .author-item-social .twitter:focus, .author-item-social .twitter:hover {
        background: #0087ba; }
    .author-item-social .linkedin {
      background: #0d77b7; }
      .author-item-social .linkedin:before {
        content: "";
        line-height: 30px;
        font-size: 21px; }
      .author-item-social .linkedin:focus, .author-item-social .linkedin:hover {
        background: #0a5887; }
    .author-item-social .youtube {
      background: #cc181e; }
      .author-item-social .youtube:before {
        content: "";
        line-height: 30px;
        font-size: 21px; }
      .author-item-social .youtube:focus, .author-item-social .youtube:hover {
        background: #9e1317; }
    .author-item-social .instagram {
      background: #8a3ab9; }
      .author-item-social .instagram:before {
        content: "";
        line-height: 30px;
        font-size: 21px; }
      .author-item-social .instagram:focus, .author-item-social .instagram:hover {
        background: #6d2e92; }
    .author-item-social .pinterest {
      background: #cd2029; }
      .author-item-social .pinterest:before {
        content: "";
        line-height: 30px;
        font-size: 21px; }
      .author-item-social .pinterest:focus, .author-item-social .pinterest:hover {
        background: #a11920; }
    .author-item-social .google {
      background: #d34836; }
      .author-item-social .google:before {
        content: "";
        line-height: 30px;
        font-size: 21px; }
      .author-item-social .google:focus, .author-item-social .google:hover {
        background: #b03626; }
    .author-item-social .vimeo {
      background: #45bbff; }
      .author-item-social .vimeo:before {
        content: "";
        line-height: 30px;
        font-size: 21px; }
      .author-item-social .vimeo:focus, .author-item-social .vimeo:hover {
        background: #12a8ff; }
    .author-item-social ul {
      display: block; }
      .author-item-social ul li {
        display: inline-block;
        margin-bottom: 20px; }

@media (min-width: 768px) {
  .milestone-items {
    margin: 0 60px; } }

.milestone-items .slick-list {
  z-index: 1; }
  .milestone-items .slick-list:before {
    content: '';
    display: block;
    width: 100%;
    height: 1px;
    background-color: #fff;
    position: absolute;
    top: 50%;
    left: 0px; }

.milestone-items .slick-arrow {
  display: block;
  background-color: rgba(129, 127, 126, 0.8);
  color: #fff;
  width: 40px;
  height: 40px;
  text-align: center;
  border-radius: 50%;
  border: 0px;
  overflow: hidden;
  position: absolute;
  bottom: -5px;
  -webkit-transform: translate(0, -50%);
  -moz-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  -o-transform: translate(0, -50%);
  transform: translate(0, -50%);
  font-size: 0px;
  cursor: pointer;
  z-index: 2; }
  @media (min-width: 768px) {
    .milestone-items .slick-arrow {
      top: 50%;
      bottom: auto; } }
  .milestone-items .slick-arrow:before {
    font-size: 14px;
    line-height: 40px; }
  .milestone-items .slick-arrow:hover {
    background-color: #817f7e;
    color: #fff;
    text-decoration: none; }
  .milestone-items .slick-arrow:focus {
    outline: none; }
  @media (min-width: 768px) {
    .milestone-items .slick-arrow {
      width: 60px;
      height: 60px; }
      .milestone-items .slick-arrow:before {
        font-size: 22px;
        line-height: 60px; } }
  .milestone-items .slick-arrow.slick-disabled {
    background-color: rgba(129, 127, 126, 0.2); }
    .milestone-items .slick-arrow.slick-disabled:hover {
      background-color: rgba(129, 127, 126, 0.2); }

.milestone-items .slick-prev {
  left: 15px; }
  @media (min-width: 768px) {
    .milestone-items .slick-prev {
      left: -60px; } }
  .milestone-items .slick-prev:before {
    content: "\e91a"; }

.milestone-items .slick-next {
  right: 15px; }
  @media (min-width: 768px) {
    .milestone-items .slick-next {
      right: -60px; } }
  .milestone-items .slick-next:before {
    content: "\e91b"; }

.milestone-item {
  height: 400px;
  position: relative; }
  @media (min-width: 768px) {
    .milestone-item {
      height: 860px;
      margin: 0 120px; } }
  .milestone-item-inner {
    background-color: #fff;
    height: 400px;
    opacity: 0;
    -moz-transition: opacity 400ms linear, left 400ms linear 400ms, right 400ms linear 400ms;
    -o-transition: opacity 400ms linear, left 400ms linear 400ms, right 400ms linear 400ms;
    -webkit-transition: opacity 400ms linear, left 400ms linear 400ms, right 400ms linear 400ms;
    transition: opacity 400ms linear, left 400ms linear 400ms, right 400ms linear 400ms; }
    @media (min-width: 768px) {
      .milestone-item-inner {
        position: absolute;
        top: 0px;
        left: 0px;
        right: 0px; } }
    .milestone-item-inner:before {
      content: '';
      display: block;
      height: 7px;
      width: 7px;
      background-color: #ed6e04;
      border-radius: 50%;
      position: absolute;
      top: 427px;
      left: 57px; }
    .milestone-item-inner:after {
      content: '';
      display: block;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 20px 20px 0 20px;
      border-color: #fff transparent transparent transparent;
      position: absolute;
      top: 400px;
      left: 40px; }
  .milestone-item-teaser {
    height: 165px;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat; }
    @media (min-width: 768px) {
      .milestone-item-teaser {
        height: 200px; } }
  .milestone-item-info {
    padding: 15px; }
    @media (min-width: 768px) {
      .milestone-item-info {
        padding: 15px 16px 15px 16px; } }
  @media (min-width: 768px) and (min-width: 480px) {
    .milestone-item-info {
      padding: 15px 16px 15px 16px; } }
  @media (min-width: 768px) and (min-width: 545px) {
    .milestone-item-info {
      padding: 15px 20px 15px 20px; } }
  @media (min-width: 768px) and (min-width: 768px) {
    .milestone-item-info {
      padding: 18px 24px 18px 24px; } }
  @media (min-width: 768px) and (min-width: 992px) {
    .milestone-item-info {
      padding: 21px 28px 21px 28px; } }
  @media (min-width: 768px) and (min-width: 1200px) {
    .milestone-item-info {
      padding: 24px 32px 24px 32px; } }
  @media (min-width: 768px) and (min-width: 1400px) {
    .milestone-item-info {
      padding: 30px 40px 30px 40px; } }
  .milestone-item-name {
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 10px;
    font-weight: 700;
    color: #646569; }
    @media (min-width: 480px) {
      .milestone-item-name {
        font-size: 16px; } }
    @media (min-width: 545px) {
      .milestone-item-name {
        font-size: 16px; } }
    @media (min-width: 768px) {
      .milestone-item-name {
        font-size: 16px; } }
    @media (min-width: 992px) {
      .milestone-item-name {
        font-size: 16px; } }
    @media (min-width: 1200px) {
      .milestone-item-name {
        font-size: 18px; } }
    @media (min-width: 1400px) {
      .milestone-item-name {
        font-size: 22px; } }
    @media (min-width: 480px) {
      .milestone-item-name {
        line-height: 20px; } }
    @media (min-width: 545px) {
      .milestone-item-name {
        line-height: 20px; } }
    @media (min-width: 768px) {
      .milestone-item-name {
        line-height: 20px; } }
    @media (min-width: 992px) {
      .milestone-item-name {
        line-height: 21px; } }
    @media (min-width: 1200px) {
      .milestone-item-name {
        line-height: 24px; } }
    @media (min-width: 1400px) {
      .milestone-item-name {
        line-height: 30px; } }
    @media (min-width: 480px) {
      .milestone-item-name {
        margin-bottom: 10px; } }
    @media (min-width: 545px) {
      .milestone-item-name {
        margin-bottom: 10px; } }
    @media (min-width: 768px) {
      .milestone-item-name {
        margin-bottom: 12px; } }
    @media (min-width: 992px) {
      .milestone-item-name {
        margin-bottom: 14px; } }
    @media (min-width: 1200px) {
      .milestone-item-name {
        margin-bottom: 16px; } }
    @media (min-width: 1400px) {
      .milestone-item-name {
        margin-bottom: 20px; } }
  .milestone-item-text {
    min-height: 90px;
    overflow: hidden;
    text-overflow: ellipsis; }
  @media (min-width: 768px) {
    .milestone-item:nth-of-type(even) .milestone-item-inner {
      top: 460px; } }
  .milestone-item:nth-of-type(even) .milestone-item-inner:before {
    top: -33px; }
  .milestone-item:nth-of-type(even) .milestone-item-inner:after {
    top: -20px;
    border-width: 0 20px 20px 20px;
    border-color: transparent transparent #fff transparent; }
  .milestone-item.slick-active .milestone-item-inner {
    -moz-transition: opacity 400ms linear 400ms, left 400ms linear, right 400ms linear;
    -o-transition: opacity 400ms linear 400ms, left 400ms linear, right 400ms linear;
    -webkit-transition: opacity 400ms linear 400ms, left 400ms linear, right 400ms linear;
    transition: opacity 400ms linear 400ms, left 400ms linear, right 400ms linear;
    opacity: 1; }
  @media (min-width: 768px) {
    .milestone-item.slick-current .milestone-item-inner {
      left: -60px;
      right: -200px; }
    .milestone-item.slick-current + div .milestone-item-inner {
      left: -130px;
      right: -130px; }
    .milestone-item.slick-current + div + div .milestone-item-inner {
      left: -200px;
      right: -60px; } }

.single-image-widget-wrapper,
.double-image-widget-wrapper {
  margin-left: -15px;
  margin-right: -15px; }
  @media (min-width: 1600px) {
    .single-image-widget-wrapper,
    .double-image-widget-wrapper {
      margin-left: -80px;
      margin-right: -80px; } }

.double-image-widget-wrapper .row {
  margin-left: 0;
  margin-right: 0; }
  .double-image-widget-wrapper .row [class*="col-"] {
    padding-left: 0;
    padding-right: 0; }

@media (min-width: 768px) {
  .double-image-widget-wrapper .row {
    margin-left: -15px;
    margin-right: -15px; }
    .double-image-widget-wrapper .row [class*="col-"] {
      padding-left: 15px;
      padding-right: 15px; } }

@media (min-width: 1600px) {
  .double-image-widget-wrapper .row {
    margin-left: -20px;
    margin-right: -20px; }
    .double-image-widget-wrapper .row [class*="col-"] {
      padding-left: 20px;
      padding-right: 20px; } }

@media (min-width: 768px) {
  .custom-layout .row {
    margin-left: -15px;
    margin-right: -15px; }
    .custom-layout .row [class*="col-"] {
      padding-left: 15px;
      padding-right: 15px; } }

@media (min-width: 1600px) {
  .custom-layout .row {
    margin-left: -20px;
    margin-right: -20px; }
    .custom-layout .row [class*="col-"] {
      padding-left: 20px;
      padding-right: 20px; } }

.embed-video {
  position: relative; }
  .embed-video.small {
    height: 320px; }
  .embed-video.middle {
    height: 480px; }
  .embed-video.large {
    height: 600px; }
  .embed-video iframe {
    height: 100%;
    width: 100%; }
  .embed-video-overflow {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    opacity: 1;
    -moz-transition: opacity 800ms linear 200ms, top 0ms linear 1000ms;
    -o-transition: opacity 800ms linear 200ms, top 0ms linear 1000ms;
    -webkit-transition: opacity 800ms linear 200ms, top 0ms linear 1000ms;
    transition: opacity 800ms linear 200ms, top 0ms linear 1000ms; }
    .embed-video-overflow.played {
      opacity: 0;
      top: 100%; }
  .embed-video-icon {
    display: block;
    margin: 100px auto;
    width: 60px;
    height: 60px;
    line-height: 64px;
    border-radius: 50%;
    background-color: rgba(129, 127, 126, 0.8);
    text-align: center;
    overflow: hidden;
    -moz-transition: all ease-in-out 200ms;
    -o-transition: all ease-in-out 200ms;
    -webkit-transition: all ease-in-out 200ms;
    transition: all ease-in-out 200ms; }
    .embed-video-icon:before {
      display: inline-block;
      content: "\e901";
      color: #fff;
      margin-left: 4px;
      -moz-transition: all ease-in-out 200ms;
      -o-transition: all ease-in-out 200ms;
      -webkit-transition: all ease-in-out 200ms;
      transition: all ease-in-out 200ms; }
    .embed-video-icon:hover {
      background-color: #817f7e; }
      .embed-video-icon:hover:before {
        -ms-transform: scale(1.3, 1.3);
        -webkit-transform: scale(1.3, 1.3);
        transform: scale(1.3, 1.3); }
    @media (min-width: 992px) {
      .embed-video-icon {
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
        -moz-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        -o-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        margin: 0; } }

.zfp-collapse-container {
  position: relative;
  padding-bottom: 20px;
  margin-bottom: 20px; }
  @media (min-width: 992px) {
    .zfp-collapse-container {
      padding-bottom: 30px;
      margin-bottom: 30px; } }
  .zfp-collapse-container .btn-more {
    position: absolute;
    bottom: 0px;
    left: 50%;
    -webkit-transform: translate(-50%, 50%) rotateZ(45deg);
    -moz-transform: translate(-50%, 50%) rotateZ(45deg);
    -ms-transform: translate(-50%, 50%) rotateZ(45deg);
    -o-transform: translate(-50%, 50%) rotateZ(45deg);
    transform: translate(-50%, 50%) rotateZ(45deg);
    z-index: 2;
    -moz-transition: all ease-in-out 350ms;
    -o-transition: all ease-in-out 350ms;
    -webkit-transition: all ease-in-out 350ms;
    transition: all ease-in-out 350ms; }
    .zfp-collapse-container .btn-more.collapsed {
      -webkit-transform: translate(-50%, 50%) rotateZ(0deg);
      -moz-transform: translate(-50%, 50%) rotateZ(0deg);
      -ms-transform: translate(-50%, 50%) rotateZ(0deg);
      -o-transform: translate(-50%, 50%) rotateZ(0deg);
      transform: translate(-50%, 50%) rotateZ(0deg); }

.zfp-collapse-header {
  margin-bottom: 10px; }
  @media (min-width: 992px) {
    .zfp-collapse-header {
      margin-bottom: 20px; } }

.zfp-collapse-content-inner {
  padding-bottom: 10px; }
  @media (min-width: 992px) {
    .zfp-collapse-content-inner {
      padding-bottom: 20px; } }

.hero-video .video-wrapper {
  position: relative;
  margin-top: -65px; }
  @media (min-width: 1200px) {
    .hero-video .video-wrapper {
      margin-top: -80px; } }
  body.EditMode .hero-video .video-wrapper,
  body.DesignMode .hero-video .video-wrapper {
    margin-top: 0; }

.hero-video .video-container {
  position: absolute;
  top: 65px;
  bottom: 0;
  right: 0;
  left: 0;
  overflow: hidden;
  z-index: 1; }
  .hero-video .video-container video {
    position: absolute;
    display: none;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%); }
    body.EditMode .hero-video .video-container video,
    body.DesignMode .hero-video .video-container video {
      display: none; }
  .hero-video .video-container .mobile-video-background {
    position: absolute;
    height: 100vh;
    width: 100%;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    background-position: center;
    background-size: cover; }
  @media (min-width: 545px) {
    .hero-video .video-container video {
      display: block; }
    .hero-video .video-container .mobile-video-background {
      display: none; } }
  @media (min-width: 1200px) {
    .hero-video .video-container {
      top: 80px; } }

.hero-video .video-overlay {
  z-index: 2;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.3); }

.hero-video .video-content {
  min-height: 100vh;
  position: relative;
  z-index: 3; }
  .hero-video .video-content-inner {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    font-size: 32px;
    font-size: 2rem;
    line-height: 40px;
    color: #fff;
    text-align: center;
    text-transform: uppercase;
    font-weight: 300; }
    @media (min-width: 480px) {
      .hero-video .video-content-inner {
        font-size: 32px;
        font-size: 2rem; } }
    @media (min-width: 545px) {
      .hero-video .video-content-inner {
        font-size: 40px;
        font-size: 2.5rem; } }
    @media (min-width: 768px) {
      .hero-video .video-content-inner {
        font-size: 48px;
        font-size: 3rem; } }
    @media (min-width: 992px) {
      .hero-video .video-content-inner {
        font-size: 56px;
        font-size: 3.5rem; } }
    @media (min-width: 1200px) {
      .hero-video .video-content-inner {
        font-size: 64px;
        font-size: 4rem; } }
    @media (min-width: 1400px) {
      .hero-video .video-content-inner {
        font-size: 80px;
        font-size: 5rem; } }
    @media (min-width: 480px) {
      .hero-video .video-content-inner {
        line-height: 40px; } }
    @media (min-width: 545px) {
      .hero-video .video-content-inner {
        line-height: 50px; } }
    @media (min-width: 768px) {
      .hero-video .video-content-inner {
        line-height: 60px; } }
    @media (min-width: 992px) {
      .hero-video .video-content-inner {
        line-height: 70px; } }
    @media (min-width: 1200px) {
      .hero-video .video-content-inner {
        line-height: 80px; } }
    @media (min-width: 1400px) {
      .hero-video .video-content-inner {
        line-height: 100px; } }
    .hero-video .video-content-inner a {
      display: inline-block;
      position: relative;
      padding: 0 4px;
      margin: 0 -4px; }
      .hero-video .video-content-inner a:after {
        content: '';
        display: block;
        position: absolute;
        bottom: 5px;
        left: 0;
        right: 100%;
        border-bottom: 2px solid transparent;
        -moz-transition: all ease-in-out 350ms;
        -o-transition: all ease-in-out 350ms;
        -webkit-transition: all ease-in-out 350ms;
        transition: all ease-in-out 350ms; }
      .hero-video .video-content-inner a:hover {
        text-decoration: none;
        color: #646569; }
        .hero-video .video-content-inner a:hover:after {
          right: 0;
          border-color: #ed6e04; }

.hero-video .video-scroll-btn {
  display: inline-block;
  position: absolute;
  left: 50%;
  bottom: 60px;
  -webkit-transform: translate(-50%, 0%);
  -moz-transform: translate(-50%, 0%);
  -ms-transform: translate(-50%, 0%);
  -o-transform: translate(-50%, 0%);
  transform: translate(-50%, 0%);
  width: 60px;
  height: 60px;
  line-height: 60px;
  border-radius: 50%;
  background-color: rgba(129, 127, 126, 0.8);
  text-align: center;
  color: #fff;
  overflow: hidden; }
  .hero-video .video-scroll-btn:after {
    content: "\e903";
    line-height: 64px;
    font-size: 14px;
    -moz-transition: all ease-in-out 200ms;
    -o-transition: all ease-in-out 200ms;
    -webkit-transition: all ease-in-out 200ms;
    transition: all ease-in-out 200ms; }
  .hero-video .video-scroll-btn:hover {
    background-color: #817f7e;
    color: #fff;
    text-decoration: none; }
    .hero-video .video-scroll-btn:hover:after {
      line-height: 70px; }
  @media (min-width: 1200px) {
    .hero-video .video-scroll-btn {
      bottom: 100px; } }

.homepage-text {
  padding-top: 40px;
  padding-bottom: 40px;
  text-align: center; }
  @media (min-width: 480px) {
    .homepage-text {
      padding-top: 40px; } }
  @media (min-width: 545px) {
    .homepage-text {
      padding-top: 50px; } }
  @media (min-width: 768px) {
    .homepage-text {
      padding-top: 60px; } }
  @media (min-width: 992px) {
    .homepage-text {
      padding-top: 70px; } }
  @media (min-width: 1200px) {
    .homepage-text {
      padding-top: 80px; } }
  @media (min-width: 1400px) {
    .homepage-text {
      padding-top: 100px; } }
  @media (min-width: 480px) {
    .homepage-text {
      padding-bottom: 40px; } }
  @media (min-width: 545px) {
    .homepage-text {
      padding-bottom: 50px; } }
  @media (min-width: 768px) {
    .homepage-text {
      padding-bottom: 60px; } }
  @media (min-width: 992px) {
    .homepage-text {
      padding-bottom: 70px; } }
  @media (min-width: 1200px) {
    .homepage-text {
      padding-bottom: 80px; } }
  @media (min-width: 1400px) {
    .homepage-text {
      padding-bottom: 100px; } }

.partner-wrapper {
  background-color: #f2f2f2;
  padding-top: 20px;
  padding-bottom: 20px;
  position: relative; }
  @media (min-width: 480px) {
    .partner-wrapper {
      padding-top: 20px; } }
  @media (min-width: 545px) {
    .partner-wrapper {
      padding-top: 20px; } }
  @media (min-width: 768px) {
    .partner-wrapper {
      padding-top: 20px; } }
  @media (min-width: 992px) {
    .partner-wrapper {
      padding-top: 21px; } }
  @media (min-width: 1200px) {
    .partner-wrapper {
      padding-top: 24px; } }
  @media (min-width: 1400px) {
    .partner-wrapper {
      padding-top: 30px; } }
  @media (min-width: 480px) {
    .partner-wrapper {
      padding-bottom: 20px; } }
  @media (min-width: 545px) {
    .partner-wrapper {
      padding-bottom: 20px; } }
  @media (min-width: 768px) {
    .partner-wrapper {
      padding-bottom: 21px; } }
  @media (min-width: 992px) {
    .partner-wrapper {
      padding-bottom: 25px; } }
  @media (min-width: 1200px) {
    .partner-wrapper {
      padding-bottom: 28px; } }
  @media (min-width: 1400px) {
    .partner-wrapper {
      padding-bottom: 35px; } }
  .partner-wrapper:after {
    content: '';
    position: absolute;
    top: 100%;
    right: 0;
    display: block;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 10px 100vw 0 0;
    border-color: #f2f2f2 transparent transparent transparent;
    -moz-transition: all ease-in-out 500ms;
    -o-transition: all ease-in-out 500ms;
    -webkit-transition: all ease-in-out 500ms;
    transition: all ease-in-out 500ms; }
    @media (min-width: 992px) {
      .partner-wrapper:after {
        border-width: 20px 100vw 0 0; } }
    @media (min-width: 1200px) {
      .partner-wrapper:after {
        border-width: 40px 100vw 0 0; } }
    body.EditMode .partner-wrapper:after,
    body.DesignMode .partner-wrapper:after {
      border-width: 0; }

.partner-title {
  text-align: center;
  margin-bottom: 20px;
  font-size: 22px;
  line-height: 35px;
  color: #646569; }

.partner-items {
  margin-left: -15px;
  margin-right: -15px; }

.partner-item {
  padding: 15px; }
  .partner-item img {
    margin: 0 auto;
    max-height: 67px; }

/* slider overide */
.zfp-slider {
  overflow: hidden;
  max-height: 480px; }
  @media (min-width: 480px) {
    .zfp-slider {
      max-height: 480px; } }
  @media (min-width: 545px) {
    .zfp-slider {
      max-height: 480px; } }
  @media (min-width: 768px) {
    .zfp-slider {
      max-height: 480px; } }
  @media (min-width: 992px) {
    .zfp-slider {
      max-height: 560px; } }
  @media (min-width: 1200px) {
    .zfp-slider {
      max-height: 640px; } }
  @media (min-width: 1400px) {
    .zfp-slider {
      max-height: 800px; } }
  .zfp-slider.slick-initialized {
    max-height: none; }
  .zfp-slider .main-slider-inner-content {
    margin: 0;
    max-width: 60%; }
    .zfp-slider .main-slider-inner-content-text {
      font-size: 15px;
      line-height: 22px; }
      .zfp-slider .main-slider-inner-content-text q {
        display: block;
        font-size: 22px;
        line-height: 30px;
        font-weight: 700;
        margin-bottom: 15px; }
        .zfp-slider .main-slider-inner-content-text q:before, .zfp-slider .main-slider-inner-content-text q:after {
          display: inline-block;
          vertical-align: 2px; }
        .zfp-slider .main-slider-inner-content-text q:before {
          margin-right: 3px; }
        .zfp-slider .main-slider-inner-content-text q:after {
          margin-left: 3px; }
    @media (min-width: 545px) {
      .zfp-slider .main-slider-inner-content {
        max-width: 55%; } }
    @media (min-width: 768px) {
      .zfp-slider .main-slider-inner-content-text {
        font-size: 18px;
        line-height: 24px; }
        .zfp-slider .main-slider-inner-content-text q {
          font-size: 36px;
          line-height: 45px;
          margin-bottom: 25px; } }
    @media (min-width: 992px) {
      .zfp-slider .main-slider-inner-content {
        max-width: 45%; } }
    @media (min-width: 1200px) {
      .zfp-slider .main-slider-inner-content {
        max-width: 680px; }
        .zfp-slider .main-slider-inner-content-text {
          font-size: 22px;
          line-height: 35px; }
          .zfp-slider .main-slider-inner-content-text q {
            font-size: 45px;
            line-height: 55px; } }
  .zfp-slider .slick-dots {
    bottom: 0px; }
    @media (min-width: 1200px) {
      .zfp-slider .slick-dots {
        bottom: 26px; } }
    .zfp-slider .slick-dots li {
      line-height: 0;
      font-size: 0; }
      .zfp-slider .slick-dots li:only-child {
        display: none; }
  body.EditMode .zfp-slider,
  body.DesignMode .zfp-slider {
    max-width: 1280px;
    max-height: 720px;
    margin: 0 auto;
    overflow: hidden; }

.map-wrapper .map-points {
  display: none; }

.map-wrapper .marker-detail-container {
  display: none; }

.map-canvas {
  height: 600px; }

/* jobs */
.job-items {
  padding-bottom: 60px;
  position: relative; }
  .job-items .row {
    margin-left: -15px;
    margin-right: -15px; }
    .job-items .row [class*="col-"] {
      padding-left: 15px;
      padding-right: 15px; }
  .job-items [class*="col-"] {
    margin-bottom: 30px; }
  .job-items .btn-more {
    position: absolute;
    bottom: 0px;
    left: 50%;
    -webkit-transform: translate(-50%, 50%) rotateZ(45deg);
    -moz-transform: translate(-50%, 50%) rotateZ(45deg);
    -ms-transform: translate(-50%, 50%) rotateZ(45deg);
    -o-transform: translate(-50%, 50%) rotateZ(45deg);
    transform: translate(-50%, 50%) rotateZ(45deg);
    z-index: 2;
    -moz-transition: all ease-in-out 350ms;
    -o-transition: all ease-in-out 350ms;
    -webkit-transition: all ease-in-out 350ms;
    transition: all ease-in-out 350ms; }
    .job-items .btn-more.collapsed {
      -webkit-transform: translate(-50%, 50%) rotateZ(0deg);
      -moz-transform: translate(-50%, 50%) rotateZ(0deg);
      -ms-transform: translate(-50%, 50%) rotateZ(0deg);
      -o-transform: translate(-50%, 50%) rotateZ(0deg);
      transform: translate(-50%, 50%) rotateZ(0deg); }

.job-item {
  background-color: #fff;
  padding: 40px;
  position: relative;
  min-height: 280px;
  height: 100%; }
  .job-item-name {
    font-weight: 700;
    font-size: 22px;
    line-height: 30px;
    margin-bottom: 15px;
    display: inline-block;
    position: relative;
    padding: 0 4px;
    padding-left: 0; }
    .job-item-name:after {
      content: '';
      display: block;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 100%;
      border-bottom: 2px solid transparent;
      -moz-transition: all ease-in-out 350ms;
      -o-transition: all ease-in-out 350ms;
      -webkit-transition: all ease-in-out 350ms;
      transition: all ease-in-out 350ms; }
    .job-item-name:hover {
      text-decoration: none; }
      .job-item-name:hover:after {
        right: 0;
        border-color: #ed6e04; }
  .job-item-description {
    margin-bottom: 15px; }
    .job-item-description big {
      color: #323337;
      font-size: 16px;
      line-height: 25px; }

.job-detail-header h1 {
  text-transform: uppercase; }

/* contact */
.contact-column {
  text-align: center; }
  .contact-column svg {
    width: 100px;
    height: 100px; }
  .contact-column-text {
    font-size: 22px;
    line-height: 35px;
    color: #646569;
    padding-top: 10px; }
    .contact-column-text strong {
      line-height: 50px; }

.search-box {
  display: inline-block;
  width: 100%;
  max-width: 760px;
  font-size: 0; }
  .search-box.map-search {
    max-width: 430px; }
  .search-box-inner {
    display: inline-block;
    vertical-align: top;
    width: 100%;
    background-color: #fff;
    padding-right: 90px;
    margin-right: -80px; }
    .map-search .search-box-inner {
      padding-right: 0;
      margin-right: 0; }
    .search-box-inner input[type="text"] {
      line-height: 70px;
      background: #fff;
      font-size: 16px;
      height: 70px;
      border: 0; }
      .search-box-inner input[type="text"]:focus {
        outline: none; }
  .search-box-btn {
    position: relative;
    display: inline-block;
    vertical-align: top;
    width: 80px;
    height: 70px;
    line-height: 70px; }
    .map-search .search-box-btn {
      display: none; }
    .search-box-btn:before {
      content: "\e904";
      display: inline-block;
      vertical-align: middle;
      width: 40px;
      height: 40px;
      line-height: 40px;
      text-align: center;
      background-color: #ed6e04;
      color: #fff;
      border-radius: 50%;
      font-size: 20px;
      -moz-transition: all ease-in-out 200ms;
      -o-transition: all ease-in-out 200ms;
      -webkit-transition: all ease-in-out 200ms;
      transition: all ease-in-out 200ms; }
    .search-box-btn:hover:before {
      width: 80px;
      height: 70px;
      border-radius: 0;
      line-height: 70px; }

.marker-detail {
  color: #fff;
  text-align: center;
  padding: 25px;
  font-size: 16px;
  line-height: 25px;
  font-family: "Fedra", sans-serif; }
  .marker-detail h2 {
    font-size: 16px;
    line-height: 25px;
    margin-bottom: 0; }
  .marker-detail .number,
  .marker-detail .city,
  .marker-detail .email {
    padding-bottom: 10px; }
  .marker-detail a:hover {
    color: #ed6e04; }
  .marker-detail-position .gm-style-iw-c {
    padding: 0 !important; }
  .marker-detail-position:after {
    width: 0 !important;
    height: 0 !important;
    border-style: solid;
    border-width: 11px 10px 0 10px;
    border-color: #323337 transparent transparent transparent;
    transform: translate(-50%, 0%) !important;
    box-shadow: none !important;
    opacity: 0.6 !important; }
  .marker-detail-flyout {
    background-color: rgba(50, 51, 55, 0.8) !important;
    border: 0 !important;
    border-radius: 0 !important; }
    .marker-detail-flyout > div {
      background-color: transparent !important;
      overflow: hidden !important; }
  .marker-detail-close {
    opacity: 1 !important;
    width: 50px !important;
    height: 50px !important; }
    .marker-detail-close > img {
      display: none !important; }
    .marker-detail-close:before {
      content: "\e900";
      color: #ed6e04; }

/* search items */
.search-items .pux-infinite-repeater-result-count-wrapper {
  display: none; }

.search-items .show-more-container {
  text-align: center; }
  .search-items .show-more-container .arrow-down-link {
    font-size: 0;
    display: inline-block;
    background-color: rgba(129, 127, 126, 0.8);
    color: #fff;
    width: 30px;
    height: 30px;
    text-align: center;
    border-radius: 50%;
    overflow: hidden; }
    .search-items .show-more-container .arrow-down-link:before {
      content: "\e902";
      line-height: 30px;
      font-size: 14px; }
    .search-items .show-more-container .arrow-down-link:hover {
      background-color: #817f7e;
      color: #fff;
      text-decoration: none; }
    @media (min-width: 768px) {
      .search-items .show-more-container .arrow-down-link {
        width: 40px;
        height: 40px; }
        .search-items .show-more-container .arrow-down-link:before {
          line-height: 40px; } }
    @media (min-width: 992px) {
      .search-items .show-more-container .arrow-down-link {
        width: 60px;
        height: 60px; }
        .search-items .show-more-container .arrow-down-link:before {
          line-height: 60px;
          font-size: 22px; } }

.search-item {
  display: block;
  padding: 30px 40px; }
  .search-item:hover {
    text-decoration: none;
    padding: 30px 40px 30px 50px; }
  .search-item-box {
    display: block;
    background-color: #fff;
    margin-bottom: 20px; }
  .search-item-relevation {
    background-color: #646569; }
    .search-item-relevation-bar {
      background-color: #ed6e04;
      height: 4px; }
  .search-item-name {
    font-weight: 700;
    color: #ed6e04;
    margin-bottom: 10px; }
  .search-item-perex {
    color: #000;
    margin-bottom: 10px; }
  .search-item-date {
    color: #646569; }

.page-404-container {
  text-align: center; }

.page-404-image {
  margin-bottom: 20px; }
  @media (min-width: 480px) {
    .page-404-image {
      margin-bottom: 20px; } }
  @media (min-width: 545px) {
    .page-404-image {
      margin-bottom: 25px; } }
  @media (min-width: 768px) {
    .page-404-image {
      margin-bottom: 30px; } }
  @media (min-width: 992px) {
    .page-404-image {
      margin-bottom: 35px; } }
  @media (min-width: 1200px) {
    .page-404-image {
      margin-bottom: 40px; } }
  @media (min-width: 1400px) {
    .page-404-image {
      margin-bottom: 50px; } }
  .page-404-image svg {
    margin: 0 auto;
    width: 120px;
    height: 160px; }
    @media (min-width: 480px) {
      .page-404-image svg {
        width: 120px; } }
    @media (min-width: 545px) {
      .page-404-image svg {
        width: 150px; } }
    @media (min-width: 768px) {
      .page-404-image svg {
        width: 180px; } }
    @media (min-width: 992px) {
      .page-404-image svg {
        width: 210px; } }
    @media (min-width: 1200px) {
      .page-404-image svg {
        width: 240px; } }
    @media (min-width: 1400px) {
      .page-404-image svg {
        width: 300px; } }
    @media (min-width: 480px) {
      .page-404-image svg {
        height: 160px; } }
    @media (min-width: 545px) {
      .page-404-image svg {
        height: 200px; } }
    @media (min-width: 768px) {
      .page-404-image svg {
        height: 240px; } }
    @media (min-width: 992px) {
      .page-404-image svg {
        height: 280px; } }
    @media (min-width: 1200px) {
      .page-404-image svg {
        height: 320px; } }
    @media (min-width: 1400px) {
      .page-404-image svg {
        height: 400px; } }

.cookie-law-approval-content {
  vertical-align: top;
  display: inline-block;
  line-height: 54px; }
  .cookie-law-approval-content > a {
    text-decoration: none !important;
    color: #ed6e04 !important;
    font-weight: 700; }
    .cookie-law-approval-content > a:hover {
      text-decoration: underline !important;
      color: #323337 !important; }

.cookie-law-approval-close {
  display: inline-block;
  vertical-align: top;
  -webkit-border-radius: 0 !important;
  -moz-border-radius: 0 !important;
  -ms-border-radius: 0 !important;
  border-radius: 0 !important;
  border: none !important;
  font-size: 14px;
  line-height: 20px;
  padding: 16px 20px 14px 20px !important;
  text-transform: uppercase;
  -webkit-box-shadow: 4px 4px 0px 0px #dedfdf;
  -moz-box-shadow: 4px 4px 0px 0px #dedfdf;
  box-shadow: 4px 4px 0px 0px #dedfdf;
  position: relative;
  margin-top: 0;
  margin-bottom: 4px;
  margin-left: 0;
  margin-right: 4px;
  background-color: #ed6e04;
  color: #fff !important; }
  .cookie-law-approval-close:focus, .cookie-law-approval-close:hover {
    text-decoration: none;
    margin-top: 3px;
    margin-bottom: 1px;
    margin-left: 3px;
    margin-right: 1px;
    -webkit-box-shadow: 1px 1px 0px 0px #dedfdf;
    -moz-box-shadow: 1px 1px 0px 0px #dedfdf;
    box-shadow: 1px 1px 0px 0px #dedfdf;
    background-color: #d46204 !important; }

.cookie-law-details {
  display: inline-block;
  vertical-align: top;
  -webkit-border-radius: 0 !important;
  -moz-border-radius: 0 !important;
  -ms-border-radius: 0 !important;
  border-radius: 0 !important;
  border: none !important;
  font-size: 14px;
  line-height: 20px;
  padding: 16px 20px 14px 20px !important;
  text-transform: uppercase;
  -webkit-box-shadow: 4px 4px 0px 0px #dedfdf;
  -moz-box-shadow: 4px 4px 0px 0px #dedfdf;
  box-shadow: 4px 4px 0px 0px #dedfdf;
  position: relative;
  margin-top: 0;
  margin-bottom: 4px;
  margin-left: 0;
  margin-right: 9px;
  background-color: #646569;
  color: #fff !important; }
  .cookie-law-details:focus, .cookie-law-details:hover {
    text-decoration: none;
    margin-top: 3px;
    margin-bottom: 1px;
    margin-left: 4px;
    margin-right: 6px;
    -webkit-box-shadow: 1px 1px 0px 0px #dedfdf;
    -moz-box-shadow: 1px 1px 0px 0px #dedfdf;
    box-shadow: 1px 1px 0px 0px #dedfdf;
    background-color: #58585c !important; }
