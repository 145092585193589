@import "_mixins/pux/breakpoints.scss";
@import "_mixins/pux/css-rules.scss";
@import "_mixins/pux/scale.scss";
@import "_variables.scss";

.parallax-widget {
    &-wrapper {
        position: relative;
        min-height: 600px;
        background-position: bottom center !important;
        background-repeat: no-repeat;

        @include media(xxs, xs) {
            background-size: auto 260px !important;
        }

        @include media(sm) {
            background-size: 100% auto !important;
        }

        @include bs-grid(0px);

        .text-layer {
            background-color: $light-grey;
            padding: 20px;
            text-align: center;
            margin: 0 -15px 0 -15px;

            h2 {
                font-weight: 300;

                strong {
                    font-weight: 700;
                }
            }

            p,
            .perex {
                margin-bottom: 20px;
            }

            &-description {
                @include pux-scale("margin-bottom", 40px, 20px);
            }

            @include media(md) {
                padding: 40px;
                margin: -20px 0px 0px 0px;
            }

            @include media(lg) {
                margin: -40px 0 40px 0px;
            }

            @include media(xl) {
                padding: 80px;
            }
        }

        .video-btn {
            display: block;
            margin: 100px auto;
            width: 60px;
            height: 60px;
            line-height: 64px;
            border-radius: 50%;
            background-color: rgba(129, 127, 126, 0.8);
            text-align: center;
            overflow: hidden;

            &:before {
                display: inline-block;
                content: "\e901";
                color: $base-white;
                margin-left: 4px;
                @include transition();
            }

            &:hover {
                background-color: rgba(129, 127, 126, 1);

                &:before {
                    @include scale(1.3, 1.3);
                }
            }

            @include media(lg) {
                position: absolute;
                top: 50%;
                left: 50%;
                @include transform(translate(-50%, -50%));
                margin: 0;
            }
        }

        &.tickets {
            margin-left: -15px;
            margin-right: -15px;
            @include bs-grid(20px);
            background-position: center center !important;

            @include media(md) {
                margin-left: 0px;
                margin-right: 0px;
            }

            .ticket-item {
                background-color: $main-color;
                padding: 20px;
                text-align: center;
                color: $base-white;

                &-icon {
                    width: 100px;
                    height: 100px;
                    margin: 0 auto;

                    .cls-1 {
                        fill: $base-white;
                    }
                }

                &-price {
                    font-size: 45px;
                    line-height: 55px;
                    font-weight: 700;
                    padding-top: 20px;
                    @include pux-scale("font-size", 45px, 24px);
                    @include pux-scale("line-height", 55px, 30px);
                    @include pux-scale("margin-bottom", 30px, 20px);

                    > div {
                        @include pux-scale("font-size", 22px, 16px);
                        @include pux-scale("line-height", 35px, 24px);
                        font-weight: 400;
                        padding-top: 20px;
                    }
                }

                &-text {
                    @include pux-scale("font-size", 22px, 16px);
                    @include pux-scale("line-height", 35px, 24px);
                    margin-bottom: 40px;
                }

                &-btn {
                    min-height: 74px;
                }

                @include media(sm) {
                    padding: 30px;
                }

                @include media(md) {
                    padding: 40px;
                    margin-top: -20px;
                    margin-bottom: 20px;
                }

                @include media(xl) {
                    background-color: rgba(237, 110, 4, 0.8);
                    padding: 60px;
                    margin-top: -40px;
                    margin-bottom: 40px;
                }
            }

            .row {
                > [class*="col-"]:first-of-type {
                    .ticket-item {
                        margin-bottom: 240px;

                        @include media(md) {
                            margin-bottom: 20px;
                        }

                        @include media(xl) {
                            margin-bottom: 40px;
                        }
                    }
                }

                > [class*="col-"]:last-of-type {
                    .ticket-item {
                        @include media(md) {
                            margin-top: 0px;
                        }

                        @include media(lg) {
                            margin-top: -20px;
                        }

                        @include media(xl) {
                            margin-top: -40px;
                        }
                    }
                }
            }

            @include media(md) {
                background-size: cover !important;
            }
        }
    }
}

.parallax-slider-widget-wrapper {
    position: relative;

    @include media(lg) {
        min-height: 600px;
    }
}

.parallax-slider-background {
    position: absolute;
    height: 280px;
    left: 0px;
    right: 0px;
    bottom: 0px;

    @include media(md) {
        height: auto;
        top: 0px;
    }

    .slick-list,
    .slick-track,
    .slick-slide {
        height: 100%;
    }

    .slick-slide {
        background-position: center center;
        background-size: cover;
        background-repeat: no-repeat;
    }
}

.parallax-slider {
    @include pux-scale("margin-bottom", 30px, 15px);

    &-wrapper {
        background-color: $light-grey;
        padding: 20px;
        margin: 0 -15px 240px -15px;
        position: relative;
        padding-bottom: 60px;

        @include media(md) {
            padding: 40px;
            margin: -20px 0px 20px 0px;
        }

        @include media(lg) {
            margin: -40px 0 40px 0px;
        }

        @include media(xl) {
            padding: 80px;
        }
    }

    h3 {
        @include pux-scale("font-size", 55px, 24px);
        @include pux-scale("margin-bottom", 30px, 20px);
        font-weight: 400;
    }

    &-controls {
        font-size: 0px;
        text-align: center;
    }

    &-arrow {
        display: inline-block;
        background-color: rgba(129, 127, 126, 0.8);
        color: $base-white;
        width: 40px;
        height: 40px;
        text-align: center;
        line-height: 42px;
        border-radius: 50%;
        font-size: 14px;
        overflow: hidden;
        margin: 0 10px;

        &:hover {
            background-color: rgba(129, 127, 126, 1);
            color: $base-white;
            text-decoration: none;
        }

        @include media(md) {
            width: 60px;
            height: 60px;
            line-height: 64px;
            font-size: 22px;
        }

        &.prev {
            &:before {
                content: "\e91a";
            }
        }

        &.next {
            &:before {
                content: "\e91b";
            }
        }
    }

    &-item {
        text-align: center;

        &:focus {
            outline: none;
        }
    }
}
