@import "_mixins/pux/breakpoints.scss";
@import "_mixins/pux/css-rules.scss";
@import "_mixins/pux/scale.scss";
@import "_variables.scss";

.seminar-program {
    &-items {
        position: relative;
        @include pux-scale("margin-bottom", 70px, 30px);

        .btn-more {
            position: absolute;
            bottom: 0px;
            left: 50%;
            @include transform(translate(-50%, 50%) rotateZ(45deg));
            z-index: 2;
            @include transition(350ms);

            &.collapsed {
                @include transform(translate(-50%, 50%) rotateZ(0deg));
            }
        }
    }

    &-collapse {
        z-index: 1;
    }

    &-item {
        @include flex-block();
        @include flex-flow(row, wrap);
        border-bottom: 1px solid $base-white;

        &-left {
            @include flex(100%);
            @include pux-scale("padding", 40px, 10px);
            display: inline-block;
            max-width: 100px;
            position: relative;
            background-color: $light-grey;
            border-right: 1px solid $base-white;

            @include media(lg) {
                max-width: 200px;
            }
        }

        &-right {
            @include flex(100%);
            @include pux-scale("padding", 40px, 10px);
            display: inline-block;
            background-color: $light-grey;
            max-width: calc(100% - 100px);

            @include media(lg) {
                max-width: calc(100% - 200px);
            }
        }

        &-date {
            @include pux-scale("font-size", 45px, 22px);
            line-height: 1;
            font-weight: 700;
            color: $color-line;
        }

        &-name {
            @include pux-scale("font-size", 22px, 18px);
            @include pux-scale("line-height", 30px, 22px);
            @include pux-scale("margin-bottom", 15px, 10px);
            color: $color-line;
            font-weight: 700;
            text-transform: uppercase;
        }

        &-description {
            @include pux-scale("font-size", 16px, 14px);
            @include pux-scale("line-height", 25px, 18px);
        }
    }
}

.lecturer {
    &-wrapper {
        @include pux-scale("padding-bottom", 120px, 40px);
    }

    &-items {
        @include bs-grid(0.5px);
        position: relative;

        .btn-more {
            position: absolute;
            bottom: 0px;
            left: 50%;
            @include transform(translate(-50%, 50%) rotateZ(45deg));
            z-index: 2;
            @include transition(350ms);

            &.collapsed {
                @include transform(translate(-50%, 50%) rotateZ(0deg));
            }
        }
    }

    &-collapse {
        z-index: 1;
    }

    &-item {
        $container-padding: $fluid-container-padding-left + $fluid-container-padding-right;
        $container-padding-md: $fluid-container-padding-left-md + $fluid-container-padding-right-md;
        $container-padding-xl: $fluid-container-padding-left-xl + $fluid-container-padding-right-xl;

        @include calc-variable(height, 100vw, $container-padding, "-");
        border-bottom: 1px solid $base-white;
        position: relative;
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;

        &-inner {
            position: absolute;
            bottom: 0;
            left: 0;
            height: 40%;
            width: 100%;
            @include transition(350ms);
            display: table;
            vertical-align: middle;
            background-color: rgba(237, 110, 4, 0.8);

            @include media(sm) {
                background-color: rgba(237, 110, 4, 0);
                height: 100%;
            }

            &:hover {
                background-color: rgba(237, 110, 4, 0.8);
            }
        }

        &-text {
            display: table-cell;
            vertical-align: middle;
            text-align: center;
            color: $base-white;
            height: 100%;
            width: 100%;
            padding: 0 15px;
        }

        &-name {
            display: inline-block;
            text-transform: uppercase;
            font-size: 18px;
            line-height: 30px;
            font-weight: 700;

            @include media(sm) {
                font-size: 22px;
                opacity: 0;
                @include transform(translate(0, 30px));
                @include transition-full(all 300ms ease-out);
            }
        }

        &-description {
            display: inline-block;
            width: 100%;
            font-size: 14px;

            @include media(sm) {
                font-size: 16px;
                opacity: 0;
                @include transform(translate(0, 24px));
                @include transition-full(all 600ms ease-out);
            }
        }

        &:hover {
            @include media(sm) {
                .lecturer-item-name {
                    opacity: 1;
                    @include transform(translate(0, 0px));
                }

                .lecturer-item-description {
                    opacity: 1;
                    @include transform(translate(0, 0px));
                }
            }

        }

        @include media(sm) {
            @include calc-variable(height, 50vw, ($container-padding/2)+0.5px, "-");
        }

        @include media(md) {
            @include calc-variable(height, 50vw, ($container-padding-md/2)+0.5px, "-");
        }

        /*@include media(lg) {
            @include calc-variable(height, 33.333333vw, ($container-padding-md/2)+1px, "-");
        }*/
        @include media(xl) {
            @include calc-variable(height, 25vw, ($container-padding-xl/2)+3px, "-");
        }

        @media (min-width: 1600px) {
            height: ($container-max-width - $container-padding-xl - (3px)) / 4;
        }
    }
}

.tickets-wrapper {
    border-top: 1px solid $light-grey;

    @include media(xxs, md) {
        overflow-x: hidden;
    }

    .text-block {
        h2 {
            font-weight: 700;
        }
    }
}
