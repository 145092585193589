@import "breakpoints.scss";
@import "css-rules.scss";
@import "scale.scss";
@import "../../_variables.scss";

$slider-header-height: 70px;
$slider-content-center: false;
$slider-color: #fff;
$slider-max-width: 1000px;
$slider-full-window: false;
$slider-height: 720px;

$slider-arrow: true;
$slider-arrow-width: 40px;
$slider-arrow-height: $slider-arrow-width;
$slider-arrow-background: $main-color;
$slider-arrow-radius: 50%;
$slider-arrow-border: none;
$slider-arrow-left: 20px;
$slider-arrow-right: 20px;
$slider-arrow-before-size: 20px;
$slider-arrow-prev-code: "\9057";
$slider-arrow-next-code: "\9053";

$slider-dot: true;
$slider-dot-width: 40px;
$slider-dot-height: 4px;
$slider-dot-background: $base-white;
$slider-dot-active: $main-color;
$slider-dot-border: none;
$slider-dot-radius: 0;
$slider-dot-padding: 0 20px 0 0;
$slider-dot-bottom: 30px;
$slider-dot-transition-duration: 350ms;


@mixin slick-arrows($arrow-width: $slider-arrow-width, $arrow-height: $slider-arrow-width, $arrow-background: $slider-arrow-background, $arrow-radius: $slider-arrow-radius, $arrow-border: $slider-arrow-border, $arrow-left: $slider-arrow-left, $arrow-right: $slider-arrow-right, $arrow-before-size: $slider-arrow-before-size, $arrow-prev-code: $slider-arrow-prev-code, $arrow-next-code: $slider-arrow-next-code) {
    .slick-arrow {
        position: absolute;
        top: 50%;
        margin-top: -($arrow-width/2);
        width: $arrow-width;
        height: $arrow-height;
        @include border-radius($arrow-radius);
        border: $arrow-border;
        font-size: 0;
        background: $arrow-background;
        outline: 0;
        z-index: 22;
        cursor: pointer;
        &:before {
            font-size: $arrow-before-size;
        }
        &.slick-prev {
            left: $arrow-left;
            &:before {
                content: $arrow-prev-code;
            }
        }
        &.slick-next {
            right: $arrow-right;
            &:before {
                content: $arrow-next-code;
            }
        }
    }
}

@mixin slick-arrows-size($arrow-width, $arrow-height) {
    .slick-arrow {
        width: $arrow-width;
        height: $arrow-height;
    }
}

@mixin slick-arrows-position($arrow-left, $arrow-right) {
    .slick-arrow {
        &.slick-prev {
            left: $arrow-left;
        }
        &.slick-next {
            right: $arrow-right;
        }
    }
}

@mixin slick-dost($dot-bottom: $slider-dot-bottom, $dot-padding: $slider-dot-padding, $dot-width: $slider-dot-width, $dot-height: $slider-dot-height, $dot-border: $slider-dot-border, $dot-radius: $slider-dot-radius, $dot-background: $slider-dot-background, $dot-active: $slider-dot-active, $dot-transition-duration: $slider-dot-transition-duration) {
    .slick-dots {
        @include flex-block;
        @include align-items(center);
        @include justify-content(center);
        position: absolute;
        left: 0;
        width: 100%;
        bottom: $dot-bottom;
        li {
            margin: 0;
            padding: $dot-padding;
            &:before {
                display: none;
            }
            &:last-child {
                padding: 0;
            }
            button {
                font-size: 0;
                outline: 0;
                border: 0;
                padding: $dot-width/2 0;
                background: transparent;
                @include border-radius($dot-radius);
                @include transition($dot-transition-duration);

                &:before {
                    content: '';
                    background: $dot-background;
                    display: block;
                    width: $dot-width;
                    height: $dot-height;
                    border: $dot-border;
                }
            }
            &.slick-active {
                button {
                    &:before {
                        background: $dot-active;
                    }
                }
            }
        }
    }
}

.slider-init {
    .main-slider {
        width: 100%;
        background-repeat: no-repeat !important;
        @include background-size(cover);
        @include flex-block;
        @include flex-direction(row);
        @include pux-scale-padding(50px, 0, 50px, 0, 20px);
        &-inner {
            width: 100%;
            @if $slider-full-window {
                @include calc("min-height", "100vh - "+ $slider-header-height);
            }
            @else {
                @include pux-scale("min-height", $slider-height, 200px);
            }
            @include flex-block;
            @include flex-direction(column);
            @include align-items(center);
            @include justify-content(center);
            &-content {
                max-width: $slider-max-width;
                margin: auto;
                @if $slider-content-center {
                    text-align: center;
                }
                &-text {
                    color: $slider-color;
                    h1,
                    h2 {
                        color: $slider-color;
                    }
                }
                &-btn {
                    padding: 20px 0 0 0;
                }
            }
            .pux-container {
                width: 100%;
            }
        }
        &.top-center {
            background-position: top center !important;
        }
        &.top-left {
            background-position: top left !important;
        }
        &.top-right {
            background-position: top right !important;
        }
        &.center-center {
            background-position: center center !important;
        }
        &.bottom-center {
            background-position: bottom center !important;
        }
        &.bottom-left {
            background-position: bottom left !important;
        }
        &.bottom-right {
            background-position: bottom right !important;
        }
    }
    @if $slider-arrow {
        @include slick-arrows;
    }
    @if $slider-dot {
        @include slick-dost;
    }
}
