@import "_mixins/pux/breakpoints.scss";
@import "_mixins/pux/css-rules.scss";
@import "_mixins/pux/scale.scss";
@import "_variables.scss";

.infobox {
    &-wrapper {
        @include pux-scale-padding(70px, 0, 120px, 0, 30px);
        text-align: center;

        h1 {
            max-width: 1200px;
            margin: 0 auto;
            text-transform: uppercase;
            @include pux-scale("margin-bottom", 30px, 20px);
        }

        h2 {
            max-width: 1200px;
            margin: 0 auto;
            font-weight: 300;
            margin-bottom: 20px;

            strong {
                font-weight: 700;
            }
        }

        .perex {
            max-width: 1200px;
            margin: 0 auto;
            @include pux-scale("margin-bottom", 40px, 20px);
        }
    }

    &-items {
        @include pux-scale("margin-bottom", 30px, 20px);

        .row {
            margin-left: -15px;
            margin-right: -15px;

            [class*="col-"] {
                padding-left: 15px;
                padding-right: 15px;
            }

            @include media(lg) {
                margin-left: -45px;
                margin-right: -45px;

                [class*="col-"] {
                    padding-left: 45px;
                    padding-right: 45px;
                }
            }
        }
    }

    &-item {
        @include pux-scale("margin-bottom", 45px, 20px);
        text-align: center;

        &.jobs {
            margin-bottom: 40px;
        }

        &-icon {
            margin-bottom: 10px;

            svg {
                height: 100px;
            }
        }

        &-name {
            text-transform: uppercase;
            font-size: 22px;
            line-height: 30px;
            font-weight: 700;
            margin-bottom: 10px;
            color: $color-line;

            a {
                @include hover;
            }
        }

        &-text {
            line-height: 25px;
        }

        &-link {
            padding-top: 10px;
            font-weight: 700;

            a {
                &:after {
                    position: relative;
                    content: "\e908";
                    font-size: 10px;
                    line-height: 25px;
                    margin-left: 0;
                    @include transition();
                }

                &:hover {
                    color: $second-color;
                    text-decoration: none;

                    &:after {
                        margin-left: 3px;
                        color: $main-color;
                    }
                }
            }
        }
    }
}
