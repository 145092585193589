@import "breakpoints.scss";
@import "css-rules.scss";
@import "scale.scss";
@import "../../_variables.scss";

$social-networks: ("facebook", "twitter", "linkedin", "youtube", "instagram", "pinterest", "google", "vimeo");

$social-colors: ( 'facebook': #3b5998,
'twitter': #00aced,
'youtube': #cc181e,
'google': #d34836,
'linkedin': #0d77b7,
'instagram': #8a3ab9,
'pinterest': #cd2029,
'vimeo':#45bbff);

$social-codes: ( 'facebook': "\e907",
'twitter': "\e90f",
'youtube': "\e911",
'google': "\e906",
'linkedin': "\e90d",
'instagram': "\e90c",
'pinterest': "\e90e",
'vimeo':"\e910");

$social-font-size: 21px;
$social-padding: 0 15px 15px 0;
$social-size: 30px;
$social-radius: 50%;
$social-background: $base-white;
$social-background-hover: $main-color;
$social-border: none;
$social-border-hover: none;
$social-color: $color-line;
$sosical-color-hover: #fff;
$social-bgc-theme: false;
$social-bgc-theme-hover: true;
$social-theme-hover: false;

@mixin social-ico($code, $font-size, $size) {
    &:before {
        content: $code;
        line-height: $size;
        font-size: $font-size;
    }
}

@mixin create-social($font-size: $social-font-size, $padding: $social-padding, $size: $social-size, $radius: $social-radius, $background: $social-background, $background-hover: $social-background-hover, $border: $social-border, $border-hover: $social-border-hover, $color: $social-color, $color-hover: $sosical-color-hover, $bgc-theme: $social-bgc-theme, $bgc-theme-hover: $social-bgc-theme-hover, $theme-hover: $social-theme-hover) {
    ul {
        @include flex-block;
        @include align-items(center);
        margin: 0;
        padding: 0;
        list-style: none;
        li {
            padding: $padding;
            &:before {
                display: none;
            }
            a {
                font-size: 0;
                display: block;
                text-decoration: none;
                width: $size;
                height: $size;
                @include border-radius($radius);
                background: $background;
                border: $border;
                color: $color;
                text-align: center;
                @include transition;
                &:focus,
                &:hover {
                    background: $background-hover;
                    color: $color-hover;
                    border: $border-hover;
                    text-decoration: none;
                }
            }
        }
    }
    @each $value in $social-networks {
        .#{$value} {
            @include social-ico(map-get($social-codes, $value), $font-size, $size);
            @if $bgc-theme {
                background: map-get($social-colors, $value);
                &:focus,
                &:hover {
                    background: darken(map-get($social-colors, $value), 10);
                }
            }
            @if $bgc-theme-hover {
                &:focus,
                &:hover {
                    background: map-get($social-colors, $value);
                }
            }
            @if $theme-hover {
                &:focus,
                &:hover {
                    color: map-get($social-colors, $value);
                    border-color: map-get($social-colors, $value);
                }
            }
        }
    }
}

.test-social {
    @include create-social(20px, 0 15px 15px 0, 30px, 50%, grey, blue, none, none, #fff, black, false, false, false);
}

.product-social {
    @include create-social(20px, 0 15px 15px 0, 40px, 50%, none, none, 1px solid grey, 1px solid black, grey, black, false, false, false);
}
