@import "_mixins/pux/breakpoints.scss";
@import "_mixins/pux/css-rules.scss";
@import "_mixins/pux/scale.scss";
@import "_variables.scss";

.single-image-widget,
.double-image-widget {
    &-wrapper {
        margin-left: -15px;
        margin-right: -15px;

        @include media(inf) {
            margin-left: -80px;
            margin-right: -80px;
        }
    }
}

.double-image-widget {
    &-wrapper {
        @include bs-grid(0);

        @include media(md) {
            @include bs-grid(15px);
        }

        @include media(inf) {
            @include bs-grid(20px);
        }
    }
}

.custom-layout {
    @include media(md) {
        @include bs-grid(15px);
    }

    @include media(inf) {
        @include bs-grid(20px);
    }
}

.embed-video {
    position: relative;

    &.small {
        height: 320px;
    }

    &.middle {
        height: 480px;
    }

    &.large {
        height: 600px;
    }

    iframe {
        height: 100%;
        width: 100%;
    }

    &-overflow {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background-position: center center;
        background-size: cover;
        background-repeat: no-repeat;
        opacity: 1;
        @include transitions(opacity 800ms linear 200ms, top 0ms linear 1000ms);

        &.played {
            opacity: 0;
            top: 100%;
        }
    }

    &-icon {
        display: block;
        margin: 100px auto;
        width: 60px;
        height: 60px;
        line-height: 64px;
        border-radius: 50%;
        background-color: rgba(129, 127, 126, 0.8);
        text-align: center;
        overflow: hidden;
        @include transition();

        &:before {
            display: inline-block;
            content: "\e901";
            color: $base-white;
            margin-left: 4px;
            @include transition();
        }

        &:hover {
            background-color: rgba(129, 127, 126, 1);

            &:before {
                @include scale(1.3, 1.3);
            }
        }

        @include media(lg) {
            position: absolute;
            top: 50%;
            left: 50%;
            @include transform(translate(-50%, -50%));
            margin: 0;
        }
    }
}


.zfp-collapse {
    &-container {
        position: relative;
        padding-bottom: 20px;
        margin-bottom: 20px;

        @include media(lg) {
            padding-bottom: 30px;
            margin-bottom: 30px;
        }

        .btn-more {
            position: absolute;
            bottom: 0px;
            left: 50%;
            @include transform(translate(-50%, 50%) rotateZ(45deg));
            z-index: 2;
            @include transition(350ms);

            &.collapsed {
                @include transform(translate(-50%, 50%) rotateZ(0deg));
            }
        }
    }

    &-header {
        margin-bottom: 10px;

        @include media(lg) {
            margin-bottom: 20px;
        }
    }

    &-content {
        &-inner {
            padding-bottom: 10px;

            @include media(lg) {
                padding-bottom: 20px;
            }
        }
    }
}
