@import "_mixins/pux/breakpoints.scss";
@import "_mixins/pux/css-rules.scss";
@import "_mixins/pux/scale.scss";
@import "_variables.scss";

.milestone {
    &-items {
        @include media(md) {
            margin: 0 60px;
        }

        .slick {
            &-list {
                z-index: 1;
                &:before {
                    content: '';
                    display: block;
                    width: 100%;
                    height: 1px;
                    background-color: $base-white;
                    position: absolute;
                    top: 50%;
                    left: 0px;
                }
            }
            &-arrow {
                display: block;
                background-color: rgba(129, 127, 126, 0.8);
                color: $base-white;
                width: 40px;
                height: 40px;
                text-align: center;
                border-radius: 50%;
                border: 0px;
                overflow: hidden;
                position: absolute;
                bottom:-5px;
                @include transform(translate(0, -50%));
                font-size: 0px;
                cursor: pointer;
                z-index: 2;

                @include media(md) {
                    top: 50%;
                    bottom: auto;
                }

                &:before {
                    font-size: 14px;
                    line-height: 40px;
                }

                &:hover {
                    background-color: rgba(129, 127, 126, 1);
                    color: $base-white;
                    text-decoration: none;
                }

                &:focus {
                    outline: none;
                }

                @include media(md) {
                    width: 60px;
                    height: 60px;

                    &:before {
                        font-size: 22px;
                        line-height: 60px;
                    }
                }

                &.slick-disabled {
                    background-color: rgba(129, 127, 126, 0.2);

                    &:hover {
                        background-color: rgba(129, 127, 126, 0.2);
                    }
                }
            }

            &-prev {
                left: 15px;
                @include media(md) {
                    left: -60px;
                }

                &:before {
                    content: "\e91a";
                }
            }

            &-next {
                right: 15px;

                @include media(md) {
                    right: -60px;
                }

                &:before {
                    content: "\e91b";
                }
            }
        }
    }

    &-item {
        height: 400px;
        position: relative;

        @include media(md) {
            height: 860px;
            margin: 0 120px;
        }

        &-inner {
            background-color: $base-white;
            height: 400px;
            opacity: 0;
            @include transitions(opacity 400ms linear, left 400ms linear 400ms, right 400ms linear 400ms);

            @include media(md) {
                position: absolute;
                top: 0px;
                left: 0px;
                right: 0px;
            }

            &:before {
                content: '';
                display: block;
                height: 7px;
                width: 7px;
                background-color: $main-color;
                border-radius: 50%;
                position: absolute;
                top: 427px;
                left: 57px;
            }

            &:after {
                content: '';
                display: block;
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 20px 20px 0 20px;
                border-color: $base-white transparent transparent transparent;
                position: absolute;
                top: 400px;
                left: 40px;
            }
        }

        &-teaser {
            height: 165px;
            background-size: cover;
            background-position: center center;
            background-repeat: no-repeat;

            @include media(md) {
                height: 200px;
            }
        }

        &-info {
            padding: 15px;

            @include media(md) {
                @include pux-scale-padding(30px, 40px, 30px, 40px, 15px);
            }
        }

        &-name {
            @include pux-scale("font-size", 22px, 16px);
            @include pux-scale("line-height", 30px, 20px);
            @include pux-scale("margin-bottom", 20px, 10px);
            font-weight: 700;
            color: $color-line;
        }

        &-text {
            min-height: 90px;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        &:nth-of-type(even) {
            .milestone-item-inner {
                @include media(md) {
                    top: 460px;
                }

                &:before {
                    top: -33px;
                }

                &:after {
                    top: -20px;
                    border-width: 0 20px 20px 20px;
                    border-color: transparent transparent $base-white transparent;
                }
            }
        }

        &.slick-active {
            .milestone-item-inner {
                @include transitions(opacity 400ms linear 400ms, left 400ms linear, right 400ms linear);
                opacity: 1;
            }
        }

        @include media(md) {
            &.slick-current {
                .milestone-item-inner {
                    left: -60px;
                    right: -200px;
                }

                & + div {
                    .milestone-item-inner {
                        left: -130px;
                        right: -130px;
                    }

                    & + div {
                        .milestone-item-inner {
                            left: -200px;
                            right: -60px;
                        }
                    }
                }
            }
        }
    }
}
