@import "_mixins/pux/breakpoints.scss";
@import "_mixins/pux/css-rules.scss";
@import "_mixins/pux/scale.scss";
@import "_variables.scss";

.testimonial {
    &-wrapper {
        @include pux-scale-padding(70px, 0, 55px, 0, 30px);

        h2 {
            text-align: center;
            @include pux-scale("margin-bottom", 60px, 20px);
        }
    }

    &-items {
        .row {
            margin-left: -15px;
            margin-right: -15px;

            [class*="col-"] {
                padding-left: 15px;
                padding-right: 15px;
            }
        }

        &:not(.references) {
            .row {
                margin-left: -15px;
                margin-right: -15px;

                /*[class*="col-"] {
                    @include media(xl) {
                        &:nth-of-type(4) {
                            display: none;
                        }
                    }
                }*/
            }
        }

        &.references {
            position: relative;
            padding-bottom: 45px;

            .btn-more {
                position: absolute;
                bottom: 0px;
                left: 50%;
                @include transform(translate(-50%, 50%) rotateZ(45deg));
                z-index: 2;
                @include transition(350ms);

                &.collapsed {
                    @include transform(translate(-50%, 50%) rotateZ(0deg));
                }
            }
        }
    }

    &-item {
        font-size: 0px;
        position: relative;
        background-color: $light-grey;
        padding: 17px 20px;
        margin-bottom: 45px;

        @include media(lg) {
            padding: 37px 40px;
        }

        q {
            display: block;
            margin-bottom: 20px;
            font-size: 16px;
            line-height: 25px;
            min-height: 75px;

            &:before {
                margin-right: 3px;
                font-size: 16px;
            }

            &:after {
                font-size: 16px;
            }
        }

        &-info {
            font-size: 16px;
            display: inline-block;
            vertical-align: top;
            width: 100%;
            margin-bottom: -10px;

            @include media(lg) {
                padding-right: 160px;
                margin-right: -140px;
                margin-bottom: 0px;
            }

            strong {
                display: block;
            }
        }

        &-rate {
            padding-top: 20px;
            line-height: 20px;

            span {
                display: inline-block;
                margin-right: 4px;
                font-size: 20px;
                line-height: 20px;

                &:before {
                    content: "\e90b";
                    color: $star-bg;
                }

                &:nth-of-type(1) {
                    &:before {
                        @include transition-full(color 50ms linear 0ms);
                    }
                }

                &:nth-of-type(2) {
                    &:before {
                        @include transition-full(color 50ms linear 300ms);
                    }
                }

                &:nth-of-type(3) {
                    &:before {
                        @include transition-full(color 50ms linear 600ms);
                    }
                }

                &:nth-of-type(4) {
                    &:before {
                        @include transition-full(color 50ms linear 900ms);
                    }
                }

                &:nth-of-type(5) {
                    &:before {
                        @include transition-full(color 50ms linear 1200ms);
                    }
                }
            }

            &.activate {
                &.count-1 {
                    span {
                        &:nth-of-type(1) {
                            &:before {
                                color: $main-color
                            }
                        }
                    }
                }

                &.count-2 {
                    span {
                        &:nth-of-type(1),
                        &:nth-of-type(2) {
                            &:before {
                                color: $main-color
                            }
                        }
                    }
                }

                &.count-3 {
                    span {
                        &:nth-of-type(1),
                        &:nth-of-type(2),
                        &:nth-of-type(3) {
                            &:before {
                                color: $main-color
                            }
                        }
                    }
                }

                &.count-4 {
                    span {
                        &:nth-of-type(1),
                        &:nth-of-type(2),
                        &:nth-of-type(3),
                        &:nth-of-type(4) {
                            &:before {
                                color: $main-color
                            }
                        }
                    }
                }

                &.count-5 {
                    span {
                        &:before {
                            color: $main-color
                        }
                    }
                }
            }
        }

        &-photo {
            display: inline-block;
            vertical-align: top;
            width: 100%;
            text-align: right;
            margin-bottom: -37px;

            @include media(lg) {
                padding-top: 10px;
                width: 140px;
                /*margin-bottom: -57px;*/
            }

            img {
                width: 100px;
                height: 100px !important;
                border-radius: 50%;

                @include media(lg) {
                    position: absolute;
                    bottom: -20px;
                    right: 40px;
                    width: 140px;
                    height: 140px !important;
                }
            }
        }

        &:after {
            content: '';
            display: block;
            position: absolute;
            bottom: -20px;
            left: 40px;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 20px 20px 0 20px;
            border-color: $light-grey transparent transparent transparent;
        }

        &.no-rate {
            .testimonial-item-info {
                padding-bottom: 43px;
            }
        }
    }
}
