@import "_mixins/pux/breakpoints.scss";
@import "_mixins/pux/css-rules.scss";
@import "_variables.scss";

/* plugins */

@import "selectric.scss";

/* modules */

@import "_mixins/pux/pux-social.scss";
@import "_mixins/pux/pux-slider.scss";

@import "_variables.scss";
@import "zfpacademy-layout.scss";
@import "zfpacademy-typography.scss";
@import "zfpacademy-form.scss";
@import "zfpacademy-infobox.scss";
@import "zfpacademy-parallax.scss";
@import "zfpacademy-testimonial.scss";
@import "zfpacademy-countup.scss";
@import "zfpacademy-education.scss";
@import "zfpacademy-blog.scss";
@import "zfpacademy-milestones.scss";
@import "zfpacademy-widgets.scss";
@import "zfpacademy-page.scss";
