@import "_mixins/pux/breakpoints.scss";
@import "_mixins/pux/css-rules.scss";
@import "_mixins/pux/scale.scss";
@import "_mixins/pux/pux-social.scss";
@import "_variables.scss";

.pux-container {
    margin-left: auto;
    margin-right: auto;
    padding-left: $container-padding-left;
    padding-right: $container-padding-right;
    max-width: $container-max-width;
    &:after {
        display: block;
        content: "";
        clear: both;
    }
    @include media(md) {
        padding-left: $container-padding-left-md;
        padding-right: $container-padding-right-md;
    }
    @include media(xl) {
        padding-left: $fluid-container-padding-left-xl;
        padding-right: $fluid-container-padding-right-xl;
    }
}

.pux-container {
    .row {
        @include flex-block;
        @include flex-wrap(wrap);
        margin-left: $row-margin-left;
        margin-right: $row-margin-right;
        @include media(md) {
            margin-left: $row-margin-left-md;
            margin-right: $row-margin-right-md;
        }
    }
}

.text-container {
    max-width: 1040px;
    margin-left: auto;
    margin-right: auto;
    @include clearfix;

    iframe {
        display: block;
        margin: 0 auto;
        max-width: 100% !important;

        @include media(xxs, lg) {
            max-height: 480px;
        }
    }
}

.pux-container {
    .col-1,
    .col-2,
    .col-3,
    .col-4,
    .col-5,
    .col-6,
    .col-7,
    .col-8,
    .col-9,
    .col-10,
    .col-11,
    .col-12,
    .col,
    .col-xs-1,
    .col-xs-2,
    .col-xs-3,
    .col-xs-4,
    .col-xs-5,
    .col-xs-6,
    .col-xs-7,
    .col-xs-8,
    .col-xs-9,
    .col-xs-10,
    .col-xs-11,
    .col-xs-12,
    .col-auto,
    .col-sm-1,
    .col-sm-2,
    .col-sm-3,
    .col-sm-4,
    .col-sm-5,
    .col-sm-6,
    .col-sm-7,
    .col-sm-8,
    .col-sm-9,
    .col-sm-10,
    .col-sm-11,
    .col-sm-12,
    .col-sm,
    .col-sm-auto,
    .col-md-1,
    .col-md-2,
    .col-md-3,
    .col-md-4,
    .col-md-5,
    .col-md-6,
    .col-md-7,
    .col-md-8,
    .col-md-9,
    .col-md-10,
    .col-md-11,
    .col-md-12,
    .col-md,
    .col-md-auto,
    .col-lg-1,
    .col-lg-2,
    .col-lg-3,
    .col-lg-4,
    .col-lg-5,
    .col-lg-6,
    .col-lg-7,
    .col-lg-8,
    .col-lg-9,
    .col-lg-10,
    .col-lg-11,
    .col-lg-12,
    .col-lg,
    .col-lg-auto,
    .col-xl-1,
    .col-xl-2,
    .col-xl-3,
    .col-xl-4,
    .col-xl-5,
    .col-xl-6,
    .col-xl-7,
    .col-xl-8,
    .col-xl-9,
    .col-xl-10,
    .col-xl-11,
    .col-xl-12,
    .col-xl,
    .col-xl-auto {
        padding-left: $col-padding-left;
        padding-right: $col-padding-right;
        @include media(md) {
            padding-left: $col-padding-left-md;
            padding-right: $col-padding-right-md;
        }
    }
}

.pux-fluid-container {
    padding-left: $fluid-container-padding-left;
    padding-righ: $fluid-container-padding-right;
    @include media(md) {
        padding-left: $fluid-container-padding-left-md;
        padding-righ: $fluid-container-padding-right-md;
    }
    @include media(xl) {
        padding-left: $fluid-container-padding-left-xl;
        padding-righ: $fluid-container-padding-right-xl;
    }
}

header {
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    background-color: $base-white;
    box-shadow: none;
    z-index: 999;

    &:after {
        content: '';
        position: absolute;
        top: 100%;
        left: 0;
        right: 0;
        display: block;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 10px 100vw 0 0;
        border-color: $base-white transparent transparent transparent;
        @include transition(500ms);

        @include media(lg) {
            border-width: 20px 100vw 0 0;
        }

        @include media(xl) {
            border-width: 40px 100vw 0 0;
        }
    }

    .main-navbar {
        .navbar {
            &-brand {
                margin-right: 0;
                margin-bottom: 10px;
                padding-top: 10px;
                padding-bottom: 0;
                @include transition(350ms);

                img {
                    width: 94px;
                    height: 45px !important;
                    position: relative;
                    z-index: 1;
                    @include transition(500ms);

                }

                @include media(xl) {
                    margin-bottom: -7px;
                    padding-top: 15px;

                    img {
                        height: 72px !important;
                    }
                }
            }

            &-toggler {
                display: inline-block;
                vertical-align: middle;
                border: 0;
                padding: 0;
                margin: 0px 0px;
                height: 22px;
                width: 32px;
                position: relative;
                .icon-bar {
                    display: block;
                    background-color: $main-color;
                    width: 32px;
                    height: 4px;
                    border-radius: 2px;
                    @include transition(350ms);
                    position: absolute;
                    left: 0;
                    top: 0;
                    &:nth-of-type(2) {
                        top: 9px;
                    }
                    &:nth-of-type(3) {
                        top: 18px;
                    }
                }

                &:not(.collapsed) {
                    .icon-bar {
                        border-radius: 1px;

                        &:nth-of-type(1) {
                            @include transform(rotateZ(45deg));
                            top: 9px;
                        }

                        &:nth-of-type(2) {
                            width: 0px;
                            opacity: 0;
                        }

                        &:nth-of-type(3) {
                            @include transform(rotateZ(-45deg));
                            top: 9px;
                        }
                    }
                }

                &:focus {
                    outline: 0;
                    box-shadow: none;
                    border: 0;
                }

                @include media(xl) {
                    display: none;
                }
            }

            &-collapse {
                width: 100%;

                @include media(xl) {
                    display: block;
                    width: auto;
                }

                &.main-menu {
                    > ul {
                        padding: 5px 0px 15px 0px;
                        margin: 0;
                        font-size: 0;
                        @include transition(350ms);

                        @include media(xl) {
                            padding: 40px 0 7px 0;
                        }

                        > li {
                            display: block;
                            text-align: right;
                            margin-bottom: 8px;

                            @include media(xl) {
                                display: inline-block;
                                margin-bottom: 0px;
                                padding-right: 35px;
                            }

                            @include media(xxl) {
                                padding-right: 70px;
                            }

                            a {
                                font-size: 16px;
                                text-transform: uppercase;
                                display: inline-block;
                                position: relative;
                                padding: 0 4px;
                                color: $color-line;

                                @include media(xxl) {
                                    font-size: 17px;
                                    padding-bottom: 8px;
                                }

                                &:after {
                                    content: '';
                                    display: block;
                                    position: absolute;
                                    bottom: 0;
                                    left: 0;
                                    right: 100%;
                                    border-bottom: 2px solid transparent;
                                    @include transition(350ms);
                                }

                                &:hover {
                                    text-decoration: none;

                                    &:after {
                                        right: 0;
                                        border-color: $main-color;
                                    }
                                }
                            }

                            &.Highlighted {
                                a {
                                    &:after {
                                        right: 0;
                                        border-color: $main-color;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        .header {
            &-controls {
                padding: 18px 0 17px 0;
                @include transition(350ms);

                @include media(xl) {
                    padding: 35px 0 10px 0;
                }
            }

            &-btn {
                color: $color-link;
                display: inline-block;
                vertical-align: middle;


                &:before {
                    width: 30px;
                    line-height: 30px;
                    display: block;
                    font-size: 21px;
                    text-align: center;
                    @include transition();
                    @include transform-origin(50% 50%);
                }

                &.login {
                    margin-right: 15px;

                    @include media(xl) {
                        margin-right: 30px;
                    }

                    &:before {
                        content: "\e905";
                    }
                }

                &.search {
                    margin-right: 15px;

                    @include media(xl) {
                        margin-right: 30px;
                    }

                    &:before {
                        content: "\e904";
                    }
                }

                &.language {
                    position: relative;
                    margin-right: 15px;
                    
                    @include media(xl) {
                        margin-right: 0px;
                    }

                    &:hover {
                        .header-language-inner {
                            display: block;
                            padding: 10px 10px 0;
                        }
                    }
                    
                    .header-language-current,
                    .header-language-link {
                        display: block;
                        font-size: 0;
                        width: 30px;
                        height: 30px;
                        background-position: center;
                        background-repeat: no-repeat;
                        background-size: cover;
                        // cursor: pointer;
                        
                        &.active {
                            display: none;
                        }
                        
                        &.cs-cz {
                            background-image: url("../img/cz-flag.svg");
                        }
                        
                        &.sk-sk {
                            background-image: url("../img/sk-flag.svg");
                        }

                    }

                    .header-language-current {
                        position: relative;
                        z-index: 2;                    
                    }

                    .header-language-link {
                        display: block;
                        margin: 10px 0;

                        &.cs-cz,
                        &.sk-sk {
                            transition: transform 300ms;
                            &:hover {
                                transform: scale(1.25);
                            }
                        }
                    }
                    
                    .header-language-inner {
                        position: absolute;
                        display: none;
                        right: -10px;
                        top: -10px;
                        font-size: 0;
                        background-color: $base-white;
                        z-index: 1;
                        padding: 10px 0 0;
                        box-shadow: 0px 3px 7px 0px rgba(0,0,0,0.1);
                        transition: padding 300ms;
                        
                        ul {
                            padding: 40px 0 0;
                            margin: 0;
                            list-style: none;
                            li {
                                padding: 0;
                                margin: 0;
                                
                            }
                        }
                    }
                }

                &:hover,
                &:focus {
                    text-decoration: none;

                    @include media(lg) {
                        &:before {
                            @include scale(1.33, 1.33);
                        }
                    }
                }
            }
        }
    }

    &.scrolled {
        @include box-shadow((0px 3px 7px 0px rgba(0,
        0,
        0,
        0.1)));

        &:after {
            border-width: 0px;
        }

        .main-navbar {
            .navbar {
                &-brand {
                    padding-top: 10px;
                    margin-bottom: 10px;

                    img {
                        height: 45px !important;
                    }
                }

                &-collapse {
                    &.main-menu {
                        > ul {
                            padding: 5px 0 15px 0;

                            @include media(xl) {
                                padding: 23px 0 17px 0;
                            }
                        }
                    }
                }
            }

            .header-controls {
                padding: 18px 0 17px 0;
            }
        }
    }
}

main {
    padding-top: 65px;

    @include media(xl) {
        padding-top: 80px;
    }
}

footer {
    background-color: $color-line;
    color: $base-white;
    padding-bottom: 15px;

    @include media(xl) {
        padding-bottom: 50px;
    }

    .footer {
        &-list {
            ul {
                margin: 0;
                padding: 25px 0 20px 0;
                font-size: 0;
                text-align: center;

                @include media(xl) {
                    padding: 60px 0 40px 0;
                }

                li {
                    display: inline-block;
                    padding: 0 30px;
                    margin-bottom: 10px;

                    @include media(xl) {
                        margin-bottom: 0;
                    }

                    a {
                        font-size: 17px;
                        color: $base-white;
                        text-transform: uppercase;
                        display: inline-block;
                        position: relative;
                        padding: 0 4px;

                        &:after {
                            content: '';
                            display: block;
                            position: absolute;
                            bottom: 0;
                            left: 0;
                            right: 100%;
                            border-bottom: 2px solid transparent;
                            @include transition(350ms);
                        }

                        &:hover {
                            text-decoration: none;

                            &:after {
                                right: 0;
                                border-color: $base-white;
                            }
                        }
                    }
                }
            }
        }

        &-copy {
            font-size: 13px;
            line-height: 20px;
            text-align: center;
            margin-bottom: 30px;

            @include media(md) {
                text-align: left;
            }

            @include media(xl) {
                margin-bottom: 0px;
            }
        }

        &-social {
            text-align: center;
            @include create-social();

            @include media(xl) {
                text-align: left;
            }

            ul {
                width: 100%;
                display: block;

                @include media(xl) {
                    float: right;
                    width: auto;
                }

                li {
                    display: inline-block;
                    vertical-align: middle;

                    &:last-of-type {
                        padding-right: 0px;
                    }

                    .social-ico {
                        &.twitter {
                            &:before {
                                font-size: 14px;
                            }
                        }

                        &.linkedin {
                            &:before {
                                font-size: 17px;
                            }
                        }

                        &.youtube {
                            &:before {
                                font-size: 12px;
                            }
                        }
                    }
                }
            }
        }
    }
}

.EditMode,
.DesignMode {
    header {
        position: static;

        &:after {
            display: none;
        }
    }

    main {
        padding-top: 0px;
    }
}

#CMSHeaderDiv {
    display: none;
}

.is-xxl {
    display: none;
    @include media(xxl) {
        display: block;
    }
}
