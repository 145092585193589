
$px-values: (5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70, 75, 80, 85, 90, 95, 100);

// bootstrap
$main-left: 15px;
$main-right: 15px;
$main-left-md: 45px;
$main-right-md: 45px;

// container
$container-max-width: 1600px;
$container-padding-left: $main-left;
$container-padding-right: $main-right;
$container-padding-left-md: $main-left-md;
$container-padding-right-md: $main-right-md;

// fluid container
$fluid-container-padding-left: $main-left;
$fluid-container-padding-right: $main-right;
$fluid-container-padding-left-md: $main-left-md;
$fluid-container-padding-right-md: $main-right-md;
$fluid-container-padding-left-xl: 80px;
$fluid-container-padding-right-xl: 80px;


// row
$row-margin-left: (-$main-left);
$row-margin-right: (-$main-right);
$row-margin-left-md: (-$main-left-md);
$row-margin-right-md: (-$main-right-md);

// col
$col-padding-left: $main-left;
$col-padding-right: $main-right;
$col-padding-left-md: $main-left-md;
$col-padding-right-md: $main-right-md;

// typography
$font-family: 'Fedra', sans-serif;
$font-size: 16px;

// perex
$font-size-perex: 22px;
$min-font-size-perex: 18px;
$line-height-perex: 35px;

// small text
$font-size-small: 16px;
$min-font-size-small: 14px;
$line-height-small: 25px;

// main colors
$color-link: #ed6e04;
$main-color: #ed6e04;
$second-color: #323337;
$color-line: #646569;
$table: #e4e4e4;
$base-black: #000;
$base-white: #fff;
$light-grey: #f2f2f2;
$button-shadow: #dedfdf;
$star-bg: #939396;

// button primary
$btn-primary-background: $main-color;
$btn-primary-color: $base-white;

// button default
$btn-default-background: $base-white;
$btn-default-color: $main-color;

// ul, li
$ul-bullet: $main-color;
$ol-number: $color-line;
$ol-font-family: $font-family;

// table
$table-even-background: #ebebeb;
$table-head-border: $second-color;
$table-tr-border: #e4e4e4;

// form
$color-placeholder: $color-line;

// inputs
$border-color: #f1f1f1;
$input-background: #f1f1f1;
$focus-border-color: $main-color;
$input-height: 60px;
$textarea-height: $input-height * 3;

// upload
$upload-background: $main-color;
$upload-color: $base-white;
$file-name-background: $light-grey;
$file-name-color: $base-black;

// form radio
$radio-background: #eeeff4;
$radio-border: #eeeff4;
$radio-active-border: $main-color;
$radio-after-background: $main-color;

// form checkbox
$checkbox-background: #eeeff4;
$checkbox-border: #eeeff4;
$checkbox-active-border: $main-color;
$checkbox-after-background: $main-color;

// form error
$error-input-border: #d40511;
$error-label-color: #d40511;
$error-blink-start: #fff;
$error-blink-final: #fec7ca;
$use-blink-error: 1;

// scale

$scale-xxl: 1;
$scale-xl: 0.8;
$scale-lg: 0.7;
$scale-md: 0.6;
$scale-sm: 0.5;
$scale-xs: 0.4;
$scale-xxs: 0.4;

$min-font-size: 14px;
$min-padding: 10px;
$min-margin: 10px;
