@import "_mixins/pux/breakpoints.scss";
@import "_mixins/pux/css-rules.scss";
@import "_mixins/pux/scale.scss";
@import "_variables.scss";


.count-up {
    &-wrapper {
        @include pux-scale("padding-top", 60px, 20px);
        padding-bottom: 20px;

        .row {
            margin-left: -15px;
            margin-right: -15px;

            [class*="col-"] {
                padding-left: 15px;
                padding-right: 15px;
            }
        }
    }

    &-item {
        @include pux-scale("margin-bottom", 30px, 20px);
        text-align: center;

        &-ico {
            &:before {
                font-size: 70px;
            }

            &.ico-1 {
                &:before {
                    content: "\e915";
                }
            }

            &.ico-2 {
                &:before {
                    content: "\e916";
                }
            }

            &.ico-3 {
                &:before {
                    content: "\e918";
                }
            }

            &.ico-4 {
                &:before {
                    content: "\e917";
                }
            }
        }

        &-number {
            font-size: 60px;
            line-height: 60px;
            font-weight: 700;

            &:after {
                font-family: $font-family !important;
                content: '+';
                font-size: 32px;
                display: inline-block;
                vertical-align: 22px;
                margin-top: -8px;
                font-weight: 700;
            }
        }

        &-text {
            font-size: 22px;
            line-height: 30px;
            padding-top: 15px;
        }
    }
}
