@import "_mixins/pux/breakpoints.scss";
@import "_mixins/pux/css-rules.scss";
@import "_mixins/pux/scale.scss";
@import "_variables.scss";

@font-face {
    font-family: 'Fedra';
    src: url('../fonts/FedraSans/FedraSansPro-Book.otf') format('opentype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Fedra';
    src: url('../fonts/FedraSans/FedraSansPro-Light.otf') format('opentype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Fedra';
    src: url('../fonts/FedraSans/FedraSansPro-Bold.otf') format('opentype');
    font-weight: 700;
    font-style: normal;
}

.cms-bootstrap [class^="icon-"]:before,
.cms-bootstrap [class*=" icon-"]:before {
    font-family: 'Core-icons' !important;
}

*:before,
*:after {
    font-family: 'icomoon' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

body {
    height: auto !important;
}

form,
html,
body {
    @include font-size(16px);
    font-family: $font-family;
    height: 100%;
    color: $second-color;
}

img {
    max-width: 100%;
    height: auto !important;
}

@each $value in $px-values {
    .pt-#{$value} {
        @if($value > 20) {
            @include pux-scale("padding-top",
            $value,
            20);
        }
        @else {
            padding-top: #{$value}px;
        }
    }

    .pb-#{$value} {
        @if($value > 20) {
            @include pux-scale("padding-bottom",
            $value,
            20);
        }
        @else {
            padding-bottom: #{$value}px;
        }
    }

    .mt-#{$value} {
        @if($value > 20) {
            @include pux-scale("margin-top",
            $value,
            20);
        }
        @else {
            margin-top: #{$value}px;
        }
    }

    .mb-#{$value} {
        @if($value > 20) {
            @include pux-scale("margin-bottom",
            $value,
            20);
        }
        @else {
            margin-bottom: #{$value}px;
        }
    }
}

.no-padding-bottom {
    padding-bottom: 0px !important;
}

h1,
.h1 {
    @include pux-scale-font(80px,
    30px);
    @include pux-scale("line-height",
    100px,
    40px);
    @include pux-scale("margin-bottom",
    30px,
    20px);
    font-weight: 300;
}

h2,
.h2 {
    @include pux-scale-font(55px,
    20px);
    @include pux-scale("line-height",
    55px,
    28px);
    @include pux-scale("margin-bottom",
    30px,
    20px);
    font-weight: 700;
}

h3,
.h3 {
    @include pux-scale-font(45px,
    24px);
    @include pux-scale("line-height",
    65px,
    30px);
    @include pux-scale("margin-bottom",
    25px,
    15px);
    font-weight: 700;
}

h4,
.h4 {
    @include pux-scale-font(22px,
    18px);
    @include pux-scale("line-height",
    30px,
    24px);
    @include pux-scale("margin-bottom",
    20px,
    10px);
    font-weight: 700;
    text-transform: uppercase;
    color: $color-line;
}

big,
.perex {
    @include pux-scale-font($font-size-perex,
    $min-font-size-perex,
    $line-height-perex);
    color: $color-line;
}

small,
.text-small {
    @include pux-scale-font($font-size-small,
    $min-font-size-small,
    $line-height-small);
}

a {
    color: $color-link;
    cursor: pointer;
    @include transition();

    &:focus,
    &:hover {
        outline: none;
        color: $second-color;
    }
}

q {
    &:before {
        font-family: $font-family !important;
        content: '»';
        color: $main-color;
        display: inline-block;
    }

    &:after {
        font-family: $font-family !important;
        content: '«';
        color: $main-color;
        display: inline-block;
    }
}

.btn {
    display: inline-block;
    @include border-radius(0);
    border: none;
    font-size: 14px;
    line-height: 20px;
    padding: 16px 15px 14px 20px;
    text-transform: uppercase;
    @include box-shadow(4px 4px 0px 0px $button-shadow);
    position: relative;
    margin-right: 29px;
    margin-top: 0px;
    margin-bottom: 4px;
    margin-left: 0px;
    @include transition(100ms);

    &:before {
        content: '';
        display: block;
        position: absolute;
        right: -24px;
        top: 4px;
        font-size: 20px;
        color: $main-color;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 25px 0 25px 20px;
        border-color: transparent transparent transparent $button-shadow;
        @include transition(100ms);
    }

    &:after {
        content: '';
        display: block;
        position: absolute;
        right: -20px;
        top: 0;
        font-size: 20px;
        color: $main-color;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 25px 0 25px 20px;
        @include transition(100ms);
    }

    &:focus,
    &:hover {
        text-decoration: none;
        margin-top: 3px;
        margin-bottom: 1px;
        margin-left: 3px;
        margin-right: 26px;
        @include box-shadow(1px 1px 0px 0px $button-shadow);

        &:before {
            right: -26px;
            top: 1px;
        }
    }

    @include media(md) {
        font-size: 17px;
        line-height: 24px;
        padding: 23px 40px 23px 50px;
        border-width: 35px 0 35px 25px;

        &:before {
            right: -29px;
            border-width: 35px 0 35px 25px;
        }

        &:after {
            right: -25px;
            border-width: 35px 0 35px 25px;
        }
    }

    span & {
        @include box-shadow(none);
        margin-bottom: 0px;
        margin-right: 0px;
        vertical-align: top;

        &:focus,
        &:hover {
            @include box-shadow(none);
            margin-bottom: 0px;
            margin-right: 0px;
            margin-top: 0px;
            margin-left: 0px;
        }
    }

    @at-root span#{&} {
        padding: 0;
    }

    &-more {
        display: inline-block;
        background-color: rgba(129, 127, 126, 0.8);
        color: $base-white;
        width: 30px;
        height: 30px;
        text-align: center;
        line-height: 30px;
        border-radius: 50%;
        font-size: 14px;
        overflow: hidden;

        &:before {
            content: "\e902";
            line-height: 30px;
        }

        &:hover,
        &:focus {
            background-color: rgba(129, 127, 126, 1);
            color: $base-white;
            text-decoration: none;
        }

        @include media(md) {
            width: 40px;
            height: 40px;
            line-height: 40px;

            &:before {
                line-height: 40px;
            }
        }

        @include media(lg) {
            width: 60px;
            height: 60px;
            line-height: 60px;
            font-size: 22px;

            &:before {
                line-height: 60px;
            }
        }
    }

    &-link {
        color: $main-color;
        font-weight: 700;

        &:after {
            position: relative;
            content: "\e908";
            font-size: 10px;
            line-height: 25px;
            margin-left: 5px;
            @include transition();
        }

        &:hover {
            color: $second-color;
            text-decoration: none;

            &:after {
                margin-left: 8px;
                color: $main-color;
            }
        }
    }
}

.btn-primary {
    background: $btn-primary-background;
    color: $btn-primary-color;

    &:after {
        border-color: transparent transparent transparent $main-color;
    }

    &:focus,
    &:hover {
        text-decoration: none;
        background: darken($btn-primary-background, 5);
        color: $btn-primary-color;

        &:after {
            border-color: transparent transparent transparent darken($main-color, 5);
        }
    }

    &:focus &,
    &:hover & {
        background: darken($btn-primary-background, 5);
    }
}

.btn-default {
    background: $btn-default-background;
    color: $btn-default-color;
    box-shadow: 4px 4px 0px 0px rgba(50, 51, 55, 0.1);

    &:before {
        border-color: transparent transparent transparent rgba(50, 51, 55, 0.1);
    }

    &:after {
        border-color: transparent transparent transparent $btn-default-background;
    }

    &:focus,
    &:hover {
        text-decoration: none;
        background: darken($btn-default-background, 5);
        color: $btn-default-color;
        box-shadow: 1px 1px 0px 0px rgba(50, 51, 55, 0.1);

        &:after {
            border-color: transparent transparent transparent darken($btn-default-background, 5);
        }
    }
}

.image-top-center {
    position: top center !important;
}

.image-top-left {
    position: top left !important;
}

.image-top-right {
    position: top left !important;
}

.image-bottom-center {
    position: bottom center !important;
}

.image-bottom-left {
    position: bottom left !important;
}

.image-bottom-right {
    position: bottom right !important;
}

main {
    min-height: 400px;
    ol {
        margin: 0;
        padding: 0;
        list-style: none;
        counter-reset: my-ol;
        li {
            position: relative;
            margin: 0 0 10px 0;
            padding: 0 0 0 40px;
            &:before {
                font-family: $ol-font-family !important;
                content: counter(my-ol);
                counter-increment: my-ol;
                color: $ol-number;
                position: absolute;
                left: 0;
                top: 2px;
                font-weight: bold;
            }
        }
    }
    ul {
        margin: 0;
        padding: 0;
        list-style: none;
        li {
            @include font-size(16px);
            line-height: 30px;
            margin: 0 0 10px 0;
            padding: 0 0 0 40px;
            position: relative;
            &:before {
                content: "";
                width: 20px;
                height: 2px;
                background: $ul-bullet;
                position: absolute;
                top: 14px;
                left: 0;
            }
        }
    }
    table {
        width: 100%;
        tr {
            border-bottom: 2px solid $table-tr-border;
            td {
                padding: 13px 15px 12px 15px;
            }
            &:nth-child(even) {
                background: $table-even-background;
            }
        }
    }
    .respo-table {
        table {
            width: 100%;
            thead {
                display: none;
                @include media(md) {
                    display: table-header-group;
                }
                tr {
                    border-bottom: 2px solid $table-head-border;
                    th {
                        padding: 13px 15px 12px 15px;
                        font-weight: 400;
                    }
                }
            }
            tbody {
                tr {
                    display: block;
                    border: 1px solid $table-tr-border;
                    border-bottom: none;
                    margin: 0 0 20px 0;
                    &:nth-child(even) {
                        background: $table-even-background;
                    }
                    @include media(md) {
                        display: table-row;
                        margin: 0;
                        border: none;
                        border-bottom: 2px solid $table-tr-border;
                    }
                    td {
                        @include flex-block;
                        @include justify-content(space-between);
                        width: 100%;
                        border-bottom: 2px solid $table-tr-border;
                        @include media(md) {
                            display: table-cell;
                            border: none;
                            width: auto;
                        }
                        &:before {
                            content: attr(data-th);
                            display: block;
                            text-align: left;
                            font-family: $font-family !important;
                            margin: 0 15px 0 0;
                            @include media(md) {
                                display: none;
                            }
                        }
                    }
                }
            }
        }
    }
}

.two-column-list {
    ul {
        @include media(sm) {
            @include column-count(2);
        }
    }
}

.btn-list {
    ul {
        @include flex-block();
        @include flex-flow(row,
        wrap);
        margin-left: -15px;
        margin-right: -15px;

        li {
            @include flex(100%);
            max-width: 100%;
            padding-left: 15px;
            padding-right: 15px;
            margin-bottom: 30px;

            @include media(md) {
                @include flex(33.3333%);
                max-width: 33.3333%;
            }

            &:before {
                display: none;
            }

            a {
                display: block;
                background-color: $main-color;
                color: $base-white;
                line-height: 22px;
                padding: 14px 30px;
                text-align: center;
                text-transform: uppercase;

                &:hover {
                    text-decoration: none;
                    background-color: darken($main-color, 5);
                }

                @include media(md) {
                    font-size: 17px;
                    padding: 24px 30px;
                }
            }
        }
    }
}

/* wrappers */

.orange-wrapper {
    background-color: $main-color;
    color: $base-white;

    .perex,
    big {
        color: $base-white;
    }

    a:not(.btn) {
        color: $base-white;
        text-decoration: underline;
    }
}

.white-wrapper {
    background-color: $base-white;
}

.grey-wrapper {
    background-color: $light-grey;
}

.text-block {
    @include media(lg) {
        padding-left: 40px;
        padding-right: 40px;
    }

    @include media(xl) {
        padding-left: 70px;
        padding-right: 70px;
    }

    h2 {
        font-weight: 300;
        @include pux-scale("margin-bottom",
        40px,
        20px);

        strong {
            font-weight: 700;
        }
    }

    p {
        @include pux-scale("margin-bottom",
        50px,
        20px);
    }
}

.bottom-line {
    border-bottom: 1px solid $light-grey;
}

/* animated icons */

.animated-icon {
    opacity: 0;

    &.full-visible {
        opacity: 1;
    }
}

.hide {
    display: none;
}