@import "_mixins/pux/breakpoints.scss";
@import "_mixins/pux/css-rules.scss";
@import "_mixins/pux/scale.scss";
@import "_variables.scss";


label {
    display: block;
    margin: 0px 0 10px 0;
    font-size: 16px;
    line-height: 25px;
    font-weight: 700;
}

.form-control,
textarea,
input[type="text"],
input[type="password"] {
    width: 100%;
    height: $input-height;
    padding: 0 30px;
    border: 1px solid $border-color;
    border-radius: 0;
    background: $input-background;
    @include transition(100ms);
    @include placeholder {
        color: $color-placeholder;
    }
    &:focus {
        outline: 0;
        border: 1px solid $focus-border-color;
    }
}

textarea,
textarea.form-control {
    height: $textarea-height;
    padding: 20px 30px;
    &:focus {
        border: 1px solid $focus-border-color;
    }
}


input[type="submit"] {
    &:focus,
    &:hover {}
}

.required {
    label {
        &:before {
            content: "*";
            font-size: 10px;
            display: inline-block;
            vertical-align: 6px;
            margin: 0 2px 0 0;
        }
    }
}

.file {
    .uploader-upload {
        display: none;
    }
    label {
        display: inline-block;
        padding: 20px;
        text-transform: uppercase;
        color: $upload-color;
        background: $upload-background;
        cursor: pointer;
        @include font-size(15px);
        @include border-radius(0);
        max-width: 280px;
        margin: 0;
        margin-top: 35px;
        width: 100%;
        text-align: center;
        cursor: pointer;
        letter-spacing: 1px;
        outline: 0 !important;
        line-height: 20px;
    }
    .file-name {
        display: block;
        padding: 5px 10px;
        background: $file-name-background;
        color: $file-name-color;
        margin-top: 10px;
    }
}

@if($use-blink-error==1) {
    .Error {
        select,
        .selectric,
        input[type="text"],
        textarea {
            border: 1px solid $error-input-border !important;
            @include box-shadow(inset 0 0 0 1px $error-input-border);
            @include animation(normal, blink, 1s, ease, 2);
        }
    }

    @include keyframes(blink) {
        0% {
            background: $error-blink-start;
        }
        50% {
            background: $error-blink-final;
        }
        100% {
            background: $error-blink-start;
        }
    }
}

.EditingFormErrorLabel {
    @include font-size(14px);
    color: $error-label-color;
    display: block;
    margin: 5px 0 15px 0;
}

.ErrorLabel {
    color: $error-label-color;
    display: block;
    margin: 5px 10px;
}

.radio {
    input[type="radio"] {
        display: none;
        & + label {
            position: relative;
            padding: 0 0 10px 30px;
            display: inline-block;
            margin: 0;
            &:before {
                content: "";
                @include border-radius(50%);
                width: 20px;
                height: 20px;
                background: $radio-background;
                display: block;
                position: absolute;
                left: 0;
                top: 1px;
                @include transition;
            }
            &:after {
                content: "";
                display: block;
                left: 5px;
                top: 6px;
                width: 10px;
                height: 10px;
                background: $radio-after-background;
                @include border-radius(50%);
                position: absolute;
                @include opacity(0);
                @include transition;
            }

            &:hover {
                &:after {
                    @include opacity(0.4);
                }
            }
        }
        &:checked {
            & + label {
                &:after {
                    @include opacity(1);
                }
            }
        }
    }
}

.checkbox {
    input[type="checkbox"] {
        display: none;
        & + label {
            position: relative;
            padding: 0 0 10px 30px;
            margin: 0;
            display: inline-block;
            &:before {
                content: "";
                width: 20px;
                height: 20px;
                background: $checkbox-background;
                display: block;
                position: absolute;
                left: 0;
                top: 1px;
                @include transition;
            }
            &:after {
                content: "\e909";
                display: inline-block;
                line-height: 20px;
                font-size: 10px;
                width: 20px;
                color: $main-color;
                text-align: center;
                left: 0px;
                top: 1px;
                position: absolute;
                @include opacity(0);
                @include transition;
            }

            &:hover {
                &:after {
                    @include opacity(0.4);
                }
            }
        }
        &:checked {
            & + label {
                &:after {
                    @include opacity(1);
                }
            }
        }
    }
}

select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 100%;
    height: $input-height;
    padding: 0 30px;
    border: 1px solid $border-color;
    background: $input-background;
    @include transition(100ms);
    background-image: url(../img/dp-arrow.png);
    background-position: 97% center;
    background-repeat: no-repeat;

    &:focus {
        outline: 0;
        border: 1px solid $focus-border-color;
    }
}

.modal {
    background-color: rgba($base-white, 0.8);

    &-dialog {
        background-color: $base-white;
        width: calc(100% - 20px);
        max-width: 920px;
        padding: 20px;

        @include pux-scale("margin-top", 150px, 30px);
        @include pux-scale("margin-bottom", 100px, 30px);

        &.search {
            background-color: transparent;
            padding: 30px 0 0 0;
            text-align: center;
        }

        @include media(md) {
            padding: 40px;
        }

        @include media(lg) {
            padding: 80px;
        }
    }

    &-form {
        .editing-form-control-nested-control {
            @include pux-scale("margin-bottom", 20px, 10px);

            &.Error {
                margin-bottom: 0;

                &+label {
                    margin-bottom: 0;
                }
            }
        }
    }

    &-close {
        position: absolute;
        top: 0px;
        right: 0px;
        background-color: $main-color;
        color: $base-white;
        display: block;
        height: 40px;
        width: 40px;
        text-align: center;

        &:before {
            content: "\e900";
            line-height: 40px !important;
            font-size: 14px;
        }

        &:hover {
            background-color: darken($main-color, 5);
            color: $base-white;
            text-decoration: none;
        }

        @include media(md) {
            height: 80px;
            width: 80px;

            &:before {
                line-height: 80px !important;
            }
        }
    }
}

.newsletter-subscription {
    max-width: 440px;
    margin: 0 auto;
    font-size: 0;
    background-color: $base-white;

    &-input {
        display: inline-block;
        vertical-align: top;
        width: 100%;
        margin-right: -60px;
        padding-right: 60px;

        input[type="text"] {
            background-color: $base-white;
            height: 60px;
            line-height: 60px;
            border-color: transparent;
            padding: 0 20px;

            &:focus {
                border-color: transparent;
            }
        }

        @include media(sm) {
            margin-right: -80px;
            padding-right: 80px;

            input[type="text"] {
                height: 80px;
                line-height: 80px;
                padding: 0 30px;
            }
        }
    }

    &-btn {
        position: relative;
        vertical-align: top;
        width: 60px;
        height: 60px;
        font-size: 0;
        margin: 0;
        padding: 0;
        box-shadow: none;
        background-color: $base-white;

        &:before {
            content: "\e90a";
            font-size: 16px;
            border: 0;
            width: 40px;
            height: 40px;
            line-height: 40px;
            text-align: center;
            top: 10px;
            right: 10px;
            background-color: $main-color;
            color: $base-white;
            border-radius: 50%;
            cursor: pointer;
        }

        &:after {
            display: none;
        }

        &:hover,
        &:focus {
            margin: 0;
            padding: 0;
            box-shadow: none;
            background-color: $base-white;

            &:before {
                top: 10px;
                right: 10px;
                background-color: darken($main-color, 5);
            }
        }

        @include media(sm) {
            width: 80px;
            height: 80px;

            &:before {
                width: 60px;
                height: 60px;
                line-height: 60px;
            }
        }
    }
}

.checkbox-control {
    .editing-form-control-nested-control {
        display: inline-block;
        vertical-align: top;
        width: 35px;

        label {
            padding-bottom: 0px !important;
        }
    }

    .EditingFormLabel {
        display: inline-block;
        vertical-align: top;
        width: 100%;
        margin-left: -35px;
        padding-left: 35px;
    }
}
