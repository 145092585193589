@import "_mixins/pux/breakpoints.scss";
@import "_mixins/pux/css-rules.scss";
@import "_mixins/pux/scale.scss";
@import "_variables.scss";

.hero-video {
    .video {
        &-wrapper {
            position: relative;
            margin-top: -65px;

            @include media(xl) {
                margin-top: -80px;
            }

            @include admin() {
                margin-top: 0;
            }
        }

        &-container {
            position: absolute;
            top: 65px;
            bottom: 0;
            right: 0;
            left: 0;
            overflow: hidden;
            z-index: 1;

            video {
                position: absolute;
                display: none;
                top: 50%;
                left: 50%;
                @include transform(translate(-50%, -50%));

                @include admin() {
                    display: none;
                }
            }

            .mobile-video-background {
                position: absolute;
                height: 100vh;
                width: 100%;
                top: 50%;
                left: 50%;
                @include transform(translate(-50%, -50%));
                background-position: center;
                background-size: cover;
            }

            @include media(sm) {
                video {
                    display: block;
                }

                .mobile-video-background {
                    display: none;
                }
            }

            @include media(xl) {
                top: 80px;
            }
        }

        &-overlay {
            z-index: 2;
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            left: 0;
            background-color: rgba(0, 0, 0, 0.3);
        }

        &-content {
            min-height: 100vh;
            position: relative;
            z-index: 3;

            &-inner {
                position: absolute;
                top: 50%;
                left: 50%;
                @include transform(translate(-50%, -50%));

                @include pux-scale-font(80px, 30px);
                @include pux-scale("line-height", 100px, 40px);
                color: $base-white;
                text-align: center;
                text-transform: uppercase;
                font-weight: 300;

                a {
                    display: inline-block;
                    position: relative;
                    padding: 0 4px;
                    margin: 0 -4px;

                    &:after {
                        content: '';
                        display: block;
                        position: absolute;
                        bottom: 5px;
                        left: 0;
                        right: 100%;
                        border-bottom: 2px solid transparent;
                        @include transition(350ms);
                    }

                    &:hover {
                        text-decoration: none;
                        color: $color-line;

                        &:after {
                            right: 0;
                            border-color: $main-color;
                        }
                    }
                }
            }
        }

        &-scroll-btn {
            display: inline-block;
            position: absolute;
            left: 50%;
            bottom: 60px;
            @include transform(translate(-50%, 0%));
            width: 60px;
            height: 60px;
            line-height: 60px;
            border-radius: 50%;
            background-color: rgba(129, 127, 126, 0.8);
            text-align: center;
            color: $base-white;
            overflow: hidden;

            &:after {
                content: "\e903";
                line-height: 64px;
                font-size: 14px;
                @include transition();
            }

            &:hover {
                background-color: rgba(129, 127, 126, 1);
                color: $base-white;
                text-decoration: none;

                &:after {
                    line-height: 70px;
                }
            }

            @include media(xl) {
                bottom: 100px;
            }
        }
    }
}

.homepage-text {
    @include pux-scale("padding-top", 100px, 30px);
    @include pux-scale("padding-bottom", 100px, 30px);
    text-align: center;
}

.partner {
    &-wrapper {
        background-color: $light-grey;
        @include pux-scale("padding-top", 30px, 20px);
        @include pux-scale("padding-bottom", 35px, 20px);
        position: relative;

        &:after {
            content: '';
            position: absolute;
            top: 100%;
            right: 0;
            display: block;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 10px 100vw 0 0;
            border-color: $light-grey transparent transparent transparent;
            @include transition(500ms);

            @include media(lg) {
                border-width: 20px 100vw 0 0;
            }

            @include media(xl) {
                border-width: 40px 100vw 0 0;
            }

            @include admin() {
                border-width: 0;
            }
        }
    }

    &-title {
        text-align: center;
        margin-bottom: 20px;
        font-size: 22px;
        line-height: 35px;
        color: $color-line;
    }

    &-items {
        margin-left: -15px;
        margin-right: -15px;
    }

    &-item {
        padding: 15px;

        img {
            margin: 0 auto;
            max-height: 67px;
        }
    }
}

/* slider overide */

.zfp-slider {
    overflow: hidden;
    @include pux-scale("max-height", 800px, 480px);

    &.slick-initialized {
        max-height: none;
    }

    .main-slider {
        &-inner {
            &-content {
                margin: 0;
                max-width: 60%;

                &-text {
                    font-size: 15px;
                    line-height: 22px;

                    q {
                        display: block;
                        font-size: 22px;
                        line-height: 30px;
                        font-weight: 700;
                        margin-bottom: 15px;

                        &:before,
                        &:after {
                            display: inline-block;
                            vertical-align: 2px;
                        }

                        &:before {
                            margin-right: 3px;
                        }

                        &:after {
                            margin-left: 3px;
                        }
                    }
                }

                @include media(sm) {
                    max-width: 55%;
                }

                @include media(md) {
                    &-text {
                        font-size: 18px;
                        line-height: 24px;

                        q {
                            font-size: 36px;
                            line-height: 45px;
                            margin-bottom: 25px;
                        }
                    }
                }

                @include media(lg) {
                    max-width: 45%;
                }

                @include media(xl) {
                    max-width: 680px;

                    &-text {
                        font-size: 22px;
                        line-height: 35px;

                        q {
                            font-size: 45px;
                            line-height: 55px;
                        }
                    }
                }
            }
        }
    }

    .slick-dots {
        bottom: 0px;

        @include media(xl) {
            bottom: 26px;
        }

        li {
            line-height: 0;
            font-size: 0;

            &:only-child {
                display: none;
            }
        }
    }

    @include admin() {
        max-width: 1280px;
        max-height: 720px;
        margin: 0 auto;
        overflow: hidden;
    }
}

.map {
    &-wrapper &-points {
        display: none;
    }

    &-wrapper .marker-detail-container {
        display: none;
    }

    &-canvas {
        height: 600px;
    }
}

/* jobs */

.job {
    &-items {
        padding-bottom: 60px;
        position: relative;

        @include bs-grid(15px);

        [class*="col-"] {
            margin-bottom: 30px;
        }

        .btn-more {
            position: absolute;
            bottom: 0px;
            left: 50%;
            @include transform(translate(-50%, 50%) rotateZ(45deg));
            z-index: 2;
            @include transition(350ms);

            &.collapsed {
                @include transform(translate(-50%, 50%) rotateZ(0deg));
            }
        }
    }

    &-item {
        background-color: $base-white;
        padding: 40px;
        position: relative;
        min-height: 280px;
        height: 100%;

        &-name {
            font-weight: 700;
            font-size: 22px;
            line-height: 30px;
            margin-bottom: 15px;
            display: inline-block;
            @include hover;
            padding-left: 0;
        }

        &-description {
            margin-bottom: 15px;

            big {
                color: $second-color;
                font-size: 16px;
                line-height: 25px;
            }
        }
    }

    &-detail {
        &-header {
            h1 {
                text-transform: uppercase;
            }
        }
    }
}

/* contact */

.contact {
    &-column {
        text-align: center;

        svg {
            width: 100px;
            height: 100px;
        }

        &-text {
            font-size: 22px;
            line-height: 35px;
            color: $color-line;
            padding-top: 10px;

            strong {
                line-height: 50px;
            }
        }
    }
}

.search-box {
    display: inline-block;
    width: 100%;
    max-width: 760px;
    font-size: 0;

    &.map-search {
        max-width: 430px;
    }

    &-inner {
        display: inline-block;
        vertical-align: top;
        width: 100%;
        background-color: $base-white;
        padding-right: 90px;
        margin-right: -80px;

        .map-search & {
            padding-right: 0;
            margin-right: 0;
        }

        input[type="text"] {
            line-height: 70px;
            background: $base-white;
            font-size: 16px;
            height: 70px;
            border: 0;

            &:focus {
                outline: none;
            }
        }
    }

    &-btn {
        position: relative;
        display: inline-block;
        vertical-align: top;
        width: 80px;
        height: 70px;
        line-height: 70px;

        .map-search & {
            display: none;
        }

        &:before {
            content: "\e904";
            display: inline-block;
            vertical-align: middle;
            width: 40px;
            height: 40px;
            line-height: 40px;
            text-align: center;
            background-color: $main-color;
            color: $base-white;
            border-radius: 50%;
            font-size: 20px;
            @include transition();
        }

        &:hover {
            &:before {
                width: 80px;
                height: 70px;
                border-radius: 0;
                line-height: 70px;
            }
        }
    }
}

.marker-detail {
    color: $base-white;
    text-align: center;
    padding: 25px;
    font-size: 16px;
    line-height: 25px;
    font-family: $font-family;

    h2 {
        font-size: 16px;
        line-height: 25px;
        margin-bottom: 0;
    }

    .number,
    .city,
    .email {
        padding-bottom: 10px;
    }

    a {
        &:hover {
            color: $main-color;
        }
    }

    &-position {
        .gm-style-iw-c {
            padding: 0 !important;
        }

        &:after {
            width: 0 !important;
            height: 0 !important;
            border-style: solid;
            border-width: 11px 10px 0 10px;
            border-color: $second-color transparent transparent transparent;
            transform: translate(-50%,-0%) !important;
            box-shadow: none !important;
            opacity: 0.6 !important;
        }
    }

    &-flyout {
        background-color: rgba($second-color, 0.8) !important;
        border: 0 !important;
        border-radius: 0 !important;

        > div {
            background-color: transparent !important;
            overflow: hidden !important;
        }
    }

    &-close {
        opacity: 1 !important;
        width: 50px !important;
        height: 50px !important;

        >img {
            display: none !important;
        }

        &:before {
            content: "\e900";
            color: $main-color;
        }
    }
}

/* search items */

.search-items {
    .pux-infinite-repeater-result-count-wrapper {
        display: none;
    }

    .show-more-container {
        text-align: center;

        .arrow-down-link {
            font-size: 0;
            @include more-btn;
        }
    }
}

.search-item {
    display: block;
    padding: 30px 40px;

    &:hover {
        text-decoration: none;
        padding: 30px 40px 30px 50px;
    }

    &-box {
        display: block;
        background-color: $base-white;
        margin-bottom: 20px;
    }

    &-relevation {
        background-color: $color-line;

        &-bar {
            background-color: $main-color;
            height: 4px;
        }
    }

    &-name {
        font-weight: 700;
        color: $main-color;
        margin-bottom: 10px;
    }

    &-perex {
        color: $base-black;
        margin-bottom: 10px;
    }

    &-date {
        color: $color-line;
    }
}

.page-404 {
    &-container {
        text-align: center;
    }

    &-image {
        @include pux-scale("margin-bottom", 50px, 20px);

        svg {
            margin: 0 auto;
            @include pux-scale("width", 300px, 20px);
            @include pux-scale("height", 400px, 20px);
        }
    }
}

.cookie-law {
    &-approval {
        &-content {
            vertical-align: top;
            display: inline-block;
            line-height: 54px;

            >a {
                text-decoration: none !important;
                color: $main-color !important;
                font-weight: 700;

                &:hover {
                    text-decoration: underline !important;
                    color: $second-color !important;
                }
            }
        }

        &-close {
            display: inline-block;
            vertical-align: top;
            @include border-radius(0 !important);
            border: none !important;
            font-size: 14px;
            line-height: 20px;
            padding: 16px 20px 14px 20px !important;
            text-transform: uppercase;
            @include box-shadow(4px 4px 0px 0px $button-shadow);
            position: relative;
            margin-top: 0;
            margin-bottom: 4px;
            margin-left: 0;
            margin-right: 4px;
            background-color: $main-color;
            color: $base-white !important;

            &:focus,
            &:hover {
                text-decoration: none;
                margin-top: 3px;
                margin-bottom: 1px;
                margin-left: 3px;
                margin-right: 1px;
                @include box-shadow(1px 1px 0px 0px $button-shadow);
                background-color: darken($main-color, 5) !important;
            }
        }
    }

    &-details {
        display: inline-block;
        vertical-align: top;
        @include border-radius(0 !important);
        border: none !important;
        font-size: 14px;
        line-height: 20px;
        padding: 16px 20px 14px 20px !important;
        text-transform: uppercase;
        @include box-shadow(4px 4px 0px 0px $button-shadow);
        position: relative;
        margin-top: 0;
        margin-bottom: 4px;
        margin-left: 0;
        margin-right: 9px;
        background-color: $color-line;
        color: $base-white !important;

        &:focus,
        &:hover {
            text-decoration: none;
            margin-top: 3px;
            margin-bottom: 1px;
            margin-left: 4px;
            margin-right: 6px;
            @include box-shadow(1px 1px 0px 0px $button-shadow);
            background-color: darken($color-line, 5) !important;
        }
    }
}